import { useEffect, useState, memo, forwardRef } from 'react';
import moment from "moment";
import Geocode from "react-geocode";
import axios from 'axios';
import { Link } from "react-router-dom";
import { dataBase } from '../../firebase/database';
import CurrencyInput from "react-currency-input-field";
import { onSnapshot, collection } from "firebase/firestore";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import useAuth from '../../hooks/useAuth';
// import Header from '../../components/Header/Index';
import { Users, KeyMaps } from '../../data/Data';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from '@mui/icons-material/People';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LinkIcon from '@mui/icons-material/Link';
import GroupIcon from '@mui/icons-material/Group';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ConstructionIcon from '@mui/icons-material/Construction';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PaymentsIcon from '@mui/icons-material/Payments';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Management } from '../../images/icons/Management.svg';
import { ReactComponent as ReportIcon } from '../../images/icons/Report.svg';

import Losango from '../../images/icons/Losango.png';
import Santander from '../../images/icons/Santander.jpg';
import Bv from '../../images/icons/Bv.png';


import './_style.scss';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../../data/theme'
import { Box } from '@mui/material';
import Loading from '../../components/Loading';

const Schedules = ({ userRef, check, reports }) => {
  Geocode.setLanguage("pt-BR");
  Geocode.setRegion("br");
  Geocode.setApiKey(KeyMaps);
  Geocode.setLocationType("ROOFTOP");

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

    const { user } = useAuth();
    const [obras, setObras] = useState({ ativo: [], finalizado: [] });
    const [posto, setPosto] = useState();
    const [km, setKm] = useState();
    const [litro, setLitro] = useState();
    const [total, setTotal] = useState();
    const [cidade, setCidade] = useState(undefined);
    const [lng, setLng] = useState();
    const [lat, setLat] = useState();
    const [combustivel, setCombustivel] = useState();
    const [members, setMembers] = useState(undefined);
    const [findTec, setFindTec] = useState(undefined);
    // eslint-disable-next-line no-unused-vars
    const [rawValue, setRawValue] = useState(" ");
    const [open, setOpen] = useState(false);
    const [financeSchedules, setFinanceSchedules] = useState([]);
    // const [createSchedule, setCreateSchedule] = useState(undefined);
    const FinanceSchedulesCollectionRef = collection(dataBase, "Financeiro");
    const MembersSchedulesCollectionRef = collection(dataBase, "Membros");
    const [TabsValue, setTabsValue] = useState(0);
    const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
    
    useEffect(() => {
      if(collection) { 
            // const q = query(ObrasSchedulesCollectionRef, orderBy("CreateAt")); // Pega aos chats pela ordem descrescente do 'Created'
            const unsub = async () => {

              onSnapshot(FinanceSchedulesCollectionRef, (schedules) => { // Atualiza os dados em tempo real
              let docFinance = [];
              schedules.forEach(doc => {
                docFinance.push({ ...doc.data(), id: doc.id })
              })
              setFinanceSchedules(docFinance); // puxa a coleção 'Agendas' para o state
          })
              onSnapshot(MembersSchedulesCollectionRef, (schedules) => { // Atualiza os dados em tempo real
              let docMembers = [];
              schedules.forEach(doc => {
                docMembers.push({ ...doc.data(), id: doc.id })
              })
              setMembers(docMembers); // puxa a coleção 'Agendas' para o state
          })
        }
            unsub();
          };
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [collection]);
    
  useEffect(() => {
    if(members){
      setFindTec(members.find((ref) => ref.nome === user.name && ref.cargo === "Técnico"))
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[members])

  useEffect(() => {
    if(userRef && (userRef.cargo === 'Instalador' || userRef.cargo === 'Terceirizado')){
      // const obrasFinalizadas = visits.filter(data => data.instalacao === true);
      
      // const obrasRef = userRef.obras.map(data => {
      //   const obraEncontrada = obrasFinalizadas.find(ref => ref.id === data.id);
      //   console.log(obraEncontrada)
      //   return {
      //     nome: data.nome,
      //     id: data.id,
      //     finalizado: obraEncontrada !== undefined ? true : false
      //   };
      // });

      // console.log(obrasRef)
      // if(obrasRef) {
      //   try {
      //     updateDoc(doc(dataBase, "Membros", userRef.id), {
      //      obras: obrasRef
      //    })
      //   } catch {

      //   }
      // }
      setObras({
        ativo: (userRef.obras || []).filter(data => data.finalizado !== true), // Caso seja 'undefined' o resultado, irá retornar uma array vazia
        finalizado: (userRef.obras || []).filter(data => data.finalizado === true) // Caso seja 'undefined' o resultado, irá retornar uma array vazia
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userRef])

  const handleClickOpen = () => {
    if(check) {
      Swal.fire({
        title: 'Sem Conexão',
        icon: "error",
        html: `Não é possível Confirmar Combustivel <b>sem internet.</b> Verifique a sua conexão.`,
        confirmButtonText: "Fechar",
        showCloseButton: true,
        confirmButtonColor: "#d33"  
      })
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        })
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnValueChange = (value) => {
    setRawValue(value === undefined ? "undefined" : value || " ");
    setLitro(value);
  };

  const handleOnValueChange2 = (value) => {
    setRawValue(value === undefined ? "undefined" : value || " ");
    setTotal(value);
  };

  useEffect( () => {
    if(!cidade && lng) {
      Geocode.fromLatLng(lat,lng).then(
        async (response) => {
          // console.log(response)
         let cityRef = response.results[0].address_components;
          setCidade(cityRef.find((ref) => ref.types[0] === 'administrative_area_level_2'));
        //  console.log(cidade)
       },
       (error) => {
        //  console.log(error);
       })
    }
  },[cidade,lat,lng, open])

  const viewLinks = () => {
    Swal.fire({
      title: 'Simuladores & Financiamentos',
      html: `Confira abaixo os links para simulação ou financiamento de projetos.</b> </br></br>` +
      `<div class="swal-item"><img class="swal-img" src=${Bv} alt="" /><a class="swal-link" href="https://instalador.meufinanciamentosolar.com.br/login" target="_blank">BV</a>
      </div>` +
      `<div class="swal-item"><img class="swal-img" src=${Losango} alt="" /><a class="swal-link" href="https://crednet.losango.com.br/Crednet" target="_blank">Losango</a>
      </div>` +
      `<div class="swal-item"><img class="swal-img" src=${Santander} alt="" /><a class="swal-link" href="https://lojista.maisvezes.com.br/originacao-loj/login" target="_blank">Santander</a>
      </div>`,
      showCloseButton: true,
      confirmButtonColor: "#000",
      confirmButtonText: "Ok",
    })
  }

  const sendData = (e) => {
     e.preventDefault();
    if(cidade) {
      const totalFormat = total.replace(',',".");
      const litroFormat = litro.replace(',',".");
      // const precoTotal = (totalFormat * litroFormat).toFixed(2);
      // const precoTotalFormat = precoTotal.replace('.',",");
      // console.log(precoTotalFormat)
      const litros = (totalFormat/litroFormat).toFixed(2);
      setOpen(false);
        Swal.fire({
          title: 'Atenção',
          html: `Verifique os dados abaixo para confirmar o <b>Abastecimento.</b> </br></br>` +
          `Nome do Posto: <b>${posto}</b> </br>` +
          `Quilometragem: <b>${km}</b> </br>` +
          `Preço por Litro: <b>R$ ${litro}</b> </br>` +
          `Quantidade de Litro: <b>${litros}</b> </br>` +
          `Preço Total: <b>R$ ${total}</b> </br>` +
          `Combustivel: <b>${combustivel}</b> </br>` +
          `Veiculo: <b>${findTec.veiculo}</b>`,
          icon: "warning",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#F39200",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if(result.isConfirmed) {
            axios.post('https://n8n.corpbrasil.cloud/webhook/2223e180-0daa-4d5d-90f6-e2cd29aef7d6', {
              data: moment().format('DD/MM/YYYY HH:mm'),
              nome: posto,
              km: km,
              litro: litro,
              QtdeLitro: `R$ ${total}`,
              total: litros,
              lat: lat,
              lng: lng,
              cidade: cidade.long_name,
              endereco: `https://maps.google.com/?q=${lat},${lng}`,
              responsavel: userRef?.nome,
              telefone: '5515998307457',
              veiculo: userRef?.veiculo,
              combustivel: combustivel
            })
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'Abastecimento confirmado com Sucesso!',
              showConfirmButton: false,
              timer: 2000
            })
            setPosto('');
            setKm('');
            setLitro('');
            setTotal('');
            setLng('');
            setLat('');
            setCombustivel('');
          } else {
            setOpen(true);
          }
      });
    } else {
      setOpen(false);
      Swal.fire({
        title: 'GPS Desativado',
        html: `Ative o <b>GPS</b> para confirmar o abastecimento.`,
        icon: "error",
        showCloseButton: true,
        confirmButtonColor: "#000",
        confirmButtonText: "Ok",
      })
    }
  }

  const titleRef = (ref) => {
    switch(ref) {
      case "Instalador":
        return 'Obras'
      case "Terceirizado":
        return 'Serviços'
      case "Representante":
        return 'Área do Representante'
      default: return 'Inicio'
    }
  }
  

  return (
    <div>
      <Loading view={financeSchedules.length === 0} />
    {financeSchedules && userRef ?
        <div className='container-schedules'>
            <div className='title-schedule'>
              <HomeOutlinedIcon sx={{ width: '50px', height: '50px' }} />
              <h1>{titleRef(userRef && userRef.cargo)}</h1>
              {/* <h1>{userRef && (userRef.cargo === 'Instalador' || userRef.cargo === 'Terceirizado') ? 'Obras' : userRef && userRef.cargo === 'Representante' ? 'Área do Representante' : 'Inicio'}</h1> */}
            </div>
            {userRef && (userRef.cargo === 'Instalador' || userRef.cargo === 'Terceirizado') ?
            <div className='content-schedule' style={{ marginTop: '2rem' }}>
            <ThemeProvider theme={theme}>
              <Tabs value={TabsValue} onChange={(e, newValue) => setTabsValue(newValue)} aria-label="Obras" centered>
                  <Tab sx={{ textTransform: 'capitalize' }} label="Em Andamento" {...a11yProps(1)} />
                  <Tab sx={{ textTransform: 'capitalize' }} label="Finalizado" {...a11yProps(2)} />
              </Tabs>
            </ThemeProvider>
            <CustomTabPanel value={TabsValue} index={0}>
              {obras && obras.ativo.length > 0 ? obras.ativo.map((obra, index) => (
            <div key={index} className='box-schedule'>
                  <li className='schedule'>
                    <Link className='schedule__content' to={`/${userRef && userRef.cargo === 'Terceirizado' ? 'limpeza' : 'visita'}/${obra.id}`}>
                      <div className='schedule__icon report'><ConstructionIcon /></div>
                      <div className='schedule__text-box'>
                      <div className='schedule__text'>
                        <p>{obra.nome}</p>
                        {obra.data ? 
                          <span>Data: <b>{moment(obra.data).format('DD/MM/yyyy')}</b></span> : null
                        }
                        {obra.horario ? 
                          <span>Horário: <b>{obra.horario}</b></span> : null
                        }
                      </div>
                      </div>
                    </Link> 
                  </li>
            </div>
              )) : <h1 className='visit-aviso'>Nenhuma Obra Encontrada</h1>}    
            </CustomTabPanel>
            <CustomTabPanel value={TabsValue} index={1}>
              {obras && obras.finalizado.length > 0 ? obras.finalizado.map((obra, index) => (
            <div key={index} className='box-schedule'>
                  <li className='schedule'>
                    <Link className='schedule__content' to={`/visita/${obra.id}`}>
                      <div className='schedule__icon calculation'><ConstructionIcon /></div>
                      <div className='schedule__text-box'>
                      <div className='schedule__text'>
                        <p>{obra.nome}</p>
                      </div>
                      </div>
                    </Link> 
                  </li>
            </div>
              )) : <h1 className='visit-aviso'>Nenhuma Obra Encontrada</h1>}    

            </CustomTabPanel>
            </div> : 
            <div className='content-schedule'>
              <div className='buttons-content'>
              <div className='box-schedule'>
                  <li className='schedule'>
                    <Link className='schedule__content' to="/visitas">
                      <div className='schedule__icon visits'><CalendarMonthIcon /></div>
                      <div className='schedule__text-box'>
                      <div className='schedule__text'>
                        <p>{userRef && userRef.cargo === 'Representante' ? 'Gestão de Visitas' : 'Agenda'}</p>
                      </div>
                      </div>
                    </Link> 
                  </li>
            </div>
            {userRef && (userRef.cargo === 'Representante' || userRef.cargo === 'Administrador' || userRef.time) ? 
              <div className='box-schedule'>
                  <li className='schedule'>
                    <Link className='schedule__content' to="/comissao">
                      <div className='schedule__icon payments'><PaymentsIcon /></div>
                      <div className='schedule__text-box'>
                      <div className='schedule__text'>
                        <p>Comissões</p>
                      </div>
                      </div>
                    </Link> 
                  </li>
            </div> : null
            }
            {userRef && (userRef.cargo === "Técnico" || userRef.cargo === "Administrador" || userRef.nome_ref) &&
              <><div className='box-schedule'>
                    {financeSchedules && financeSchedules.map((schedule, index) => (
                      <li key={index} className='schedule'>
                        <Link className='schedule__content' to="/financeiro">
                          <div className='schedule__icon finance'><LocalAtmIcon /></div>
                          <div className='schedule__text'>
                            <p>Financeiro</p>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </div> 
                  {userRef && (userRef.nome === "Pós-Venda" || userRef.cargo === "Administrador") &&
                    <div className='box-schedule'>
                      <li className='schedule'>
                        <Link className='schedule__content' to="/instalacao">
                          <div className='schedule__icon install'><CleaningServicesIcon /></div>
                          <div className='schedule__text'>
                            <p>Instalação</p>
                          </div>
                          </Link>
                      </li>
                        </div>
                  }
                  {userRef && (userRef.nome === "Pós-Venda" || userRef.cargo === "Administrador") &&
                    <div className='box-schedule'>
                      <li className='schedule'>
                        <Link className='schedule__content' to="/limpeza">
                          <div className='schedule__icon cleaner'><CleaningServicesIcon /></div>
                          <div className='schedule__text'>
                            <p>Limpeza</p>
                          </div>
                          </Link>
                      </li>
                        </div>
                  }
                  {userRef && (user.email === Users[0].email || userRef.cargo === "Vendedor(a)" || userRef.cargo === "Administrador") && userRef.nome !== 'Pós-Venda' &&
                    <div className='box-schedule'>
                      <li className='schedule'>
                        <Link className='schedule__content' to="/gestao-comercial">
                          <div className='schedule__icon management'><Management /></div>
                          <div className='schedule__text'>
                            <p>Gestão Comercial</p>
                          </div>
                          </Link>
                      </li>
                        </div>
                  }
                  {userRef && (user.email === Users[0].email || userRef.cargo === "Vendedor(a)" || userRef.cargo === "Administrador") && userRef.nome !== 'Pós-Venda' &&
                    <div className='box-schedule'>
                      <li className='schedule'>
                        <Link className='schedule__content' to="/financiamento">
                          <div className='schedule__icon financing'><AccountBalanceIcon /></div>
                          <div className='schedule__text'>
                            <p>Financiamento</p>
                          </div>
                          </Link>
                      </li>
                        </div>
                    }</>}
                {userRef && (userRef.cargo === "Vendedor(a)" || userRef.cargo === "Administrador") && userRef.nome !== 'Pós-Venda' &&
                    <div className='box-schedule'>
                      <li className='schedule'>
                        <Link className='schedule__content' to="/clientes">
                          <div className='schedule__icon credit'><GroupIcon /></div>
                          <div className='schedule__text'>
                            <p>Clientes</p>
                          </div>
                          </Link>
                      </li>
                        </div>
                }
              {/* {userRef && (user.email === Users[0].email || userRef.cargo === "Vendedor(a)" || userRef.cargo === "Administrador") && userRef.nome !== 'Pós-Venda'
              && !userRef.time &&
              <div className='box-schedule'>
                <li className='schedule'>
                  <Link className='schedule__content' to="/prospeccao">
                    <div className='schedule__icon prospection'><Prospection /></div>
                    <div className='schedule__text'>
                      <p>Prospecção</p>
                    </div>
                    </Link>
                </li>
                  </div>
              } */}
            {/* {userRef && (user.email === Users[0].email || userRef.cargo === "Vendedor(a)" || userRef.cargo === "Administrador") && userRef.nome !== 'Pós-Venda' && !userRef.time &&
            <div className='box-schedule'>
              <li className='schedule'>
                <Link className='schedule__content' to="/leads">
                  <div className='schedule__icon alert'><PeopleIcon /></div>
                  <div className='schedule__text'>
                    <p>Confirmar Leads</p>
                  </div>
                  </Link>
              </li>
                </div>
            } */}
          {userRef && (user.email === Users[0].email || userRef.cargo === "Vendedor(a)" || userRef.cargo === "Administrador") && !userRef.time &&
            <div className='box-schedule'>
              <li className='schedule'>
                <Link className='schedule__content' to="/relatorio">
                <Badge sx={{ width: '5.6rem', height: '100%' }} badgeContent={reports && reports.length - userRef.relatorio} color="error">
                  <div className='schedule__icon report'><ReportIcon /></div>
                </Badge>
                  <div className='schedule__text'>
                    <p>Relatório</p>
                  </div>
                  </Link>
              </li>
                </div>
            }
            {userRef && (userRef.cargo === "Administrador" || userRef.cargo === "Técnico" || findTec || userRef.local === 'Rua') &&
              <div className='box-schedule'>
                    <li className='schedule'>
                      <Link className='schedule__content' onClick={() => handleClickOpen()}>
                        <div className='schedule__icon fuel'><LocalGasStationIcon /></div>
                        <div className='schedule__text'>
                          <p>Confirmar</p>
                          <p>Combustivel</p>
                        </div>
                      </Link>
                    </li>
                  </div>
            }
            {userRef && userRef.cargo !== 'Representante' ? 
              <>
              {/* <div className='box-schedule'>
                <li className='schedule'>
                  <a className='schedule__content' href="https://calculadora.app.deyeinversores.com.br/pt-BR" target="_blank" rel="noreferrer">
                    <div className='schedule__icon calculation'><CalculateIcon /></div>
                    <div className='schedule__text'>
                      <p>Calculadora Híbrida</p>
                    </div>
                  </a>
                </li>
              </div> */}
              <div className='box-schedule'>
              <li className='schedule'>
                <div className='schedule__content' onClick={() => viewLinks()}>
                  <div className='schedule__icon link'><LinkIcon /></div>
                  <div className='schedule__text'>
                    <p>Simuladores &</p>
                    <p>Financiamentos</p>
                  </div>
                </div>
              </li>
            </div></> : null
            }
          {userRef && userRef.leads && 
            <div className='box-schedule'>
                      <li className='schedule'>
                        <a className='schedule__content' href={userRef && userRef.leads} target="_blank" rel="noreferrer">
                          <div className='schedule__icon leads'><PeopleIcon /></div>
                          <div className='schedule__text'>
                            <p>Meu Leads</p>
                          </div>
                        </a>
                      </li>
                    </div>
          }   
              </div>      
            </div>
            }
            <Dialog open={open} onClose={handleClose}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              ><CloseIcon /></IconButton>
              <DialogTitle sx={{ fontSize: '1.4rem' }} align='center'>Confirmar Combustível</DialogTitle>
                <ThemeProvider theme={theme}>
                <form onSubmit={sendData} autoComplete='off'>
                <DialogContent>
                  <DialogContentText sx={{ marginBottom: "1rem" }}>
                    Preencha os campos abaixo para confirmar o abastecimento do veículo.
                  </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Nome do Posto"
                      type="text"
                      onChange={(e) => setPosto(e.target.value)}
                      value={posto || ''}
                      fullWidth
                      required
                      variant="outlined"
                    />
                    <TextField
                      margin="dense"
                      id="name"
                      label="Quilometragem do Veiculo"
                      type="number"
                      onChange={(e) => setKm(e.target.value)}
                      value={km  || ''}
                      fullWidth
                      required
                      variant="outlined"
                    />
                    <CurrencyInput
                    customInput={TextField}
                    style={{ margin: '0.7rem 0rem 0.3rem 0' }}
                    className="label__text"
                    label="Preço por Litro"
                    placeholder="R$ 00"
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    onValueChange={handleOnValueChange}
                    decimalsLimit={2}
                    value={litro || ''}
                    required
                    fullWidth
                    />
                    <CurrencyInput
                    customInput={TextField}
                    style={{ margin: '0.4rem 0rem 0.7rem 0' }}
                    className="label__text"
                    label="Preço Total"
                    placeholder="R$ 00"
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    onValueChange={handleOnValueChange2}
                    decimalsLimit={2}
                    value={total || ''}
                    required
                    fullWidth
                    />
                    <FormControl sx={{ margin: '0.3rem 0' }} fullWidth>
                      <InputLabel id="demo-simple-select-label">Combustível</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={combustivel}
                        label="Combustível"
                        required
                        onChange={(e) => setCombustivel(e.target.value)}
                      >
                        <MenuItem value='Gasolina'>Gasolina</MenuItem>
                        <MenuItem value='Etanol'>Etanol</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      margin="dense"
                      id="name"
                      label="Veiculo"
                      type="number"
                      value={userRef?.veiculo}
                      disabled
                      fullWidth
                      variant="outlined"
                    />
                  </DialogContent>
                  <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                    <Button 
                      disableElevation
                      variant='contained'
                      color='success'
                      sx={{ textTransform: 'capitalize' }}
                      type='submit'>Confirmar</Button>
                    <Button 
                      disableElevation
                      variant='contained'
                      sx={{ textTransform: 'capitalize' }}
                      onClick={handleClose}>Cancelar</Button>
                  </DialogActions>
                </form>
                </ThemeProvider>
            </Dialog>
            <Snackbar open={check} autoHideDuration={6000}>
                <Alert severity="error" sx={{ width: '100%' }}>
                  Você está sem conexão. Verifique a sua conexão com a internet.
                </Alert>
            </Snackbar>
        </div> : 
    <Stack sx={{ margin: '4rem 0' }}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}>
      <Skeleton variant='rectangular' width={950} height={96} />
      <Skeleton variant='rectangular' width={950} height={96} />
      <Skeleton variant='rectangular' width={950} height={96} />
      <Skeleton variant='rectangular' width={950} height={96} />
    </Stack>
      }
    </div>

  )
}

export default memo(Schedules)
