import React from 'react'
import { useState, useEffect } from "react";
import Swal from "sweetalert2";

import Dialog from "@mui/material/Dialog";
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { theme } from "../../../data/theme";
import { ThemeProvider, DialogContentText, InputAdornment, Box, Autocomplete, createFilterOptions, CircularProgress, Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import style from "./style.module.scss";
import NewTooltip from "../../../components/Tooltip";
import axios from 'axios';
import CurrencyInput from 'react-currency-input-field';
import formatCurrency from '../../../functions/formatCurrency';

const Estimate = ({ sheet, setProposalRef, cloneSheet, view, index, close }) => {
    const [proposal, setProposal] = useState(null);
    const [proposalList, setProposalList] = useState([]);
    const [proposalListRef, setProposalListRef] = useState([]);
    const [originalEstimate, setOriginalEstimate] = useState(null);
    const [open, setOpen] = useState(false);
    const filter = createFilterOptions();
    const [loading, setLoading] = useState(false);

    console.log(cloneSheet)

    useEffect(() => {
        const fetchData = async () => {
            const propostasSheet = await axios.get('https://script.google.com/macros/s/AKfycbwl_i83rMEHlAXIx7FWhuwkjnW7uuBTIi2FmdjucMU3WEUx-JuFrG8TSHeMo2zHhXNp8g/exec')
            const propostasRef = propostasSheet.data.GoogleSheetData;
            console.log(propostasRef)
            const propostaFormated = propostasRef.map((data, index) => {
                return {
                    nome: data[1],
                    proposta: data[14],
                    telefone: data[2],
                    valor: parseFloat(data[4].replace(/\./g, '').replace(/(\d{2})$/, '.$1')),
                    valor_parcelado: parseFloat(data[5].replace(/\./g, '').replace(/(\d{2})$/, '.$1')),
                    codigo: data[12],
                    inversor: data[6],
                    inversor_quantidade: data[7] || 0,
                    modulo: data[8],
                    modulo_quantidade: data[9],
                    kwh: String(data[10]).replace(/\.|,/g, m => m === ',' ? '.' : ''),
                    kwp: String(data[15]).replace(/\.|,/g, m => m === ',' ? '.' : ''),
                }
            });
            const propostasListFull = propostaFormated.filter(data => data.nome);
            setProposalListRef(propostasListFull);
        }

        fetchData();
    }, [])

    useEffect(() => {
        if (proposalListRef && sheet) {
            setProposalList(proposalListRef.filter(data => data.telefone.includes(sheet.telefone)));
            if (loading) {
                setLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proposalListRef, sheet])

    useEffect(() => {
        // Verificar se sheet, planilha e index são válidos antes de tentar acessar
        if (sheet && Array.isArray(sheet.planilha) && sheet.planilha.length > index && view) {
            const estimateRef = sheet.planilha[index]?.orcamento;

            if (estimateRef && !proposal) {
                setProposal(estimateRef);
                setOriginalEstimate(estimateRef);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sheet, index, view]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);

        setProposal({ ...proposal, [name]: value });
    }

    const checkInputs = () => {
        if(proposal) {
            const inputs = ['proposta', 'inversor', 'inversor_quantidade', 'modulo', 'modulo_quantidade', 'kwh', 'kwp', 'valor', 'valor_parcelado'];
            const inputsFilled = inputs.every(input => proposal[input] !== '' && proposal[input] !== null)
            return inputsFilled;
        } else {
            return true
        }

        // if (!proposal.proposta || !proposal.valor || !proposal.kwh || !proposal.kwp || !proposal.modulo || !proposal.modulo_quantidade) {
        //     return false;
        // }

        // return true
        //  return hasSpecificPropertiesChanged(originalEstimate, {...proposal, valor: proposal.valor});
    };

    console.log(proposal)
    console.log(originalEstimate)

    const saveEstimate = async () => {

        const result = await Swal.fire({
            title: 'Proposta',
            html: `Você deseja realizar essa ação?`,
            icon: "question",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0eb05f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        });

        try {

            if (result.isConfirmed) {
                setProposalRef(proposal);
                // setLoading(true);
                // const sheets = [];
                // sheets.push(...sheet.planilha);
                // sheets[index] = {
                //     ...sheet.planilha[index],
                //     orcamento: proposal,
                // };

                // await updateDoc(doc(dataBase, 'Clientes', sheet.id), {
                //     planilha: sheets
                //   })

                //   setLoading(false);
                await Swal.fire({
                    title: 'Proposta',
                    html: `A <b>Proposta</b> foi escolhida com sucesso.`,
                    icon: "success",
                    showConfirmButton: true,
                    showCloseButton: true,
                    confirmButtonColor: "#111",
                })

                close();
            }

        } catch (error) {
            // setLoading(true);
            Swal.fire({
                title: 'Erro ao enviar orçamento',
                text: 'Não foi possível enviar o orçamento. Tente novamente mais tarde.',
                icon: 'error',
                confirmButtonText: 'Ok',
            })
            console.log('ERRO: ', error)
        }
    }

    // const sendEstimate = async () => {

    //     const result = await Swal.fire({
    //         title: 'Orçamento',
    //         html: `Você deseja enviar o orçamento para o cliente?`,
    //         icon: "question",
    //         showCancelButton: true,
    //         showCloseButton: true,
    //         confirmButtonColor: "#0eb05f",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: "Sim",
    //         cancelButtonText: "Não",
    //       });

    //     try {

    //         if(result.isConfirmed) {
    //             setLoading(true);
    //             const sheets = [];
    //             sheets.push(...sheet.planilha);
    //             sheets[index] = {
    //                 ...sheet.planilha[index],
    //                 orcamento: originalEstimate,
    //                 enviado: true,
    //             };

    //             await updateDoc(doc(dataBase, 'Clientes', sheet.id), {
    //                 planilha: sheets
    //             })

    //             await axios.post('https://n8n.corpbrasil.cloud/webhook-test/fed5e7b0-e4f1-47d0-88d8-0cdcd755eed0', {
    //                 ...proposal,
    //                 valor: proposal.valor.replace('.', ','),
    //                 nome: sheet.nome,
    //                 telefone: sheet.telefone,
    //                 consultora: sheet.consultora,
    //                 representante: sheet.representante ? `${sheet.representante?.nome} (${sheet.representante?.codigo})` : 'Nenhum',
    //                 id: `${sheet.id}-${index}`,
    //             })

    //               setLoading(false);
    //               await Swal.fire({
    //                 title: 'Orçamento',
    //                 html: `O <b>Orçamento</b> foi enviado com sucesso.`,
    //                 icon: "success",
    //                 showConfirmButton: true,
    //                 showCloseButton: true,
    //                 confirmButtonColor: "#111",
    //               })
    //         }

    //     } catch (error) {
    //         setLoading(true);
    //         Swal.fire({
    //             title: 'Erro ao enviar orçamento',
    //             text: 'Não foi possível enviar o orçamento. Tente novamente mais tarde.',
    //             icon: 'error',
    //             confirmButtonText: 'Ok',
    //         })
    //         console.log('ERRO: ', error)
    //     }
    // }

    // const checkSend = () => {
    //     return (originalEstimate)
    // }

    const closeBox = () => {
        close();
    }

    console.log(proposalList, loading)

    return (
        <Dialog
            className={style.dialog}
            open={view}
            fullWidth
            maxWidth="sm"
        >
            <IconButton
                aria-label="close"
                onClick={closeBox}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            ><CloseIcon /></IconButton>
            <DialogTitle align="center">Proposta</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ textAlign: "center", marginBottom: '0.3rem'}}>
                    Escolha a proposta que será enviada para o cliente caso ele aceite o <b>sistema recomendado</b>.
                </DialogContentText>
                <ThemeProvider theme={theme}>
                    <Grid container spacing={1} sx={{ marginTop: '0.5rem', marginBottom: '1rem', paddingRight: '8px'  }}>
                        <Grid item xs={12}>                         
                            <Autocomplete
                                value={proposal || ''}
                                fullWidth
                                loading={open}
                                open={open}
                                noOptionsText='Nenhuma Proposta Encontrada'
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                sx={{ alignItems: 'center' }}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setProposal({
                                            proposta: newValue,
                                            kwp: '',
                                            kwh: '',
                                            valor: ''
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        setProposal({
                                            proposta: newValue.inputValue,
                                            kwp: '',
                                            kwh: '',
                                            valor: ''
                                        });
                                    } else {
                                        setProposal(newValue);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.proposta);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            proposta: `Adicionar "${inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                disabled={!cloneSheet}
                                handleHomeEndKeys
                                disableClearable={true}
                                id="free-solo-with-text-demo"
                                options={proposalList}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.proposta;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.proposta} ({formatCurrency(option.valor)})</li>}
                                freeSolo
                                loadingText='Carregando...'
                                renderInput={(params) => (
                                    <TextField {...params}
                                        label="Proposta"
                                        type="search"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Potência do Kit"
                                type="number"
                                disabled={!cloneSheet}
                                name="kwp"
                                required
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kWp</InputAdornment>,
                                }}
                                value={proposal?.kwp || ''}
                                onChange={handleChange}
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Geração do Kit"
                                type="number"
                                disabled={!cloneSheet}
                                name="kwh"
                                required
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kWh</InputAdornment>,
                                }}
                                value={proposal?.kwh || ''}
                                onChange={handleChange}
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Inversor"
                                disabled={!cloneSheet}
                                type="text"
                                name="inversor"
                                required
                                value={proposal?.inversor || ''}
                                onChange={handleChange}
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Inversor Quantidade"
                                type="number"
                                disabled={!cloneSheet}
                                name="inversor_quantidade"
                                required
                                value={proposal?.inversor_quantidade || ''}
                                onChange={handleChange}
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Módulo"
                                type="text"
                                name="modulo"
                                disabled={!cloneSheet}
                                required
                                value={proposal?.modulo || ''}
                                onChange={handleChange}
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Módulo Quantidade"
                                type="number"
                                name="modulo_quantidade"
                                required
                                disabled={!cloneSheet}
                                value={proposal?.modulo_quantidade || ''}
                                onChange={handleChange}
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CurrencyInput
                                customInput={TextField}
                                label="Valor à Vista"
                                placeholder="R$ 00"
                                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                                onValueChange={(formatted) => setProposal({ ...proposal, valor: formatted })}
                                decimalsLimit={2}
                                value={proposal?.valor || ''}
                                required
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CurrencyInput
                                customInput={TextField}
                                label="Valor Parcelado"
                                placeholder="R$ 00"
                                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                                onValueChange={(formatted) => setProposal({ ...proposal, valor_parcelado: formatted })}
                                decimalsLimit={2}
                                value={proposal?.valor_parcelado || ''}
                                required
                                disabled
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </ThemeProvider>
                {proposal?.telefone ?
                    <Alert severity="info">
                        <AlertTitle>Dados Extra da Proposta</AlertTitle>
                        <Box>
                            <p>Cliente: <b>{proposal?.nome || 'Não Informado'}</b></p>
                            {/* <p>Inversor: <b>{proposal.inversor || 'Não Informado'}</b>, Quantidade: <b>{proposal.inversor_quantidade || 'Não Informado'}</b></p>
                            <p>Módulo: <b>{proposal.modulo || 'Não Informado'}</b>, Quantidade: <b>{proposal.modulo_quantidade || 'Não Informado'}</b></p> */}
                            <p>Telefone: <b>{proposal?.telefone || 'Não Informado'}</b></p>
                            <p>Representante: <b>{proposal?.codigo || 'Não Informado'}</b></p>
                        </Box>
                    </Alert> : null
                }
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ThemeProvider theme={theme}>
                        <NewTooltip title={!checkInputs() ? 'Preencha os campos obrigatório para salvar a proposta' : ''} placement='top'>
                            <span>
                                <LoadingButton
                                    disableElevation
                                    variant="contained"
                                    color="success"
                                    disabled={!checkInputs() || !cloneSheet}
                                    onClick={saveEstimate}
                                    sx={{ textTransform: 'capitalize' }}>
                                    Escolher
                                </LoadingButton>
                            </span>
                        </NewTooltip>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{ textTransform: 'capitalize' }}
                            onClick={closeBox}>Cancelar</Button>
                    </ThemeProvider>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default Estimate