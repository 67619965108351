import Santander from "../../../images/icons/Santander.jpg";
import BancoBV from "../../../images/icons/Bv.png";
import BancoCorSolar from "../../../images/icons/CorSolar.png";
import BTGPactual from "../../../images/icons/Btg.png";
import Caixa from "../../../images/icons/Caixa.jpeg";
import Losango from "../../../images/icons/Losango.png";
import Sicredi from "../../../images/icons/Sicredi.png";
import Sicoob from "../../../images/icons/Sicoob.png";
import SolAgora from "../../../images/icons/SolAgora.png";
import SolFacil from "../../../images/icons/SolFacil.png";
import SolPlace from "../../../images/icons/SolPlace.png";
import BancoBrasil from "../../../images/icons/BancodoBrasil.jpg";
import FGTS from "../../../images/icons/FGTS.png";

export const banks = {
    Santander: Santander,
    "Banco BV": BancoBV,
    "Banco CorSolar": BancoCorSolar,
    "BTG Pactual": BTGPactual,
    "Caixa Econômica Federal": Caixa,
    Losango: Losango,
    Sicredi: Sicredi,
    Sicoob: Sicoob,
    "Sol Agora": SolAgora,
    "Sol Facil": SolFacil,
    SolPlace: SolPlace,
    "Banco Do Brasil": BancoBrasil,
    "FGTS": FGTS
  };