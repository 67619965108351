import React, { createContext, useState, useEffect, useContext } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { dataBase } from '../firebase/database';

// Criando o contexto
const QueueFirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const collectionRef = collection(dataBase, 'Fila');
    
    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
      const newData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setData(newData);
    });

    return () => unsubscribe(); // Cleanup quando o componente desmontar
  }, []);

  return (
    <QueueFirebaseContext.Provider value={{ data }}>
      {children}
    </QueueFirebaseContext.Provider>
  );
};

// Hook personalizado para consumir o contexto
export const useFirebaseDataQueue = () => {
  return useContext(QueueFirebaseContext);
};
