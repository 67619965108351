import React, { useCallback, useEffect, useMemo, useState } from 'react'

import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { banks } from '../data/Banks';
import { Avatar, Box, Checkbox, Collapse, FormControlLabel, Grid, Typography } from '@mui/material';
import { NewBox } from './NewBox';
import { NewDivider } from './NewDivider';
import CustomAudioPlayer from '../../../components/AudioPlayerFixed/CustomAudioPlayer';
import formatCurrency from '../../../functions/formatCurrency';
import ButtonSection from './ButtonSection';
import Simulation from './Simulation';
import ButtonCB from '../../../components/Button';
import Swal from 'sweetalert2';
import InstallmentCheckbox from './InstallmentCheckbox';
import { getFirstName } from '../../../functions/getFirstName';
import { useGracePeriodMonth } from '../../../hooks/useGracePeriodMonth';

const colorsBanks = {
    "Padrão": ['#000', '#000', "#000"],
    "Santander": ['#E00000', '#B70000', '#E00000'],
    "Banco BV": ['#1D30AF', '#152486', '#1D30AF'],
    "Losango": ['#C51134', '#A3102D', '#014277'],
    "Banco do Brasil": ['#FFF500', '#FFF500', '#455CFF', '#455CFF'],
    "Sicoob": ['#004453', '#002D37', '#004453'],
    "Sicredi": ['#40B93C', '#328B2F', '#0F6828'],
    "Sol Agora": ['#3C38D9', '#2B2899', '#3C38D9'],
    "FGTS": ['#003F74', '#032847', '#032847']
  }

const Financing = ({ simulation, simulacaoRef, assistant, system, customer, openVisit, openPaymentContract, 
  setRefresh, proposal, installment, setInstallment, type = 'Visita', step }) => {
    const [open, setOpen] = useState(false);
    const [installmentOptions, setInstallmentOptions] = useState([]);
    const { getMonthAfterGracePeriod } = useGracePeriodMonth();
    useEffect(() => {
      if(simulation) {
        setInstallmentOptions(simulation?.parcelas || []);
      }
    },[simulation])

    const availableInstallments = useMemo(() => {
        return simulation?.condicao_especial?.parcelas || [];
      }, [simulation]);

    const handleInstallmentsCondition = useCallback((event) => {
        const selectedValue = event.target.value;
        const selectedInstallment = availableInstallments.find(
          (installment) => parseInt(installment.quantidade, 10) === parseInt(selectedValue, 10)
        );
    
        setInstallment({ ...selectedInstallment, condicao_especial: true });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [availableInstallments]);
    
      const handleInstallments = useCallback((event) => {
        const selectedValue = event.target.value;
        const selectedInstallment = installmentOptions.find(
          (installment) => parseInt(installment.quantidade, 10) === parseInt(selectedValue, 10)
        );
        setInstallment(selectedInstallment);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [installmentOptions]);

    console.log(step, type)

    const showMessageCondition = ({ background, textColor }) => {
        return Swal.fire({
          html:
            `<b>Informações Importantes</b><br/><br />
            <p>Os valores das parcelas podem sofrer pequenas variações devido ao score de crédito, TAC (Taxa de Abertura de Crédito) e IOF (Imposto sobre Operações Financeiras).</p><br/>
            <p>**Ao optar por antecipar as parcelas, não haverá aplicação de descontos.</p>`,
          confirmButtonColor: "#fff",
          confirmButtonText: "Fechar",
          customClass: {
            popup: 'custom-swal-box',
            confirmButton: 'custom-confirm-button',  // Classe para o botão de confirmação
          },
          didRender: () => {
            // Muda a cor do fundo da caixa de diálogo dinamicamente
            document.querySelector('.custom-swal-box').style.backgroundColor = background;
            // Muda a cor do texto dinamicamente
            document.querySelectorAll('.custom-swal-box').forEach(el => {
              el.style.color = textColor;
            });
          }
        });
      };

      console.log(proposal)

  return (
    <Grid container spacing={2}>
          {step !== `Payments` && (
            <Grid item xs={12} ref={simulacaoRef || null} id="Simulacao" sx={{ order: { xs: 0} }}>
              <NewBox margin="1rem 0 0rem 0" sx={{ flex: 1, 'h3': { fontSize: '21px' } }}>
                <h3>Crédito Bancário</h3>
                <NewDivider
                  height="4px"
                  color="#F39200"
                  margin="0.3rem 0 0.5rem 0"
                />
              </NewBox>
            </Grid>
          )}

          {step !== `Payments` && (
            <Grid item xs={12} sx={{ order: { xs: 1} }}>
            <NewBox margin="0" sx={{ flex: 1 }}>
                <h3>Entenda o crédito bancário</h3>
                <NewDivider
                  height="3px"
                  color="#F1F1F1"
                  margin="0.3rem 0 0.5rem 0"
                />
                <Box
                  sx={{ width: "100%", maxWidth: "500px", display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}
                >
                  <Avatar
                    src={assistant?.photo?.url}
                    alt=""
                    sx={{ width: 60, height: 60 }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "end",
                      }}
                      fontSize={14}
                    >
                      <b>Analista fotovoltaica:</b>
                      <p>{assistant?.nome_completo}</p>
                    </Box>
                    <CustomAudioPlayer
                      url={simulation?.mensagemVoz?.url || ""}
                      color="#747474"
                    />
                  </Box>
                </Box>
              </NewBox>
            </Grid>
          )}

          {simulation?.condicao_especial && step !== `Payments` &&
            <Grid item xs={12} sx={{ order: { xs: 2} }}>
              <Box sx={{
                backgroundColor: colorsBanks[simulation?.condicao_especial?.banco || 'Padrão'][0], borderRadius: '15px', padding: '1rem',
                display: 'flex', flexDirection: 'column', gap: '0.5rem'
              }}>
                <Box
                  sx={{ 'img': { width: '40px' }, display: 'flex', maxWidth: '440px', alignItems: 'center', gap: '1rem', width: '100%' }}
                  marginTop={"0.5rem"}
                >
                  <img src={banks[simulation?.condicao_especial?.banco]} alt="" />
                  <Box color="#fff">
                    <h3>Condições Especiais</h3>
                    <p>{simulation?.condicao_especial?.banco}</p>
                  </Box>
                </Box>
                <Box sx={{
                  padding: '1rem', backgroundColor: "#fff", borderRadius: '10px', margin: '0.5rem 0',
                  color: colorsBanks[simulation?.condicao_especial?.banco || 'Padrão'][2], lineHeight: '23px'
                }}>
                  <p>Comece a pagar em</p>
                  <b style={{ textTransform: 'capitalize' }}>{getMonthAfterGracePeriod(simulation?.condicao_especial?.carencia)}</b>
                  <p><b>{simulation?.condicao_especial?.parcelas[0]?.quantidade}</b>x parcelas iguais de{" "}
                    <b>{formatCurrency(simulation?.condicao_especial?.parcelas[0]?.valor)}</b></p>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%', justifyContent: 'space-between' }}>
                  {simulation?.condicao_especial?.parcelas.map((data, index) => (
                    <FormControlLabel
                      key={index}
                      sx={{
                        display: 'flex',
                        gap: '0rem',
                        margin: 0,
                        paddingRight: '0.5rem',
                        borderRadius: '5px',
                        backgroundColor: "#fff",
                        height: '30px'
                      }}
                      control={
                        <Checkbox
                          color="success"
                          value={data.quantidade}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              display: 'flex',
                            },
                            '&.Mui-checked': { color: "#0FA958" }
                          }}
                          disabled={system?.pagamento_ref?.tipo}
                          checked={data.quantidade === installment?.quantidade || system?.pagamento_ref?.tipo.includes("Condição Especial")}
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                          onChange={handleInstallmentsCondition}
                        />
                      }
                      label={
                        <Box>
                          <p>Eu Quero</p>
                        </Box>}
                    />
                  ))}
                  <ButtonCB
                    background={colorsBanks[simulation?.condicao_especial?.banco || 'Padrão'][1]}
                    sx={{ fontSize: '12px', color: "#fff", height: '30px' }}
                    onClick={() => showMessageCondition({
                      background: colorsBanks[simulation?.condicao_especial?.banco || 'Padrão'][1],
                      textColor: colorsBanks[simulation?.condicao_especial?.banco || 'Padrão'][3] || "#fff"
                    })}
                  >
                    <Typography fontSize={'20px'} paddingRight={'5px'}>*</Typography> Informações Importantes
                  </ButtonCB>
                </Box>
              </Box>
            </Grid>
          }

          {simulation?.fgts && step !== `Payments` &&
            <Grid item xs={12} sx={{ order: { xs: 2} }}>
              <Box sx={{
                backgroundColor: colorsBanks['FGTS'][0], borderRadius: '15px', padding: '1rem',
                display: 'flex', flexDirection: 'column', gap: '0.5rem'
              }}>
                <Box
                  sx={{ 'img': { width: '40px' }, display: 'flex', maxWidth: '440px', alignItems: 'center', gap: '1rem', width: '100%' }}
                  marginTop={"0.5rem"}
                >
                  <img src={banks['FGTS']} alt="" />
                  <Box color="#fff">
                    <h3>Condições Especiais</h3>
                    <p>FGTS</p>
                  </Box>
                </Box>
                <Box sx={{
                  padding: '1rem', backgroundColor: "#fff", borderRadius: '10px', margin: '0.5rem 0',
                  color: colorsBanks['FGTS'][2], lineHeight: '23px'
                }}>
                  <p>Use seu saldo do FGTS para <b>comprar seu sistema fotovoltaico</b> e comece a economizar na conta de luz. Consulte agora as condições com nossa analista!</p>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%', justifyContent: 'space-between' }}>
                  {simulation?.fgts?.resgate_saldo && simulation?.fgts?.resgate_valor ? 
                    <ButtonCB sx={{ }} startIcon={<WhatsAppIcon />} background="#00cc4c">
                      Consultar
                    </ButtonCB> : 
                    <ButtonCB sx={{ }} startIcon={<WhatsAppIcon />} background="#00cc4c" onClick={() => window.open(`https://wa.me/5515988339165?text=Quero%20saber%20mais%20sobre%20o%20FGTS`, '_blank')}>
                      Consultar 
                    </ButtonCB> // padding: '0.1rem 0.5rem'
                  }
                  {/* <ButtonCB
                    background={colorsBanks['FGTS'][1]}
                    sx={{ fontSize: '12px', color: "#fff", height: '30px' }}
                    onClick={() => showMessageCondition({
                      background: colorsBanks['FGTS'][1],
                      textColor: colorsBanks['FGTS'][3] || "#fff"
                    })}
                  >
                    <Typography fontSize={'20px'} paddingRight={'5px'}>*</Typography> Informações Importantes
                  </ButtonCB> */}
                </Box>
              </Box>
            </Grid>
          }

          <Grid item xs={12} sx={{ order: { xs: 3} }}>
            <NewBox
              margin="0"
              sx={{ 
                flex: 1, 
                padding: "1rem 0", 
                overflow: 'hidden',
                backgroundColor: step === `Payments` ? 'transparent' : "#fff",
                boxShadow: step === `Payments` ? 'none' : '0px 10px 24px 0px #0000001A'}}
            >
              <Box sx={{ padding: '0 1rem 0 1rem' }}>
                {simulation?.situacao === "Aprovado" ? (
                    <Box>
                    <Box
                      sx={{ 'img': { width: '40px' }, display: 'flex', maxWidth: '440px', alignItems: 'center', gap: '1rem', width: '100%' }}
                      marginTop={"0.5rem"}
                    >
                      <img src={banks[simulation?.banco]} alt="" />
                      <Box>
                        <h3>Boleto Bancário</h3>
                        <p>{simulation?.banco}</p>
                      </Box>
                    </Box>
                      <NewDivider
                        height="3px"
                        color="#F1F1F1"
                        margin="1rem 0"
                      />
                      <Box sx={{ display: 'flex', maxWidth: '440px', alignItems: 'center', gap: '1rem', width: '100%' }}>
                        <TimerOutlinedIcon
                          sx={{ fontSize: "50px", fill: "#FFAB00" }}
                        />
                        <Box>
                          <h3>Comece a pagar em: </h3>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                              p: { textTransform: "capitalize", marginTop: '0.1rem' },
                            }}
                          >
                            <p>
                              {getMonthAfterGracePeriod(
                                simulation?.carencia
                              )}
                            </p>
                          </Box>
                        </Box>
                      </Box>
                      <NewDivider
                        height="3px"
                        color="#F1F1F1"
                        margin="1rem 0"
                      />
                      <Box
                        sx={{ display: 'flex', maxWidth: '440px', alignItems: 'center', gap: '1rem', width: '100%',marginBottom: "1.5rem" }}
                      >
                        <CurrencyExchangeRoundedIcon
                          sx={{
                            fill: "#FFAB00",
                            fontSize: "44px",
                            padding: "0.2rem",
                          }}
                        />
                        <Box>
                          <h3>Escolha as parcelas</h3>
                          {/* {!system?.visita && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                              }}
                            >
                              <p>Selecione a parcela desejada</p>
                            </Box>
                          )} */}
                        </Box>
                      </Box>
                      {installmentOptions
                        .slice()
                        .reverse()
                        .map((data, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ display: 'flex', maxWidth: '400px', alignItems: 'center', gap: '1.5rem', width: '100%', lineHeight: '25px' }}>
                              <InstallmentCheckbox
                                data={data}
                                disabled={system?.pagamento_ref?.tipo}
                                installment={installment}
                                handleInstallments={
                                  handleInstallments
                                }
                              />
                            </Box>
                            <NewDivider
                              height="3px"
                              color="#F1F1F1"
                              margin="1rem 0"
                            />
                          </Box>
                        ))}
                      
                        <Box
                          sx={{
                            textAlign: "center",
                            marginBottom: "0.5rem",
                            width: "100%",
                            minWidth: "330px"
                          }}
                        >
                          {!installment ? (
                            <Typography variant="caption">
                              Escolha uma das opções de pagamento
                            </Typography>
                          ) : (
                            <Typography variant="caption">
                              Agora, clique no botão e {type === 'Contrato' ? 'solicite seu contrato' : 'agende a sua visita técnica'}
                            </Typography>
                          )}
                          <Box sx={{ 
                              flexDirection: 'column',
                              display: 'flex',
                              gap: '0.7rem' 
                            }}>

                          {type === 'Contrato' ? 
                            proposal?.status !== 'Aceito' ?
                            <ButtonCB
                            fullWidth
                            sx={{
                              maxWidth: "350px",
                              margin: "0 0 0.5rem 0",
                            }}
                            size="large"
                            background={step === 'Payments' ? "#209CFF" : "#000"}
                            onClick={() =>
                              openPaymentContract(
                                "Contrato",
                                "Financiamento"
                              )
                            }
                          >
                            Solicitar Contrato
                          </ButtonCB>: 
                          <ButtonCB
                          fullWidth
                          sx={{
                            maxWidth: "350px",
                            margin: "1rem 0 0.5rem 0",
                          }}
                          size="large"
                          startIcon={<EventAvailableIcon />}
                          background="transparent"
                          variant="outlined"
                          onClick={() =>
                            openPaymentContract(
                              "Contrato",
                              "Financiamento"
                            )
                          }
                          colorText="#000"
                        >
                          Contrato Solicitado
                        </ButtonCB>
                           : 
                            !system.visita ?
                            <ButtonCB
                              fullWidth
                              sx={{ maxWidth: "350px" }}
                              size="large"
                              background="#209CFF"
                              onClick={() =>
                                openVisit("Gostou", "Simulação")
                              }
                            >
                              Agendar Visita Técnica
                            </ButtonCB> : 
                        <ButtonCB
                          fullWidth
                          sx={{
                            maxWidth: "350px",
                            margin: "1rem 0 0.5rem 0",
                          }}
                          size="large"
                          startIcon={<EventAvailableIcon />}
                          background="transparent"
                          variant="outlined"
                          colorText="#000"
                        >
                          Visita Solicitada
                        </ButtonCB>
                          }

                          {step !== 'Payments' && (
                            <ButtonCB
                              fullWidth
                              size="large"
                              background="#22b558"
                              startIcon={<WhatsAppIcon />}
                              onClick={() => window.open(`https://wa.me/${assistant?.telefone}?text=Ol%C3%A1%2C%20tenho%20uma%20d%C3%BAvida%20referente%20ao%20meu%20or%C3%A7amento`, '_blank')}
                            >
                              Dúvidas? Clique aqui
                            </ButtonCB>
                          )}
                          </Box>
                        </Box>
                      
                    </Box>
                ) : (
                  <>
                    <Box sx={{ textAlign: 'center' }}>
                      <h3>Simulação de Crédito</h3>
                    </Box>
                    <NewDivider
                      height="3px"
                      color="#F1F1F1"
                      margin="1rem 0"
                    />
                    <Box
                      sx={{
                        padding: "0.5rem",
                        display: "flex",
                        gap: "0.5rem",
                        flexDirection: "column",
                        p: { textAlign: "justify" },
                      }}
                    >
                      <p>
                        {getFirstName(customer?.nome)}, a análise de
                        crédito no CPF indicado para simulação não foi
                        aprovado.
                      </p>
                      <p>
                        Se você tiver outro CPF, de algum familiar
                        próximo, basta preencher as informações abaixo
                        no formulário e nos enviar.
                      </p>
                      <p>
                        Dessa forma, refazemos a simulação e enviamos
                        o resultado para você o mais rápido possível.
                      </p>
                    </Box>
                    {proposal.nova_simulacao ? (
                      <ButtonCB
                        fullWidth
                        sx={{
                          maxWidth: "350px",
                          margin: "1rem 0 0.5rem 0",
                        }}
                        size="large"
                        startIcon={<AccountBalanceIcon />}
                        background="transparent"
                        variant="outlined"
                        colorText="#000"
                      >
                        Simulação Solicitada
                      </ButtonCB>
                    ) : (
                      <Box
                        sx={{
                          marginTop: "1rem",
                          width: "100%",
                          boxShadow: "0px 10px 24px 0px #0000001A",
                        }}
                      >
                        <ButtonSection
                          icon={false}
                          open={open}
                          text="Fazer nova Simulação"
                          onClick={() =>
                            setOpen(!open)
                          }
                        />
                        <Collapse
                          sx={{ width: "100%", paddingTop: "0.5rem" }}
                          in={open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Simulation
                            customer={customer}
                            system={system}
                            assistant={assistant}
                            setRefresh={setRefresh}
                            newSimulation={true}
                          />
                        </Collapse>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            
            </NewBox>
          </Grid>
    </Grid>
  )
}

export default Financing