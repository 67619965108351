import { useState, useEffect, memo, useRef } from 'react'
import styles from './styles.module.scss';
import { Alert, AlertTitle, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import moment from 'moment';
import SlickSlider from "react-slick";

import ArticleIcon from '@mui/icons-material/Article';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'; // Visita Comercial
import PeopleIcon from '@mui/icons-material/People'; // Tecnica + Comercial
import EngineeringIcon from '@mui/icons-material/Engineering'; // Pós Venda
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { FaRegCalendarTimes } from "react-icons/fa";

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { theme } from '../../../data/theme';
import Swal from "sweetalert2";
import "cooltipz-css";
import { collection, deleteDoc, deleteField, doc, getDoc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import BlockIcon from '@mui/icons-material/Block';
import { FaCalendarCheck } from "react-icons/fa";

import axios from 'axios';
import Gps from '../Components/Register_Visit/Gps';
import RegisterVisitStep1 from '../Components/Register_Visit/Step1';
import RegisterVisitStep2 from '../Components/Register_Visit/Step2';
import RegisterVisitStep3 from '../Components/Register_Visit/Step3';
import RegisterVisitStep4 from '../Components/Register_Visit/Step4';
import RegisterVisitStep45 from '../Components/Register_Visit/Step4_5';
import RegisterVisitStep5 from '../Components/Register_Visit/Step5';
import RegisterVisitStep6 from '../Components/Register_Visit/Step6';
import RegisterVisitStep15 from '../Components/Register_Visit/Step1_5';
import RegisterVisitStep25 from '../Components/Register_Visit/Step2_5';
import RegisterInstall from '../Components/Register_Visit/Install';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import NewTooltip from '../../../components/Tooltip';
import useVisitConfirm from '../../../hooks/useVisitConfirm';
import useSwalAlert from '../../../hooks/useSwalAlert';
import { toast } from '../../../components/Toast';
import { formatDate } from '../../../functions/formatDate';
import removePrefixPhone from '../../../functions/removePrefixPhone';

const InfoVisit = ({ isLoaded, userRef, visitsAll, sellers, members }) => {
  const { handleVisitConfirm } = useVisitConfirm();
  const swalAlert = useSwalAlert();
  const [info, setInfo] = useState({
    cliente: '',
    endereco: '',
    dataTexto: '',
    horarioTexto: '00:00',
    visitaNumero: 1800,
    consultoraTexto: '',
    representante: 'Nenhum',
    tecnicoTexto: null,
    concessionaria: '',
    observacao: '',
    cidade: '',
    numero: 0
  });
  const [visit, setVisit] = useState({});
  const { idVisit } = useParams();
  const navigate = useNavigate();
  const [position, setPosition] = useState({
    lat: -23.109655741119823,
    lng: -47.71509175415125
  });
  const [TabsValue, setTabsValue] = useState(0);
  const [openVisit, setOpenVisit] = useState('step0');
  const slider = useRef(null);
  const [anotacao, setAnotacao] = useState('');
  const [checkAnotacao, setCheckAnotacao] = useState('');
  const [viewEdit, setViewEdit] = useState(false);
  const [addInstall, setAddInstall] = useState(false);
  const [idStep1, setIdStep1] = useState(null);
  const [step2, setStep2] = useState(null);
  const [idStep4, setIdStep4] = useState(null);
  const [install, setInstall] = useState('Nenhum');
  const [installAll, setInstallAll] = useState();
  const [openEdit, setOpenEdit] = useState({ install: false, cleaner: false });
  const [instaladores, setInstaladores] = useState([{ nome: '', documento: '' }]);
  const [photosStep1, setPhotosStep1] = useState();
  const [photosStep2, setPhotosStep2] = useState();
  const [photosStep3, setPhotosStep3] = useState();
  const [photosStep4, setPhotosStep4] = useState();
  const [checkStep, setCheckStep] = useState({ step0: false, step1: false, step1_5: false, step2: false, step2_5: false, step3: false, step4: false })
  const [dataVisit, setDataVisit] = useState();
  const journey = ['Proposta Comercial', 'Visita Técnica', 'Projeto', 'Venda Concluida', 'Comissão', 'Instalação'];
  const journeySG = ['PC', 'VT', 'P', 'F', 'VC', 'C', 'INS'];
  const journeyColor = ['#dcdbad', '#addcc5', '#5facff', '#bedcad', '#6ece99', '#45e952'];
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const settingsVisit = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // autoplay: true,
    adaptiveHeight: true,
    autoplaySpeed: 2000,
  };

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        style={{ display: 'flex', justifyContent: 'center' }}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display={'flex'} width={'90%'} alignItems={'center'} justifyContent={'center'}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  useEffect(
    () => {
      const fetchData = async () => {
        onSnapshot(await collection(dataBase, 'Visitas', idVisit, 'Tecnica'), (schedule) => {
          // Atualiza os dados em tempo real
          setDataVisit(
            schedule.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        });
        onSnapshot(doc(dataBase, "Visitas", idVisit), (doc) => {
          setVisit({ ...doc.data(), id: doc.id });
        });
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (visit) {
      setPosition({
        lat: visit.lat,
        lng: visit.lng
      })
      setInstall(visit.instalador?.nome || 'Nenhum');
    }
  }, [visit])

  // const calculateCommission = (value) => {
  //   if (value >= 5000) return 2;
  //   if (value >= 4500) return 2.5;
  //   if (value >= 4000) return 3;
  //   if (value >= 3500) return 3.5;
  //   if (value >= 3000) return 4;
  //   if (value >= 2500) return 4.5;
  //   if (value >= 2000) return 5;
  //   if (value >= 1500) return 5.5;
  //   if (value >= 1000) return 6;
  //   if (value >= 500) return 6.5;
  //   if (value <= 499) return 7;
  //   return null;
  // };

  // const handleComissionValue = (valueInitial, valueEnd) => {
  //   const discount = parseFloat(valueInitial) - parseFloat(valueEnd);
  //   let comissionValue = 0;
  //   console.log(discount)
  //   const commissionPercentage = calculateCommission(discount);
  //   if (!isNaN(discount) && discount >= 500) {
  //     comissionValue = commissionPercentage ? (parseFloat(valueEnd) * commissionPercentage) / 100 : 0;
  //   } else {
  //     comissionValue = commissionPercentage ? (parseFloat(valueEnd) * commissionPercentage) / 100 : 0;
  //   }
  //   console.log(comissionValue)
  //   return comissionValue;
  // }

  // function visitaNome(valor) {
  //   switch (valor) {
  //     case 'comercial':
  //       return 'Comercial';
  //     case 'comercial_tecnica':
  //       return 'Comercial + Técnica';
  //     case 'pos_venda':
  //       return 'Pós-Venda';
  //     default:
  //       return valor;
  //   }
  // }


  // useEffect(() => { // Seleciona a visita de acordo com o tipo escolhido
  //   const lunch = () => {
  //     let tecRef = '';
  //     let consultoraTexto = null;
  //     switch (type) {
  //       case 'lunch':
  //         setDriver(tecs.filter((ref) => ref.nome === userRef.nome))
  //         tecRef = userRef.nome;
  //         //driverRef.current = 'Bruna';
  //         break
  //       case 'comercial':
  //         setDriver(tecs.filter((ref) => ref.nome === "Bruna" || ref.nome === "Lia"))
  //         tecRef = 'Bruna';
  //         consultoraTexto = 'Bruna';
  //         //driverRef.current = 'Bruna';
  //         break
  //       case 'comercial_tecnica':
  //         setDriver(tecs.filter((ref) => ref.nome === "Lucas" || ref.nome === "Neto"))
  //         tecRef = 'Lucas';
  //         consultoraTexto = 'Bruna';
  //         //driverRef.current = 'Lucas';
  //         break
  //       case 'pos_venda':
  //         setDriver(tecs.filter((ref) => ref.nome === "Lucas" || ref.nome === "Neto"))
  //         tecRef = 'Lucas';
  //         consultoraTexto = 'Pós-Venda';
  //         break
  //       default:
  //         setDriver(tecs)
  //         // setVisits(visitsAll)
  //     }
  //     console.log('aaaaaaaaaaaa ',info)
  //     setInfo({
  //       ...info, 
  //       tecnicoTexto: tecRef,
  //       consultoraTexto: consultoraTexto
  //     })
  //   };
  //   lunch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  console.log(info)

  // useEffect(() => {
  //   // let visitsType = schedule.filter((visit) => visit.tecnico === "Lucas" && visit.tecnico === "Luis");
  //   let visitsData;
  //   let visitsType;
  //   switch (type) {
  //     case 'comercial':
  //       visitsData = visitsAll.filter((visit) => visit.data === info.dataTexto && (visit.tecnico !== "Lucas" && visit.tecnico !== "Neto"));
  //       visitsType = visitsAll.filter((visit) => visit.tecnico !== "Lucas" && visit.tecnico !== "Neto" && moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024'));
  //       break
  //     case 'lunch':
  //       visitsData = visitsAll.filter((visit) => visit.data === info.dataTexto);
  //       visitsType = visitsAll.filter((visit) =>  moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024'));
  //       break
  //     default:
  //       visitsData = visitsAll.filter((visit) => visit.data === info.dataTexto && (visit.tecnico === "Lucas" || visit.tecnico === "Neto" || visit.categoria === 'lunch'));
  //       visitsType = visitsAll.filter((visit) => (visit.tecnico === "Lucas" || visit.tecnico === "Neto" || visit.categoria === 'lunch') &&
  //        moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024'))
  //   }
  //   if(info.dataTexto) {
  //     if (visitsData && info.dataTexto.substring(8,10) !== "00") {
  //       setVisits(visitsData);
  //     }
  //   } else {
  //     setVisits(visitsType);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[info.dataTexto, visitsAll, type, monthNumber])

  // useEffect(() => {
  //   // console.log(visitaNumero);
  //   if (info.horarioTexto && info.visitaNumero) {
  //     moment.locale("pt-br");

  //     const saidaEmpresa = moment(info.horarioTexto, "hh:mm"); //Horario de chegada
  //     const chegadaCliente = moment(info.horarioTexto, "hh:mm"); //Horario de chegada

  //     saidaEmpresa.subtract(rotaTempo, "seconds").format("hh:mm"); // Pega o tempo que o tecnico vai precisar sair da empresa

  //     setSaidaTexto(saidaEmpresa.format("kk:mm"));

  //     chegadaCliente.add(info.visitaNumero, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
  //     setSaidaCliente(chegadaCliente.format("kk:mm"));
  //     chegadaCliente.add(rotaTempo, "seconds").format("hh:mm"); //Adiciona tempo de viagem volta
  //     // console.log(chegadaCliente)
  //     setChegadaTexto(chegadaCliente.format("kk:mm"));
  //   }

  //     let saidaEmpresaRef, ChegadaEmpresaRef;
  //     moment.locale("pt-br");
  //     saidaEmpresaRef = saidaTexto;
  //     ChegadaEmpresaRef = chegadaTexto;

  //     const saidaFormatada = moment(saidaEmpresaRef, "hh:mm");
  //     const chegadaFormatada = moment(ChegadaEmpresaRef, "hh:mm");
  //     let check = [];
  //     let visitsFindData = [];


  //     const dataRef = visitsAll.filter(
  //       (dia) => dia.data === info.dataTexto && (dia.tecnico === info.tecnicoTexto || (type === 'lunch' && dia.consultora === info.tecnicoTexto))
  //     );

  //       dataRef.map((ref) => {
  //         // console.log("eae");
  //         if (
  //           saidaFormatada <= moment(ref.saidaEmpresa, "hh:mm") &&
  //           chegadaFormatada <= moment(ref.saidaEmpresa, "hh:mm")
  //         ) {
  //           check.push(ref);
  //         } else {
  //           if (saidaFormatada >= moment(ref.chegadaEmpresa, "hh:mm"))
  //             check.push(ref);
  //         }
  //         return dataRef;
  //       });
  //     // console.log(visitsFindCount);

  //     dataRef.map((a) => {
  //       //Percorre todos os arrays de 'dataRef' e compara se os arrays são iguais
  //       if (check.includes(a) === false) {
  //         visitsFindData.push(a);
  //       }
  //       return setVisitsFind(visitsFindData);
  //     });
  //     setVisitsFindCount(dataRef.length - check.length)

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [info.horarioTexto, info.visitaNumero, chegadaTexto, saidaTexto, rotaTempo, info.cidade, info.dataTexto, info.tecnicoTexto]);

  // useEffect(() => {
  //   let visitsData;
  //   let visitsType;

  //   const filterByDateAndTechnician = (visit) => {
  //     const isLucasOrNeto = visit.tecnico === "Lucas" || visit.tecnico === "Neto";
  //     const isTargetMonthYear = moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024');
  //     return isTargetMonthYear && isLucasOrNeto;
  //   };

  //   const filterByDateAndSeller = (visit) => {
  //     const isBruna = visit.tecnico === "Bruna";
  //     const isTargetMonthYear = moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024');
  //     return isTargetMonthYear && isBruna;
  //   };

  //   switch (type) {
  //     case 'comercial':
  //       visitsData = visitsAll.filter((visit) => visit.data === info.dataTexto && (visit.tecnico !== "Lucas" && visit.tecnico !== "Neto"));
  //       visitsType = visitsAll.filter(filterByDateAndSeller);
  //       break;
  //     case 'lunch':
  //       visitsData = visitsAll.filter((visit) => visit.data === info.dataTexto);
  //       visitsType = visitsAll.filter((visit) => moment(visit.data).month() + 1 === parseInt(monthNumber) && moment(visit.data).year() === parseInt('2024'));
  //       break;
  //     default:
  //       visitsData = visitsAll.filter((visit) => visit.data === info.dataTexto && (visit.tecnico === "Lucas" || visit.tecnico === "Neto" || visit.categoria === 'lunch'));
  //       visitsType = visitsAll.filter(filterByDateAndTechnician);
  //   }

  //   if (info.dataTexto && info.dataTexto.substring(8, 10) !== "00") {
  //     setVisits(visitsData);
  //   } else {
  //     setVisits(visitsType);
  //   }
  // }, [info.dataTexto, visitsAll, type, monthNumber]);

  useEffect(() => {
    if (dataVisit) {
      const step0 = dataVisit.find((etapa) => etapa.etapa === 1);
      if (step0) {
        setIdStep1(step0.id);
      }
      const step1 = dataVisit.find((etapa) => etapa.etapa === 1 && etapa.injecao_energia_ca);
      const step15 = dataVisit.find((etapa) => etapa.etapa === 1 && etapa.fachada);
      const step2Ref = dataVisit.find((etapa) => etapa.etapa === 2);
      if (step2Ref) {
        setStep2(step2Ref);
      }
      const step25 = dataVisit.find((etapa) => etapa.etapa === 2 && etapa.telhadoVideo);
      const step3 = dataVisit.find((etapa) => etapa.etapa === 3);
      const step4 = dataVisit.find((etapa) => etapa.etapa === 4 && etapa.telhadoPlaca);
      const step4Ref = dataVisit.find((etapa) => etapa.etapa === 4); // Utilizado para caso já exista a etapa 4
      const step45 = dataVisit.find((etapa) => etapa.etapa === 4 && etapa.inversorDataSheet);
      if (step4Ref) {
        setIdStep4(step4Ref.id);
      }
      let photos = [];
      const photosFull1 = step15 && photos.concat(step15.amperimetro, step15.bengala, step15.disjuntor, step15.fachada, step15.padrao, step15.poste, step15.relogio)
      const photosFull2 = step2Ref && photos.concat(step2Ref.telhadoPerto, step2Ref.telhadoLonge);
      const videosFull2 = step2Ref && step2Ref.telhadoVideo;
      let FilesStep2 = [];
      if (step2Ref) {
        for (let i = 0; i < photosFull2.length; i++) {
          FilesStep2.push({ type: 'photo', url: photosFull2[i] });
        }
      }
      if (step25) {
        for (let i = 0; i < videosFull2.length; i++) {
          FilesStep2.push({ type: 'video', url: videosFull2[i] });
        }
      }
      const photosFull4 = step4 && photos.concat(step4.telhadoMedida, step4.telhadoPlaca, step4.telhadoIrradiacao, step4.kit)
      const photosFull3 = step3 && step3.telhadoMedida;
      setPhotosStep1(photosFull1);
      setPhotosStep2(FilesStep2);
      setPhotosStep3(photosFull3);
      setPhotosStep4(photosFull4);
      setInfo({
        gps_padrao: step0 && step0.GPS_padrao,
        telhado: step3 && step3.telhado,
        anotacao: step4 && step4.anotacao,
        anotacao_padrao: step1 && step1.anotacao,
        injecao_energia_ca: step1 && step1.injecao_energia_ca,
        condominio: step4 && step4.condominio
      })

      // handleCheckStep({ 
      //   step0: step0 ? true : false,
      //   step1: step1 ? true : false,
      //   step1_5: step15 ? true : false,
      //   step2: step2 ? true : false,
      //   step3: step3 ? true : false,
      //   step4: step4 ? true : false,
      //   step4_5: step45 ? true : false
      //  })

      setCheckStep({
        step0: step0 ? true : false,
        step1: step1 ? true : false,
        step1_5: step15 ? true : false,
        step2: step2Ref ? true : false,
        step2_5: step25 ? true : false,
        step3: step3 ? true : false,
        step4: step4 ? true : false,
        step4_5: step45 ? true : false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVisit, visit])

  const deleteStep = async (visit, id, keys) => {

    try {
      Swal.fire({
        title: 'Projeto',
        html: `Você deseja excluir a etapa de <b>Projetos</b>?`,
        icon: "question",
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const stepRef = doc(dataBase, 'Visitas', idVisit, 'Tecnica', id);
          const docStep = await getDoc(stepRef);
          if (!docStep.exists()) {
            console.log('O documento não existe.');
            return;
          }

          const step = docStep.data();
          const storage = getStorage();
          // const keys = Object.keys(step.ref);
          console.log(keys);

          keys.forEach(async key => {
            const value = step.ref[key];
            console.log(value[0]); // Apenas para depuração, se necessário

            for (const data of value) {
              try {
                console.log("Dados: ", data);
                await deleteObject(ref(storage, data));
                console.log('Deletado com sucesso:', data);
              } catch (error) {
                console.log('Erro ao deletar:', data, error);
              }
            }
          });

          const updateData = {
            projeto_user: deleteField()
          };

          await updateDoc(doc(dataBase, 'Visitas', visit.id), updateData);

          await updateDoc(stepRef, {
            ref: deleteField(),
            telhadoIrradiacao: deleteField(),
            telhadoMedida: deleteField(),
            telhadoPlaca: deleteField()
          });

          Swal.fire({
            title: 'Projeto',
            html: `O <b>projeto</b> foi excluído com sucesso.`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#111",
          });
        }
      })
    } catch (error) {
      console.error('Erro ao excluir etapa:', error);
      // Tratar o erro de acordo com a necessidade do seu aplicativo
      Swal.fire({
        title: 'Erro',
        text: 'Ocorreu um erro ao excluir a etapa do projeto.',
        icon: "error",
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
      });
    }
  };


  useEffect(() => {
    if (members) {
      setInstallAll(members.filter((member) => member.cargo === 'Instalador'))
    }
  }, [members])

  // const ativeProposta = (index) => {
  //   if(propostas[index].ativo === true) {
  //     Swal.fire({
  //       title: 'Proposta',
  //       html: `Você deseja <b>desmarcar</b> a Proposta como <b>Aceita</b>?`,
  //       icon: "question",
  //       showCancelButton: true,
  //       showCloseButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //     }).then(async (result) => {
  //         if (result.isConfirmed) {
  //           setPropostas(setInativo(propostas, index));
  //         }})
  //   } else {
  //     Swal.fire({
  //       title: 'Proposta',
  //       html: `Você deseja <b>marcar</b> a Proposta como <b>Aceita</b>?`,
  //       icon: "question",
  //       showCancelButton: true,
  //       showCloseButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //     }).then(async (result) => {
  //         if (result.isConfirmed) {
  //           setPropostas(setAtivo(propostas, index));
  //           Swal.fire({
  //             title: 'Proposta',
  //             html: `A Proposta foi marcada com sucesso como <b>Aceita</b>.`,
  //             icon: "success",
  //             showConfirmButton: true,
  //             showCloseButton: true,
  //             confirmButtonColor: "#111",
  //           })
  //         }})
  //   }
  // };


  // const setAtivo = (propostasRef, indexAtivo) => {
  //   return propostasRef.map((proposta, index) => ({
  //     ...proposta,
  //     ativo: index === indexAtivo,
  //   }));
  // };

  // const setInativo = (propostasRef, indexAtivo) => {
  //   return propostasRef.map((proposta, index) => ({
  //     ...proposta,
  //     ativo: index === indexAtivo ? !proposta.ativo : proposta.ativo,
  //   }));
  // };

  const handlejourney = async (journey, index) => {

    try {
      const comissionDoc = await getDoc(doc(dataBase, 'Comissoes', idVisit));
      if (comissionDoc.exists()) {
        const comissionRef = comissionDoc.data();
        console.log(comissionRef);

        if (!comissionRef.comissao_acordada || comissionRef.comissao_acordada.status !== "Aceito") {
          return Swal.fire({
            title: 'Atenção',
            html: `Este cliente possui uma comissão de representante que ainda não tem o documento da <b>Comissão Acordada</b> enviada ou aceita, e por esse motivo não é possível alterar para <b>${journey}</b>.`,
            icon: "warning",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#111",
          })
        }
      }
      Swal.fire({
        title: 'Jornada do Cliente',
        html: `Você deseja alterar para <b>${journey}</b>?`,
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {

          const docRef = doc(dataBase, 'Visitas', visit.id);

          if (journey === 'Venda Concluida' && visit.indicador.nome !== "Nenhum") {
            if (journey === 'Venda Concluida' && visit.indicador.nome !== "Nenhum") {
              const propostaAtiva = visit.propostas.find(proposta => proposta.ativo);

              const propostaFormatada = propostaAtiva ? {
                kit: propostaAtiva.kit,
                valor: Number(propostaAtiva.valor_final).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              } : null;

              const props = {
                data: moment(visit.data).format("DD/MM/YYYY"),
                nome: visit.cliente,
                marcado: visit.chegadaCliente,
                cidade: visit.cidade,
                consultora: visit.consultora,
                etapa: journey,
                id: visit.id,
                ...propostaFormatada
              };

              await enviarSolicitacaoPost(props);
            }
            const comissionDoc = await getDoc(doc(dataBase, 'Comissoes', visit.id));
            if (comissionDoc.exists()) {
              const comissionData = comissionDoc.data();
              await updateDoc(doc(dataBase, 'Comissoes', visit.id), {
                etapa: 'Venda Concluida',
                updateAt: serverTimestamp(),
                data: moment().format('DD/MM/YYYY'),
                historico: Array.isArray(comissionData.historico) ? [
                  ...comissionData.historico,
                  {
                    data: moment().format('DD/MM/YYYY HH:mm'),
                    text: `A Assistente de Vendas <b>${userRef && userRef.nome}</b> confirmou a venda do Kit Fotovoltaico.`
                  }
                ] : [
                  {
                    data: moment().format('DD/MM/YYYY HH:mm'),
                    text: `A Assistente de Vendas <b>${userRef && userRef.nome}</b> confirmou a venda do Kit Fotovoltaico.`
                  }
                ]
              })
            }
          }
          await updateDoc(docRef, {
            jornada: {
              etapa: journey,
              index: index,
              sigla: journeySG[index],
              cor: journeyColor[index],
              data: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm')}`
            }
          }).then(() => {
            Swal.fire({
              title: 'Jornada do Cliente',
              html: `A Jornada do Cliente foi alterada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#0eb05f",
            })
          });
        }
      })
    } catch (error) {
      console.error('[ERRO]: ', error)
    }
  }

  const deleteInput = (index) => {
    const newInputs = instaladores.filter(((_, i) => i !== index))
    setInstaladores(newInputs);
  }

  const deleteInstall = (document) => {
    try {
      Swal.fire({
        title: 'Instaladores',
        html: `Você deseja excluir o <b>Instalador?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const docRef = doc(dataBase, 'Visitas', idVisit);
          let collectionInstall = visit.instaladores;
          collectionInstall = collectionInstall.filter(data => data.documento !== document);
          await updateDoc(docRef, {
            instaladores: collectionInstall
          }).then(() => {
            Swal.fire({
              title: 'Instaladores',
              html: `O instalador foi excluido com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#0eb05f",
            })
          });
        }
      })
    } catch (error) {

    }
  }

  const changeService = async (visit) => {
    try {
      const confirmation = await Swal.fire({
        title: 'Atenção',
        html: `Você deseja confirmar essa ação?`,
        icon: 'question',
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
        confirmButtonColor: '#0eb05f',
        denyButtonColor: '#dd3333',
        showCloseButton: true,
        showDenyButton: true
      });

      if (confirmation.isConfirmed) {
        let newInstall = installAll.find(obj => obj.nome === install) || {
          nome: 'Nenhum',
          id: '',
          telefone: '',
          data: '',
          horario: '',
        };

        let props = {
          instalador: {
            nome: newInstall.nome,
            uid: newInstall.id || '',
            telefone: newInstall.telefone || '',
            data: visit.instalador?.data || '',
            horario: visit.instalador?.horario || '',
            dataRef: visit.instalador?.dataRef || '',
          }
        };

        console.log(props)

        const refInstall = visit.instalador ? installAll.find(obj => obj.nome === visit.instalador.nome) : [];
        let obras = refInstall?.obras ?? [];

        // console.log(refInstall);
        console.log(newInstall);

        if (refInstall) {
          obras = obras.filter(item => item.id !== visit.id);
          await updateObras(refInstall, obras);
        }
        // console.log(refInstall.nome, obras)

        if (newInstall.nome !== 'Nenhum') { // Caso o novo instalador não seja 'Nenhum'
          const newObras = newInstall.obras ?? [];
          newObras.push({
            id: visit.id,
            nome: visit.cliente,
            finalizado: visit.instalacao ? true : false,
            data: visit.instalador?.data || '',
            horario: visit.instalador?.horario || '',
          })
          console.log(newInstall.nome, newObras)

          await updateObras(newInstall, newObras);

        }

        await updateDoc(doc(dataBase, "Visitas", visit.id), props);

        Swal.fire({
          title: 'Terceirizado',
          html: `O novo <b>Terceirizado</b> foi definido com sucesso.`,
          icon: "success",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        });
        setOpenEdit({ install: false });
      }
    } catch (error) {
      console.error("Error changing service:", error);
      // Trate o erro conforme necessário
    }
  }

  const updateObras = async (refInstall, obras) => {
    try {
      await updateDoc(doc(dataBase, "Membros", refInstall?.id), {
        obras: obras
      });
    } catch (error) {
      console.error("Error updating obras:", error);
      // Trate o erro conforme necessário
    }
  }

  const handleInput = (index, value, type) => {
    const newInputs = [...instaladores];
    if (type === 'nome') {
      newInputs[index].nome = value;
    } else {
      newInputs[index].documento = value;
    }
    setInstaladores(newInputs);
  }

  const addInput = () => {
    if (!addInstall) {
      return setAddInstall(true);
    } else {
      setInstaladores([...instaladores, { nome: '', documento: '' }])
    }
  }

  const closeInstall = () => {
    setInstaladores([{ nome: '', documento: '' }])
    setAddInstall(false);
  }

  const confirmInstall = async () => {
    try {
      if (instaladores.find(data => data.nome === '' || data.documento === '')) {
        return Swal.fire({
          title: 'Instaladores',
          html: `Não é possivel confirmar caso o campo <b>Nome</b> ou <b>RG/CPF</b> esteja vazio.`,
          icon: "warning",
          showCloseButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Ok",
        })
      } else {
        Swal.fire({
          title: 'Instaladores',
          html: `Você deseja confirmar os <b>Instaladores?</b>`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const docRef = doc(dataBase, 'Visitas', idVisit);
            let collectionInstall = visit.instaladores ? visit.instaladores : [];
            collectionInstall = collectionInstall.concat(instaladores);
            await updateDoc(docRef, {
              instaladores: collectionInstall
            }).then(() => {
              Swal.fire({
                title: 'Instaladores',
                html: `Os instaladores foram confirmados com sucesso.`,
                icon: "success",
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonColor: "#111",
              })
              setInstaladores([{ nome: '', documento: '' }])
              setAddInstall(false)
              //  setAnotacao();
            });
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteAnot = async (anot) => {
    try {
      const docRef = doc(dataBase, 'Visitas', idVisit);
      Swal.fire({
        title: 'Anotação',
        html: `Você deseja excluir a <b>Anotação?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const collectionAnot = visit.anotacao;
          const find = collectionAnot.findIndex((date) => date.dataCreat === anot.dataCreat);
          collectionAnot.splice(find, 1)
          await updateDoc(docRef, {
            anotacao: collectionAnot,
            updateAt: serverTimestamp()
          }).then(() => {
            Swal.fire({
              title: 'Anotação',
              html: `A Anotação foi deletada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#111",
            })
            setViewEdit(null)
            setCheckAnotacao();
            setAnotacao();
          });
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const confirmAnot = async () => {
    try {

      const docRef = doc(dataBase, 'Visitas', idVisit);
      Swal.fire({
        title: 'Anotação',
        html: `Você deseja alterar a <b>Anotação?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const collectionAnot = visit.anotacao ? visit.anotacao : [];
          const find = collectionAnot.findIndex((date) => date.dataCreat === checkAnotacao)
          if (find === -1) {
            collectionAnot.push({
              data: `[${moment(new Date()).format('DD/MM/YYYY')} às ${moment(new Date()).format('HH:mm')}] ${userRef && userRef.nome}:`,
              dataCreat: new Date(),
              text: anotacao,
              uid: userRef && userRef.uid
            })
          } else {
            collectionAnot[find] = {
              data: collectionAnot[find].data,
              dataCreat: new Date(),
              text: anotacao,
              uid: userRef && userRef.uid
            }
          }
          await updateDoc(docRef, {
            anotacao: collectionAnot,
            updateAt: serverTimestamp()
          }).then(() => {
            Swal.fire({
              title: 'Anotação',
              html: `A Anotação foi alterada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#0eb05f",
            })
            setViewEdit(null);
            setCheckAnotacao();
            setAnotacao();
          });
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleAnot = (anot, type) => {
    if (type === 'close') {
      setViewEdit();
      setAnotacao();
      setCheckAnotacao();
    } else if (type === 'edit') {
      setAnotacao(anot.text);
      setViewEdit(true);
      setCheckAnotacao(anot.dataCreat);
    } else if (type === 'add') {
      setViewEdit(true);
    }
  }

  const permission = (visit) => { //Permissão
    // console.log(visit)
    // if(visit.categoria === 'lunch') {
    //   if(visit.consultora === userRef.nome || userRef.cargo === 'Administrador') return true;
    // }
    // if(visit.categoria === 'comercial' || visit.categoria === 'comercial_tecnica') {
    //   if(visit.consultora === userRef.nome || userRef.cargo === 'Administrador') return true;
    // }
    // if(visit.categoria === 'pos_venda' || visit.categoria === 'tecnica') {
    //   if(visit.consultora === userRef.nome || userRef.cargo === 'Administrador') return true;
    // }
    if (visit?.consultora === userRef?.nome || userRef?.cargo === 'Administrador' || visit?.responsavel?.id === userRef?.id) {
      return true;
    } else return false;
  }

  const visitsFind = (type, visit) => {
    if (type === "antes")
      return visitsAll.filter(
        (ref) =>
          ref.data === visit.data && ref.chegadaEmpresa === visit.saidaEmpresa &&
          ref.tecnico === visit.tecnico && ref.tipo !== "Almoço" && !ref.visitaAlmoco
      );
    if (type === "depois")
      return visitsAll.filter(
        (ref) =>
          ref.data === visit.data && ref.saidaEmpresa === visit.chegadaEmpresa &&
          ref.tecnico === visit.tecnico && ref.tipo !== "Almoço" && !ref.visitaAlmoco
      );
  };

  function getMonthlyWeekNumber(dt) {
    // como função interna, permite reuso
    var getmonweek = function (myDate) {
      var today = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate(), 0, 0, 0);
      var first_of_month = new Date(myDate.getFullYear(), myDate.getMonth(), 1, 0, 0, 0);
      var p = Math.floor((today.getTime() - first_of_month.getTime()) / 1000 / 60 / 60 / 24 / 7);
      // ajuste de contagem
      if (today.getDay() < first_of_month.getDay()) ++p;
      // ISO 8601.
      if (first_of_month.getDay() <= 3) p++;
      return p;
    }
    // último dia do mês
    var udm = (new Date(dt.getFullYear(), dt.getMonth() + 1, 0, 0, 0, 0)).getDate();
    /*  Nos seis primeiros dias de um mês: verifica se estamos antes do primeiro Domingo.
     *  Caso positivo, usa o último dia do mês anterior para o cálculo.
     */
    if ((dt.getDate() < 7) && ((dt.getDate() - dt.getDay()) < -2))
      return getmonweek(new Date(dt.getFullYear(), dt.getMonth(), 0));
    /*  Nos seis últimos dias de um mês: verifica se estamos dentro ou depois do último Domingo.
     *  Caso positivo, retorna 1 "de pronto".
     */
    else if ((dt.getDate() > (udm - 6)) && ((dt.getDate() - dt.getDay()) > (udm - 3)))
      return 1;
    else
      return getmonweek(dt);
  }

  const deleteVisit = async () => {
    console.log(visit)
    try {
      if (permission(visit)) {
        Swal.fire({
          title: 'Visita',
          html: `Você deseja excluir essa <b>Visita</b>?`,
          icon: "warning",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const visitsAntes = visitsFind("antes", visit);
            const visitsDepois = visitsFind("depois", visit);
            if (visitsAntes.length > 0) {
              visitsAntes.map(async (ref) => {
                await updateDoc(
                  doc(dataBase, "Visitas", ref.id),
                  {
                    chegadaEmpresa: moment(ref.saidaDoCliente, "hh:mm")
                      .add(ref.tempoRota, "seconds")
                      .format("kk:mm"),
                    groupRef: "",
                    group: "",
                    visitaConjunta: false,
                    tipo: "Visita",
                  }
                );
              });
            }
            if (visitsDepois.length > 0) {
              visitsDepois.map(async (ref) => {
                await updateDoc(
                  doc(dataBase, "Visitas", ref.id),
                  {
                    saidaEmpresa: moment(ref.chegadaCliente, "hh:mm")
                      .subtract(ref.tempoRota, "seconds")
                      .format("kk:mm"),
                    groupRef: "",
                    group: "",
                    visitaConjunta: false,
                    tipo: "Visita",
                  }
                );
              });
            }
            await deleteDoc(
              doc(dataBase, "Visitas", visit.id)
            );
            const comissionDoc = await getDoc(doc(dataBase, "Comissoes", visit.id))
            if (comissionDoc.exists()) {
              // console.log('COMISSÃO ENCONTRADO!')
              await deleteDoc(doc(dataBase, "Comissoes", visit.id));
            } else {
              // console.log('COMISSÃO NÃO ENCONTRADO!')
            }
            const date = new Date(visit.data);
            axios.post('https://n8n.corpbrasil.cloud/webhook/321c02a7-03b7-4f81-b4a0-2958660ff449', {
              ID: visit.id,
              data: moment(visit.data).format("DD/MM/YYYY"),
              dataDelete: moment(new Date()).format("DD/MM/YYYY HH:mm"),
              nome: visit.tecnico,
              cliente: visit.cliente,
              marcado: visit.chegadaCliente,
              consultora: visit.consultora,
              city: visit.cidade,
              semana: getMonthlyWeekNumber(date),
              mes: moment(visit.data).format("M"),
              ende: visit.endereco,
              categoria: visit.categoria,
              tipo: visit.tipo,
              confirmar: visit.confirmar,
              extra: visit.preData
            })
            navigate('/visitas')
            Swal.fire({
              title: 'Visita',
              html: `A Visita em <b>${visit.cidade}</b> foi deletada com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#111",
            });
          }
        });
      } else {
        Swal.fire({
          title: 'Acesso Negado',
          html: `Somente o responsável pode Excluir a <b>Visita.</b>`,
          icon: "error",
          showCloseButton: true,
          confirmButtonColor: "#111",
          confirmButtonText: "Ok",
        })
      }
    } catch { }
  };

  const enviarSolicitacaoPost = async (props) => {
    const representanteDoc = await getDoc(doc(dataBase, 'Membros', visit.indicador?.id));

    if (representanteDoc.exists()) {
      const representanteRef = representanteDoc.data();
      await axios.post('https://n8n.corpbrasil.cloud/webhook/febca167-a1fa-43c9-bab6-304bd2919b84', {
        ...props,
        representante: representanteRef.nome,
        telefone: '5515991573088', //representanteRef.telefone
        codigo: representanteRef.id_representante
      })

    }
  }

  // const cancelInstall = async () => {
  //   try {
  //     Swal.fire({
  //       title: 'Instalação',
  //       html: `Você deseja cancelar o <b>Agendamento da Instalação</b>?`,
  //       icon: 'question',
  //       showCloseButton: true,
  //       showCancelButton: true,
  //       confirmButtonColor: "#0eb05f",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: 'Sim',
  //       cancelButtonText: 'Não',
  //     }).then(async (result) => {
  //       if(result.isConfirmed) {
  //         const docRef = doc(dataBase, 'Visitas', idVisit);
  //         await updateDoc(docRef, {
  //           instalador: {
  //             nome: visit.instalador?.nome,
  //             telefone: visit.instalador?.telefone,
  //             uid: visit.instalador?.uid,
  //             dataRef: null,
  //             data: null,
  //             horario: null
  //           }
  //         }).then(() => {

  //           Swal.fire({
  //             title: 'Instalação',
  //             html: `O Agendamento da Instalação foi cancelado com sucesso.`,
  //             icon: "success",
  //             showConfirmButton: true,
  //             showCloseButton: true,
  //             confirmButtonColor: "#0eb05f",
  //           });

  //         });
  //       }
  //     })
  //   } catch (error) {
  //     console.error('[ERRO]: ', error)
  //   }
  // }

  const handleInstall = (data) => {
    if (data) {
      navigate('/instalacao')
    } else {
      setOpenVisit('install');
    }
  }

  const checkInstall = () => {
    const isAdmin = userRef?.cargo === 'Administrador';
    const isPostSale = userRef?.nome === 'Pós-Venda' && userRef?.id === visit.representante?.id;
    const hasValidInstaller = visit.instalador?.nome && visit.instalador.nome !== 'Nenhum';

    if (isAdmin || isPostSale) {
      if (!hasValidInstaller) {
        return {
          msg: 'Só é possível agendar a instalação após escolher um instalador',
          permission: true
        };
      } else {
        return {
          msg: '',
          permission: false
        };
      }
    } else {
      return {
        msg: 'Você não tem permissão para agendar a instalação',
        permission: true
      };
    }
  };

  const statusVisit = async (type) => {

      const visitRef = doc(dataBase, "Visitas", visit.id);
      const date = new Date(visit.data);

      const typeRef = type === 'confirmar' ? 'Confirmada' : 'Cancelada'
      const typeCheck = Boolean(type === 'confirmar');
      
      const result = await Swal.fire({
          title: 'Atenção',
          html: `Você deseja ${type} a <b>Visita</b>?`,
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#0eb05f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })

        if (result.isConfirmed) {
          try {

            await updateDoc(visitRef, {
              confirmar: typeCheck, 
              visita_status: {
                status: typeRef,
                data: new Date(),
                responsavel: userRef?.nome,
                responsavel_id: userRef?.id
              }
            });

            const customerDoc = await getDoc(doc(dataBase, 'Clientes', visit.cliente_id));
            const customerData = customerDoc.exists() ? customerDoc.data() : { telefone: removePrefixPhone(visit?.preData?.telefone) || ''};
  
            await axios.post('https://n8n.corpbrasil.cloud/webhook/63b48297-3e22-4eba-8b21-45e87f52f3fb', {
              ID: visit.id,
              data: moment(visit.data).format("DD/MM/YYYY"),
              nome: visit.tecnico,
              cliente: visit.cliente,
              telefone: customerData.telefone,
              marcado: visit.chegadaCliente,
              consultora: visit.consultora,
              city: visit.cidade,
              semana: getMonthlyWeekNumber(date),
              mes: moment(visit.data).format("M"),
              ende: visit.endereco,
              categoria: visit.categoria,
              confirmada: type === 'confirmar' ? 'Sim' : 'Não',
            })
  
            await Swal.fire({
              title: 'Sucesso',
              html: `A Visita foi <b>${typeRef}</b> com sucesso.`,
              icon: "success",
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonColor: "#0eb05f",
            });

          } catch (error) {
            console.error('[ERRO]: ', error);
            toast({ icon: 'error', text: `Ocorreu um erro ao ${type} a visita.`, error: error })
          }
        }
  };

  return (
    <div className={styles.panel}>
      {/* <Requirement type={type} view={view} collectData={collectData} openBox={openBox} closeBox={closeBox} /> */}
      <div className={styles.panel_header}>
        <IconButton className={styles.back_button} onClick={() => navigate(`/visitas`)}><ArrowCircleLeftOutlinedIcon sx={{ fontSize: '50px' }} /></IconButton>
        <div className={styles.panel_header_title}>
          {visit && visit.categoria === 'comercial' ? <RequestQuoteIcon sx={{ fontSize: '45px', fill: "#0eb05f" }} /> : null}
          {visit && visit.categoria === 'comercial_tecnica' ? <PeopleIcon sx={{ fontSize: '45px', fill: "#576af5" }} /> : null}
          {visit && visit.categoria === 'pos_venda' ? <EngineeringIcon sx={{ fontSize: '45px', fill: "#e24725" }} /> : null}
          {visit && visit.categoria === 'tecnica' ? <EngineeringIcon sx={{ fontSize: '45px', fill: "#1c256e" }} /> : null}
          <h1>{visit && visit.cliente}</h1>
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <h2>Visita {visit && moment(visit.data).format("DD/MM/YYYY")} ás {visit && visit.chegadaCliente}</h2>
            {visit && visit.categoria !== 'pos_venda' ?
              handleVisitConfirm(visit && visit.confirmarVisita) : null}
          </Box>
        </div>
      </div>
      {visit && visit.observacao &&
        <Alert severity="info" color='warning' sx={{ margin: '1rem 0', justifyContent: 'center' }}>
          <AlertTitle>Observação</AlertTitle>
          <b>{visit.observacao}</b>
        </Alert>
      }
      {visit?.categoria === 'comercial_tecnica' || visit?.categoria === 'tecnica' ?
        <><h2 className={styles.title_section}>Jornada do Cliente</h2>
          <div className={styles.step_box}>
            <div className={styles.step_client}>
              {journey.map((journey, index) => {
                const step = visit.jornada?.index;
                let styleStep = null;
                if (step === index) {
                  if (journey === 'Instalação') {
                    styleStep = styles.active_item_last;
                  } else {
                    styleStep = styles.current_active_item;
                  }
                } else if (step > index) {
                  styleStep = styles.active_item
                }
                return (
                  <div className={styleStep}>
                    {visit && visit.indicador && visit.indicador.nome !== 'Nenhum' ?
                      <NewTooltip placement='top' title={`Alterar etapa para ${journey}`}>
                        <span>
                          <button
                            disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.uid !== visit.uid))}
                            onClick={() => handlejourney(journey, index)}>
                            {journey}
                          </button>
                        </span>
                      </NewTooltip>
                      :
                      <NewTooltip placement='top' title={`Alterar etapa para ${journey}`}>
                        <button
                          disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.nome !== 'Pós-Venda'))}
                          onClick={() => handlejourney(journey, index)}>
                          {journey}
                        </button>
                      </NewTooltip>
                    }
                  </div>
                )
              })}
            </div>
            {visit.jornada ?
              <span>Atualização: {visit.jornada?.data}</span> : null
            }
          </div></> : null
      }
      <div className={styles.panel_content_item}>
        <h2>Informações</h2>
        <div className={styles.panel_content_box}>
          <div className={styles.panel_content_title}>
            <h3>Dados do Cliente</h3>
          </div>
          <ul className={styles.info_item_only}>
            <li><p><b>Nome:</b> {visit.cliente}</p></li>
            <li><p><b>Cidade: </b>{visit.cidade}</p></li>
            <li><p><b>Telefone: </b>{visit.preData?.telefone ?? 'Não Informado'}</p></li>
            <li><p><b>Responsável: </b>{visit.consultora}</p></li>
            <li><p><b>Técnico: </b>{visit.tecnico}</p></li>
            <li><p><b>Analista: </b>{visit.representante?.nome ?? 'Nenhuma'}</p></li>
          </ul>
          <div className={styles.panel_content_title}>
            <h3>Energia</h3>
          </div>
          <div className={styles.info}>
            <ul className={styles.info_item}>
              <li><p><b>Concessionária:</b> {visit.concessionaria ? visit.concessionaria : 'Não Informado'}</p></li>
              <li><p><b>Análise Padrão: </b>{visit.analise_padrao && visit.analise_padrao ?
                visit.analise_padrao : 'Aguardando o Técnico'}
                {visit.analise_padrao === 'Sim ✅' &&
                  <IconButton aria-label="Visualizar Análise do Técnico" data-cooltipz-dir="right" size='small' sx={{ marginBottom: '0.2rem' }} onClick={() => swalAlert('Análise Padrão', `<b>Técnico:</b> ${visit.anotacao_padrao}`, 'info')} ><InfoIcon sx={{ width: '20px', height: '20px' }} /></IconButton>}</p>
              </li>
              <li><p><b>Tipo do Padrão:</b> {visit.padrao ? visit.padrao?.tipo : 'Aguardando o Técnico'}</p></li>
              <li><p><b>O Padrão precisa de melhoria?</b> {visit.padrao ? visit.padrao?.melhoria : 'Aguardando o Técnico'}
                {visit.padrao?.melhoria === 'Sim ✅' && <IconButton aria-label="Visualizar Melhoria" data-cooltipz-dir="right" size='small' sx={{ marginBottom: '0.2rem' }} onClick={() => swalAlert('Melhorias do Padrão', `<b>Técnico:</b> ${visit.padrao?.texto}`, 'info')} ><InfoIcon sx={{ width: '20px', height: '20px' }} /></IconButton>}</p>
              </li>
              <li><p><b>Bitola Ramal de Entrada:</b> {visit.medida_bitola_ramal ? visit.medida_bitola_ramal : 'Aguardando o Técnico'}</p></li>
              <li><p><b>Bitola Rede do Cliente:</b> {visit.medida_bitola_cliente ? visit.medida_bitola_cliente : 'Aguardando o Técnico'}</p></li>
            </ul>
            <ul className={styles.info_item}>
              <li><p><b>Precisa trocar o Disjuntor? </b> {visit.trocar_disjuntor ? visit.trocar_disjuntor : 'Aguardando o Técnico'}</p></li>
              <li><p><b>A injeção de Energia CA deverá ser no Disjuntor geral?</b> {checkStep.step1 ? info.injecao_energia_ca : 'Aguardando o Técnico'}</p></li>
              <li><p><b>Possui DPS?</b> {visit.dps ? visit.dps : 'Aguardando o Técnico'}</p></li>
              {visit.dps === 'Sim ✅' &&
                <><li><p><b>Qual a classe do DPS?</b> {visit.dps_classe ? visit.dps_classe : 'Aguardando o Técnico'}
                  {visit.dps_classe === 'Classe 2' && <IconButton aria-label="Precisa ser realizado a troca do DPS" data-cooltipz-dir="top" size='small' sx={{ marginBottom: '0.2rem' }}><InfoIcon sx={{ width: '20px', height: '20px', fill: '#ed6c02' }} /></IconButton>}</p>
                </li>
                  <li><p><b>É Necessário a troca do DPS?</b> {visit.dps_troca ? visit.dps_troca : 'Aguardando o Técnico'}</p></li></>
              }
              <li><p><b>Localização do GPS do Padrão:</b> {checkStep.step0 ? <a target="_blank" rel="noreferrer" href={`https://maps.google.com/?q=${info && info.gps_padrao.lat},${info && info.gps_padrao.lng}`} >Clique aqui</a> : 'Aguardando o Técnico'} </p></li>
              <li><p><b>Será realizado Monitoramento? </b>{visit.monitoramento ? visit.monitoramento : 'Aguardando o Pós-Venda'}</p></li>
            </ul>
          </div>
          <div className={styles.panel_content_title}>
            <h3>Telhado</h3>
          </div>
          <div className={styles.info} style={{ flexDirection: 'column' }}>
            <ul className={styles.info_item}>
              <li>
                <p style={{ textAlign: 'center' }}><b>Análise Telhado: </b>{visit.analise_telhado && visit.analise_telhado ? visit.analise_telhado : 'Aguardando o Técnico'}
                  {visit.analise_telhado === 'Sim ✅' && <IconButton aria-label="Visualizar Análise do Técnico" data-cooltipz-dir="right" size='small' sx={{ marginBottom: '0.2rem' }} onClick={() => swalAlert('Análise Telhado', `<b>Técnico:</b> ${visit.anotacao_telhado}`, 'info')} ><InfoIcon sx={{ width: '20px', height: '20px' }} /></IconButton>}</p>
              </li>
            </ul>
            <div className={styles.info}>
              {checkStep.step3 ? info.telhado.map((data, index) => (
                <ul key={index} className={styles.info_item}>
                  <li><p><b>Tipo:</b> {data.tipo}</p></li>
                  <li><p><b>Medida: </b>{data.medida}</p></li>
                </ul>
              )) :
                <ul className={styles.info_item}>
                  <li><p><b>Tipo:</b> Aguardando o Técnico</p></li>
                  <li><p><b>Medida: </b> Aguardando o Técnico</p></li>
                </ul>}
            </div>
          </div>
          <div className={styles.panel_content_title}>
            <h3>A Instalação será em Condomínio? {visit && visit.condominio ? visit.condominio : "Não Informado"}</h3>
          </div>
          {visit && visit.condominio === 'Sim ✅' &&
            <><div className={styles.items_install}>
              {visit.instaladores && visit.instaladores.map((data, index) => (
                <><ul key={index} className={styles.info_item}>
                  <li><p><b>Nome:</b> {data.nome}</p></li>
                  <li><p><b>RG/CPF: </b>{data.documento}</p></li>
                </ul>
                  <IconButton color='error' className={styles.delete_input} onClick={() => deleteInstall(data.documento)} variant="outlined"><CloseIcon /></IconButton> </>
              ))}
            </div>
              <div className={styles.items_big}>
                {addInstall ?
                  <div>
                    {instaladores.map((pessoa, index) => (
                      <div key={index} className={styles.input_double}>
                        <TextField
                          autoFocus
                          fullWidth
                          margin="dense"
                          label="Nome"
                          type="text"
                          value={pessoa.nome ? pessoa.nome : ''}
                          onChange={(e) => handleInput(index, e.target.value, 'nome')}
                          variant="outlined" />
                        <TextField
                          autoFocus
                          fullWidth
                          margin="dense"
                          label="RG/CPF"
                          type="text"
                          value={pessoa.documento ? pessoa.documento : ''}
                          onChange={(e) => handleInput(index, e.target.value, 'documento')}
                          variant="outlined" />
                        <IconButton color='error' disabled={index === 0 ? true : false} className={styles.delete_input} onClick={() => deleteInput(index)} variant="outlined"><CloseIcon /></IconButton>
                      </div>
                    ))}
                    <Button sx={{ margin: '1rem 0', width: '15rem' }} color='success' variant='outlined' onClick={() => addInput()} startIcon={<AddCircleIcon />}>Adicionar Instalador</Button>
                    <div className={styles.anotacao_button}>
                      <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        type="submit"
                        startIcon={<CheckCircleOutlineIcon />}
                        onClick={() => confirmInstall()}
                      >
                        Confirmar
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        type="submit"
                        startIcon={<BlockIcon />}
                        onClick={() => closeInstall()}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </div> :
                  <Button sx={{ margin: '1rem 0', width: '15rem' }} color='success' variant='outlined' onClick={() => addInput()} startIcon={<AddCircleIcon />}>Adicionar Instalador</Button>}
              </div>
            </>
          }
        </div>
      </div>
      <div className={styles.panel_content_item}>
        <h2>Localização</h2>
        {isLoaded ?
          <GoogleMap mapContainerStyle={{ width: '100%', height: '300px', margin: '1rem 0' }} zoom={18} center={{ lat: position.lat, lng: position.lng }}>
            {position && <MarkerF position={{ lat: position.lat, lng: position.lng }} />}
          </GoogleMap> :
          <Stack width='100%'>
            <Skeleton variant='rounded' width={"100%"} height={300} />
          </Stack>
        }
      </div>
      <div className={styles.panel_content_item}>
        <h2>Anotação</h2>
        {viewEdit ?
          <><textarea className={styles.edit_anotacao} value={anotacao} onChange={(e) => setAnotacao(e.target.value)} cols="30" rows="5"></textarea>
            <div className={styles.anotacao_button}>
              <Button
                variant='contained'
                disableElevation
                color="success"
                sx={{ textTransform: 'capitalize' }}
                type="submit"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={() => confirmAnot()}
              >
                Confirmar
              </Button>
              <Button
                variant='contained'
                disableElevation
                color="error"
                sx={{ textTransform: 'capitalize' }}
                type="submit"
                startIcon={<BlockIcon />}
                onClick={() => handleAnot(visit.id, 'close')}
              >
                Cancelar
              </Button>
            </div></> :
          <><div className={styles.anotacao}>
            {visit.anotacao && visit.anotacao.map((anot, index) => (
              <div key={index}>
                <div>
                  <b>{anot.data}</b>
                  <p>{anot.text}</p>
                </div>
                {userRef && (userRef.uid === anot.uid || userRef.cargo === 'Administrador') &&
                  <div>
                    <IconButton
                      aria-label="Editar Anotação"
                      data-cooltipz-dir="left"
                      size="small"
                      onClick={() => handleAnot(anot, 'edit')}
                    ><EditIcon /></IconButton>
                    <IconButton
                      aria-label="Deletar Anotação"
                      data-cooltipz-dir="left"
                      size="small"
                      color='error'
                      onClick={() => deleteAnot(anot)}
                    ><DeleteIcon /></IconButton>
                  </div>
                }
              </div>
            ))}
          </div>
            <Button
              variant='contained'
              disableElevation
              color="success"
              sx={{ marginBottom: '1rem', textTransform: 'capitalize' }}
              startIcon={<AddCircle />}
              onClick={() => setViewEdit(true)}>Adicionar Anotacao</Button>
          </>}
      </div>
      {visit && (visit.categoria === 'comercial_tecnica' || visit.categoria === 'tecnica') ?
        <div className={styles.panel_content_item}>
          <h2>Fotos</h2>
          <div className={styles.panel_content_box} style={{ padding: '1rem 0' }}>
            <Tabs value={TabsValue} onChange={(e, newValue) => setTabsValue(newValue)} aria-label="Fotos" centered>
              <Tab sx={{ textTransform: 'capitalize' }} label="Entrada" {...a11yProps(1)} />
              <Tab sx={{ textTransform: 'capitalize' }} label="Telhado" {...a11yProps(2)} />
              <Tab sx={{ textTransform: 'capitalize' }} label="Telhado com medida" {...a11yProps(3)} />
              <Tab sx={{ textTransform: 'capitalize' }} label="Projeto" {...a11yProps(4)} />
            </Tabs>
            <CustomTabPanel value={TabsValue} index={0}>
              {checkStep.step1_5 ?
                <><IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                  <SlickSlider ref={slider} {...settingsVisit} className={styles.slider}>
                    {photosStep1 && photosStep1.map((photo, index) => (
                      <div key={index} className={styles.photos}>
                        <img rel="preconnect" src={photo} alt="" />
                      </div>
                    ))}
                  </SlickSlider>
                  <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton></> :
                <h2 className={styles.alert}>As Fotos ainda não foram enviadas pelo Técnico</h2>
              }
            </CustomTabPanel>
            <CustomTabPanel value={TabsValue} index={1}>
              {checkStep.step2 ?
                <><IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton><SlickSlider ref={slider} {...settingsVisit} className={styles.slider}>
                  {photosStep2 && photosStep2.map((photo, index) => (
                    <div key={index} className={styles.photos}>
                      {photo.type === 'photo' ?
                        <img rel="preconnect" src={photo.url} alt="" /> :
                        <video className={styles.img_visit} width='100%' controls muted playsInline>
                          <source src={photo.url} type="video/mp4" />
                        </video>
                      }
                    </div>
                  ))}
                </SlickSlider><IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton></> :
                <h2 className={styles.alert}>As Fotos ainda não foram enviadas pelo Técnico</h2>
              }
            </CustomTabPanel>
            <CustomTabPanel value={TabsValue} index={2}>
              {checkStep.step3 ?
                <><IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                  {photosStep3.length === 1 ?
                    <div className={styles.photos}>
                      <img rel="preconnect" src={photosStep3} alt="" />
                    </div> :
                    <SlickSlider ref={slider} {...settingsVisit} className={styles.slider}>
                      {photosStep3 && photosStep3.map((photo, index) => (
                        <div data-index={index} key={index} className={styles.photos}>
                          <img rel="preconnect" src={photo} alt="" />
                        </div>
                      ))}
                    </SlickSlider>
                  }
                  <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton></> :
                <h2 className={styles.alert}>As Fotos ainda não foram enviadas pelo Técnico</h2>
              }
            </CustomTabPanel>
            <CustomTabPanel value={TabsValue} index={3}>
              {checkStep.step4 ?
                <><IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickPrev()}><ArrowBackIosIcon /></IconButton>
                  {photosStep4.length === 1 ?
                    <div className={styles.photos}>
                      <img rel="preconnect" src={photosStep4} alt="" />
                    </div> :
                    <SlickSlider ref={slider} {...settingsVisit} className={styles.slider}>
                      {photosStep4 && photosStep4.map((photo, index) => (
                        <div data-index={index} key={index} className={styles.photos}>
                          <img rel="preconnect" src={photo} alt="" />
                        </div>
                      ))}
                    </SlickSlider>
                  }
                  <IconButton sx={{ height: '50px', width: '50px' }} onClick={() => slider?.current?.slickNext()}><ArrowForwardIosIcon /></IconButton></> :
                <h2 className={styles.alert}>As Fotos ainda não foram enviadas pelo Pós-Venda</h2>
              }
            </CustomTabPanel>
          </div>
        </div> : null
      }
      {/* {visit && visit.propostas && visit.propostas.length > 0 ? 
          <div className={styles.panel_content_item}>
            <h2>Propostas</h2>
            <div className={styles.panel_content_box} style={{ padding: '1.5rem 2rem 1rem 1rem' }}>
            {visit.propostas && visit.propostas.map((data, index) => (
                <div key={index} className={[styles.panel_content_input]} style={{ alignItems: 'center' }}>
                  <IconButton 
                    onClick={() => ativeProposta(index)}
                    disabled
                    sx={{ height: '40px' }}>
                    {data.ativo ? 
                      <StarIcon sx={{ fill: '#ffe44c' }} /> : 
                      <StarBorderIcon sx={{ fill: '#ccc' }} />
                    }
                  </IconButton>
                  <ThemeProvider theme={theme}>
                    <TextField
                        label="Kit Fotovoltaico"
                        type="text"
                        value={data.kit ?? ''}
                        fullWidth
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                        disabled
                        variant="outlined"
                    />
                    <CurrencyInput
                      customInput={TextField}
                      style={{ margin: '0.5rem 0rem 0.7rem 0' }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      disabled
                      label="Valor Inicial"
                      placeholder="R$ 00"
                      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                      decimalsLimit={2}
                      InputProps={{readOnly: true, disableUnderline: true}}
                      value={data.valor_inicial || ''}
                      fullWidth
                      />
                    <CurrencyInput
                      customInput={TextField}
                      style={{ margin: '0.5rem 0rem 0.7rem 0' }}
                      label="Valor Final"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      disabled
                      placeholder="R$ 00"
                      InputProps={{readOnly: true, disableUnderline: true}}
                      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                      decimalsLimit={2}
                      value={data.valor_final || ''}
                      fullWidth
                      />
                  </ThemeProvider>
                </div>
              ))}
            </div>
          </div> : null
        } */}
      <Gps isLoaded={isLoaded} visit={visit} open={openVisit === 'step00'} close={() => setOpenVisit('step0')} />
      <RegisterVisitStep1 visit={visit} open={openVisit === 'step1'} idStep1={idStep1} close={() => setOpenVisit('step0')} />
      <RegisterVisitStep15 visit={visit} open={openVisit === 'step1_5'} idStep1={idStep1} close={() => setOpenVisit('step0')} />
      <RegisterVisitStep2 visit={visit} open={openVisit === 'step2'} close={() => setOpenVisit('step0')} />
      <RegisterVisitStep25 visit={visit} open={openVisit === 'step2_5'} step2={step2} close={() => setOpenVisit('step0')} />
      <RegisterVisitStep3 visit={visit} open={openVisit === 'step3'} close={() => setOpenVisit('step0')} sellers={sellers} />
      <RegisterVisitStep4 visit={visit} open={openVisit === 'step4'} close={() => setOpenVisit('step0')} userRef={userRef} idStep4={idStep4} />
      <RegisterVisitStep45 visit={visit} open={openVisit === 'step4_5'} idStep4={idStep4} close={() => setOpenVisit('step0')} userRef={userRef} />
      <RegisterVisitStep5 visit={visit} open={openVisit === 'step5'} close={() => setOpenVisit('step0')} />
      <RegisterVisitStep6 visit={visit} open={openVisit === 'step6'} close={() => setOpenVisit('step0')} />
      <RegisterInstall visit={visit} open={openVisit === 'install'} close={() => setOpenVisit('step0')} />
      {visit && (visit.categoria === 'comercial_tecnica' || visit.categoria === 'tecnica') ?
        <div className={styles.panel_content_item}>
          <h2>Visita Técnica</h2>
          <div className={styles.panel_content_box} style={{ padding: '1rem 0' }}>
            <div className={styles.panel_content_step}>
              <ThemeProvider theme={theme}>
                {/* <Button variant="contained" onClick={() => setOpenVisit('step2')}>Etapa 3</Button> */}
                {checkStep.step0 ?
                  <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>GPS</Button> :
                  <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico' && userRef.nome !== 'Pós-Venda') ? true : false} variant="contained" color="secondary" onClick={() => setOpenVisit('step00')}>GPS</Button>
                }
                {checkStep.step1 ?
                  <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Dados</Button> :
                  <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico' && userRef.nome !== 'Pós-Venda')) || checkStep.step0 === false ? true : false} variant="contained" onClick={() => setOpenVisit('step1')}>Dados</Button>
                }
                {checkStep.step1_5 ?
                  <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Fotos (Solo)</Button> :
                  <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico' && userRef.id !== visit.representante?.id && userRef.nome !== 'Pós-Venda')) || checkStep.step1 === false ? true : false} variant="contained" onClick={() => setOpenVisit('step1_5')}>Fotos (Solo)</Button>
                }
                {checkStep.step2 ?
                  <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Fotos (Telhado)</Button> :
                  <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico' && userRef.id !== visit.representante?.id && userRef.nome !== 'Pós-Venda')) || checkStep.step1_5 === false ? true : false} variant="contained" onClick={() => setOpenVisit('step2')}>Fotos (Telhado)</Button>
                }
                {checkStep.step2_5 ?
                  <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Vídeo (Telhado)</Button> :
                  <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico' && userRef.id !== visit.representante?.id && userRef.nome !== 'Pós-Venda')) || checkStep.step2 === false ? true : false} variant="contained" onClick={() => setOpenVisit('step2_5')}>Vídeo (Telhado)</Button>
                }
                {checkStep.step3 ?
                  <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Fotos (Medidas)</Button> :
                  <Button disableElevation sx={{ textTransform: 'capitalize' }} disabled={(userRef && (userRef.cargo !== 'Administrador' && userRef.cargo !== 'Técnico' && userRef.id !== visit.representante?.id && userRef.nome !== 'Pós-Venda')) || checkStep.step2 === false ? true : false} variant="contained" onClick={() => setOpenVisit('step3')}>Fotos (Med. Telhado)</Button>
                }
                {/* <Button variant="contained" onClick={() => setOpenVisit('step3')}>Etapa 3</Button> */}
              </ThemeProvider>
            </div>
            {visit?.visita_status?.status === 'Confirmada' ? 
              <Box sx={{ display: 'flex', justifyContent: 'center', padding: '0.5rem' }}>
                <span>Visita Técnica finalizada em <b>{formatDate(visit?.visita_status?.data)}</b></span>
              </Box> : null
            }
          </div>
        </div> : null}
      {visit && (visit.categoria === 'comercial_tecnica' || visit.categoria === 'tecnica') ?
        <div className={styles.panel_content_item}>
          <h2>Pós-Venda</h2>
          <div className={styles.panel_content_box} style={{ padding: '1rem 0' }}>
            <div className={styles.panel_content_step}>
              <ThemeProvider theme={theme}>
                {checkStep.step4 ?
                  <div>
                    {/* <Button variant="outlined" fullWidth sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Projeto</Button> */}
                    {userRef && (userRef.cargo === 'Administrador' || userRef.id === visit.representante?.id) ?
                      <Button variant="outlined" fullWidth sx={{ textTransform: 'capitalize' }} color="error" onClick={() => deleteStep(visit, idStep4, ['telhadoIrradiacao', 'telhadoMedida', 'telhadoPlaca'])} endIcon={<DeleteForeverOutlinedIcon />}>Projeto</Button> :
                      <Button variant="outlined" fullWidth sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Projeto</Button>
                    }
                  </div>
                  :
                  <Button
                    disableElevation
                    disabled={(userRef && (userRef.cargo === 'Administrador' || (userRef.nome === 'Pós-Venda' && userRef.id === visit.representante?.id))) ? false : true}
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                    onClick={() => setOpenVisit('step4')}>Projeto
                  </Button>
                }
                {checkStep.step4_5 ?
                  <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Kit & Instalação</Button>
                  :
                  <Button
                    disableElevation
                    disabled={(userRef && (userRef.cargo === 'Administrador' || (userRef.nome === 'Pós-Venda' && userRef.id === visit.representante?.id))) && checkStep.step4 === true ? false : true}
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                    onClick={() => setOpenVisit('step4_5')}>Kit & Instalação</Button>
                }
                {visit.pdf ?
                  <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Homologação</Button>
                  :
                  <Button
                    disableElevation
                    disabled={(userRef && (userRef.cargo === 'Administrador' || (userRef.nome === 'Pós-Venda' && userRef.id === visit.representante?.id))) ? false : true}
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                    onClick={() => setOpenVisit('step6')}>Homologação</Button>
                }
              </ThemeProvider>
              <div>
                <NewTooltip placement={'top'} title={checkInstall().msg}>
                  <span>
                    <Button
                      disableElevation
                      disabled={checkInstall().permission}
                      variant="contained"
                      color='info'
                      fullWidth
                      aria-label={visit.instalador?.nome === 'Nenhum' ? 'Escolha um instalador para desbloquear essa ação' : null}
                      data-cooltipz-dir="top"
                      startIcon={<DateRangeIcon />}
                      sx={{ textTransform: 'capitalize', height: '100%' }}
                      onClick={() => handleInstall(visit.instalador?.dataRef)}>{visit.instalador?.dataRef ? 'Reagendar Instalação' : 'Agendar Instalação'}
                    </Button>
                    {/* {userRef && (userRef.cargo === 'Administrador' || userRef.id === visit.representante?.id) && visit.instalador?.dataRef ? 
                      <IconButton sx={{ borderRadius: '5px' }} color="error" onClick={cancelInstall}><DeleteForeverOutlinedIcon /></IconButton> : null
                    } */}
                  </span>
                </NewTooltip>
              </div>
            </div>
          </div>
        </div> : null}
      {visit && (visit.categoria === 'comercial_tecnica' || visit.categoria === 'tecnica') ?
        <div className={styles.panel_content_item}>
          <h2>Financeiro</h2>
          <div className={styles.panel_content_box} style={{ padding: '1rem 0' }}>
            <div className={styles.panel_content_step}>
              <ThemeProvider theme={theme}>
                {visit && visit.infra_ca ?
                  <Button variant="outlined" sx={{ textTransform: 'capitalize' }} color="success" endIcon={<CheckCircleIcon />}>Custos</Button> :
                  <Button
                    disableElevation
                    sx={{ textTransform: 'capitalize' }}
                    disabled={(userRef && (userRef.cargo === 'Administrador' || (userRef.nome === 'Pós-Venda' && userRef.id === visit.representante?.id))) ? false : true}
                    variant="contained" onClick={() => setOpenVisit('step5')}>Custos</Button>
                }
              </ThemeProvider>
            </div>
          </div>
        </div> : null}
      {visit && (visit.categoria === 'comercial_tecnica' || visit.categoria === 'tecnica') ?
        <div className={styles.panel_content_item}>
          <h2>Terceirizado</h2>
          <div className={styles.panel_content_box} style={{ padding: '1rem 0' }}>
            <div className={styles.panel_content_step}>
              <div className={styles.panel_content_service}>
                <ThemeProvider theme={theme}>
                  <FormControl>
                    <InputLabel id="select-label">Instalador</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      value={install ?? 'Nenhum'}
                      disabled={!openEdit.install}
                      label="Instalador"
                      onChange={(e) => setInstall(e.target.value)}
                    >
                      <MenuItem value="Nenhum">Nenhum</MenuItem>
                      {installAll && installAll.map((data, index) => (
                        <MenuItem key={index} value={data.nome}>{data.nome}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {userRef && (userRef.cargo === "Administrador" || userRef.nome === 'Pós-Venda') &&
                    <div className="visit_buttons" style={{ flexDirection: 'column' }}>
                      {openEdit.install ?
                        <div >
                          <Button
                            id="basic-button"
                            disableElevation
                            color='success'
                            sx={{ textTransform: 'capitalize' }}
                            disabled={install === visit.instalador?.nome}
                            onClick={() => changeService(visit, 'Install')}
                            variant='contained'
                            startIcon={<CheckCircleOutlineIcon />}
                          >
                            Confirmar Técnico
                          </Button>
                          <Button
                            id="basic-button"
                            color='error'
                            disableElevation
                            sx={{ textTransform: 'capitalize' }}
                            onClick={() => {
                              setOpenEdit({ install: false });
                              setInstall(visit.instalador?.nome || 'Nenhum')
                            }}
                            variant='contained'
                            startIcon={<BlockIcon />}
                          >
                            Cancelar
                          </Button>
                        </div> :
                        userRef && (userRef.cargo === 'Administrador' || userRef.uid === visit.representante?.id) ?
                          <Button
                            disableElevation
                            variant="contained"
                            sx={{ textTransform: 'capitalize', width: '100%' }}
                            onClick={() => setOpenEdit({ install: true })}
                            startIcon={<PublishedWithChangesIcon />}
                          >
                            Alterar
                          </Button> : null
                      }
                    </div>
                  }
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div> : null}
      <div className={styles.button_footer}>
        {userRef?.cargo === 'Administrador' || userRef?.nome === 'Pós-Venda' || userRef?.cargo === 'Técnico' ?
        visit?.visita_status?.status === 'Confirmada' ? 
          <Button
            disableElevation
            variant='contained'
            color='error'
            startIcon={<FaRegCalendarTimes size={'1rem'} />}
            disabled={checkStep.step1 && checkStep.step2 && checkStep.step3 ? false : true}
            style={{ textTransform: 'capitalize' }}
            onClick={() => statusVisit('cancelar')}>
            Cancelar Visita
          </Button> : 
          <Button
            disableElevation
            variant='contained'
            color='success'
            startIcon={<FaCalendarCheck size={'1rem'} />}
            disabled={checkStep.step1 && checkStep.step2 && checkStep.step3 ? false : true}
            style={{ textTransform: 'capitalize' }}
            onClick={() => statusVisit('confirmar')}>
            Finalizar Visita
          </Button> : null
        }
        <Link to={"../visita/" + idVisit} target='_blank'>
          <Button
            disableElevation
            variant='contained'
            startIcon={<ArticleIcon />}
            disabled={checkStep.step1 && checkStep.step2 && checkStep.step3 ? false : true}
            style={{ textTransform: 'capitalize' }}>
            Ver Visita Completa
          </Button>
        </Link>
        <Link to={"../visita/" + idVisit + "/orcamento"} target='_blank'>
          <Button
            disableElevation
            color='warning'
            variant='contained'
            startIcon={<RequestQuoteIcon />}
            disabled={checkStep.step1 && checkStep.step2 && checkStep.step3 ? false : true}
            style={{ textTransform: 'capitalize' }}>
            Orçamento
          </Button>
        </Link>
        <ThemeProvider theme={theme}>
          {(userRef?.cargo === 'Administrador' || userRef?.uid === visit.uid || userRef?.id === visit.responsavel?.id) ?
            <><Button
              disableElevation
              variant={'contained'}
              startIcon={<EditIcon />}
              onClick={() => {
                navigate(`/visitas/editar/${idVisit}`)
                window.scrollTo(0, 0)
              }}
              style={{ textTransform: 'capitalize' }}>
              Editar
            </Button><Button
              disableElevation
              color='error'
              variant='contained'
              startIcon={<DeleteForeverIcon />}
              onClick={() => deleteVisit()}
              style={{ textTransform: 'capitalize' }}>
                Excluir
              </Button></>
            : null}
        </ThemeProvider>
      </div>
    </div>
  )
}

export default memo(InfoVisit);