import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
// import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react'
import {
  doc,
  deleteDoc,
  updateDoc,
  getDoc
} from "firebase/firestore";

import { FaMoneyBillTransfer } from "react-icons/fa6";
import PreviewIcon from '@mui/icons-material/Preview';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { BsSendArrowDown } from "react-icons/bs";
import { BsSendArrowUp } from "react-icons/bs";
import SendIcon from '@mui/icons-material/Send';

import { dataBase } from '../../firebase/database';
import moment from 'moment';
import NewTooltip from '../../components/Tooltip';
import { toast } from '../../components/Toast';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import useGoPage from '../../hooks/useGoPage';
import Logo from '../../images/logo192.png';
import axios from 'axios';

const EstimateQueue = ({ userRef, members, estimatesRef }) => {
  const goPage = useGoPage();
  const [estimates, setEstimates] = useState([])
  useEffect(() => {
    if(estimatesRef) {
      if(userRef?.time && userRef?.cargo === 'Vendedor(a)') {
        setEstimates(estimatesRef.filter((data) => data.representante === userRef?.nome))
      } else {
        setEstimates(estimatesRef)
      }
    }
  },[estimatesRef, userRef])

  console.log(estimatesRef)

  const estimativeSend = (dateRef) => {
    // Converte a data fornecida para um objeto Moment.js
    const { horario_materiais, createAt } = dateRef;
    const date = moment(horario_materiais?.toMillis());
    const dateCreate = moment(createAt?.toMillis());
    console.log("Data fornecida: ", date.format('YYYY-MM-DD HH:mm:ss'));

    // Horários alvo: 11:30, 15:30, 19:30
    const targetTimes = ['11:30', '15:30', '19:30'];

    // Percorre cada horário para verificar se está 4 horas antes
    for (let time of targetTimes) {
      // Cria um objeto Moment.js para a data do horário alvo (no mesmo dia do input)
      const [hour, minute] = time.split(':').map(Number);
      const targetTime = moment(date).set({
        hour: hour,
        minute: minute,
        second: 0,
        millisecond: 0
      });

      // Subtrai 4 horas do horário alvo
      const fourHoursBefore = moment(targetTime).subtract(4, 'hours');

      console.log("Horário alvo: ", targetTime.format('YYYY-MM-DD HH:mm:ss'));
      console.log("4 horas antes: ", fourHoursBefore.format('YYYY-MM-DD HH:mm:ss'));

      // Compara se a data fornecida está 4 horas antes do horário alvo
      if (date.isBefore(fourHoursBefore) && dateCreate.isBefore(targetTime)) {
        const targetTimeFormated = moment().set({
          hour: hour,
          minute: minute,
          second: 0,
          millisecond: 0
        });
        return targetTimeFormated.format('DD/MM/YYYY HH:mm');
      }
    }

    // Se nenhum horário coincidir, retorna o primeiro horário do dia seguinte
    const nextDay = moment().add(1, 'day').set({
      hour: 11,
      minute: 30,
      second: 0,
      millisecond: 0
    });

    return nextDay.format('DD/MM/YYYY HH:mm');
  };

  const checkPermission = useCallback((data) => {
    const { cargo, id } = userRef || {};

    if (!cargo || !id || !data) return false;

    // Verifica as condições de permissão
    const hasPermission = cargo === 'Administrador' ||
      id === data.consultora_uid ||
      id === data.representante_uid;

    return hasPermission;
  }, [userRef]);

  const cancelSend = async (data) => {
    console.log(data)

    const result = await Swal.fire({
      title: 'Atenção',
      html: `Você deseja <b>cancelar o envio</b> do Orçamento?`,
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#0eb05f",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    });

    if (result.isConfirmed) {
      try {
        const [idCustomer, index] = data.id.split("-");
        const customerDoc = await getDoc(doc(dataBase, 'Clientes', idCustomer))
        const customerData = customerDoc.exists() ? { ...customerDoc.data(), id: customerDoc.id } : null;

        let updatedSheets = [...customerData.planilha];
        let newSheet = updatedSheets[index];
        newSheet.sistemaRecomendado = false;
        updatedSheets[index] =
        {
          ...newSheet,
          historico: [
            ...newSheet.historico,
            {
              data: moment().format('DD/MM/YYYY HH:mm'),
              text: `O Envio do Orçamento para o cliente via WhatsApp foi cancelado pelo(a) ${userRef?.nome}.`
            }]
        };

        const updateData = {
          planilha: updatedSheets,
          historico: [
            ...customerData.historico,
            {
              data: moment().format('DD/MM/YYYY - HH:mm'),
              evento: 'Orçamento Cancelado ❌',
              text: `Cancelou o envio do Orçamento de <a href=https://corpbrasil.app/credito/${idCustomer}/${index} rel="noreferrer" target="_blank">${data.kit} kWp</a> com geração de <b>${data.geracao} kWh</b>.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome
            }
          ]
        };

        console.log(updateData)

        await deleteDoc(doc(dataBase, 'Fila', data.id));
        await updateDoc(doc(dataBase, 'Clientes', idCustomer), updateData);

        toast({ icon: 'success', text: 'Orçamento cancelado com sucesso.' });
      } catch (error) {
        console.log(error);
        toast({ icon: 'error', text: 'Ocorreu um erro ao cancelar o envio.', error: error })
      }
    }
  }

  const sendEstimate = async (data) => {

    const result = await Swal.fire({
      title: 'Atenção',
      html: `Você deseja <b>enviar</b> o Orçamento agora?`,
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#0eb05f",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    });

    if (result.isConfirmed) {
      try {
        const [idCustomer, index] = data.id.split("-");
        const customerDoc = await getDoc(doc(dataBase, 'Clientes', idCustomer))
        const customerData = customerDoc.exists() ? { ...customerDoc.data(), id: customerDoc.id } : null;

        let updatedSheets = [...customerData.planilha];
        let newSheet = { ...updatedSheets[index] }; // Clona o objeto da planilha
    
        // Adiciona o campo de orçamento recebido e atualiza o histórico
        newSheet.orcamento_recebido = new Date();
        newSheet.historico = [
          ...(newSheet.historico || []), // Garante que historico seja um array
          {
            data: moment().format('DD/MM/YYYY HH:mm'),
            text: `O Orçamento foi entregue ao cliente.`
          }
        ];
    
        // Substitui a planilha antiga com a nova atualizada
        updatedSheets[index] = newSheet;
    
        // Cria o novo objeto para ser atualizado no Firestore
        const newClienteData = {
          planilha: updatedSheets
        };

        console.log(newClienteData);
    
        // Atualiza os dados no Firestore

        await notifyClient(data);

        await deleteDoc(doc(dataBase, 'Fila', data.id));
        await updateDoc(doc(dataBase, 'Clientes', idCustomer), {
          ...newClienteData,
          historico: [
            ...customerData.historico,
            {
              data: moment().format('DD/MM/YYYY - HH:mm'),
              evento: 'Orçamento Enviado',
              text: `O Orçamento de <a href=https://corpbrasil.app/credito/${idCustomer}/${index} rel="noreferrer" target="_blank">${data.kit} kWp</a> com geração de <b>${data.geracao} kWh</b> foi entregue ao cliente.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome
            }
          ]
        });
        toast({ icon: 'success', text: 'Orçamento enviado com sucesso.' });

      } catch (error){
        console.log(error);
        toast({ icon: 'error', text: 'Ocorreu um erro ao cancelar o envio.', error: error })
      }
    }
  }

  const notifyClient = async(dataItem) => {
    const url = 'https://n8n.corpbrasil.cloud/webhook/c79ec60d-8757-4dc3-8d9c-d8ede689f347';
  
    // Dados que serão enviados na requisição
    const payload = {
      id: `${dataItem.id}`,
      nome: dataItem.nome,
      consultora: dataItem.consultora,
      cidade: dataItem.cidade,
      telefone: dataItem.telefone,
      chave: 'Orçamento',
      kit: dataItem.kit,
      orcamento: dataItem.orcamento,
      index: dataItem.index,
      representante: dataItem.representante,
      tipo: 'Orçamento',
      espera: '0',
      geracao: dataItem.geracao,
      cliente_id: dataItem?.cliente_id || ''
    };
  
    for (let attempt = 1; attempt <= 3; attempt++) {
      try {
        const response = await axios.post(url, payload, { timeout: 1000 });
        console.log(`Notificação enviada com sucesso para o documento ${dataItem.id}. Resposta:`, response.data);
        break; // Sai do loop se a requisição for bem-sucedida
      } catch (error) {
        if (attempt === 3) {
          console.error(`Erro ao enviar notificação para o documento ${dataItem.id} após 3 tentativas:`, error);
        } else {
          console.warn(`Tentativa ${attempt} falhou, tentando novamente...`);
        }
      }
    }
  }

  const linkCliente = (estimate, type) => {
    if (!estimate) return null;

    const { nome, id, orcamento_nome } = estimate;
    const [idCustomer, index] = id.split("-");
    const truncatedName = nome.length > 22 ? `${nome.substring(0, 22)}...` : nome;
    let linkRef = `/clientes/${idCustomer}`;

    if(type === 'planilha') {
      linkRef = `/credito/${idCustomer}/${index}`
    }

    if (!id) {
      return truncatedName;
    }

    return (
      <Link className="link_black clickable" to={linkRef} onClick={() => goPage(null, 'top')}>
        {type === 'planilha' ? orcamento_nome : truncatedName}
      </Link>
    );
  };

  const getPhotoMember = (idMember) => {
    const member = members.find((member) => member.id === idMember);
    if (member) {
      return member.photo?.url || Logo;
    } else {
      return Logo;
    }
  }

  const getMemberColor = (id) => {
    const member = members.find((member) => member.id === id);
    return member ? member.cor : 'transparent';
  }

  return (
    <div className="container-schedule">
      <div className="title-panel">
        <FaMoneyBillTransfer size='62px' />
        <h2>Orçamentos em Fila</h2>
      </div>
      <div className="box-schedule">
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <TableContainer className="table-visit" component={Paper} sx={{ margin: '2rem 0 1rem', maxWidth: '1400px' }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#333", color: "#fff", ".MuiTableCell-root": { color: "#fff" } }}>
                  <TableCell align='center'>Enviado na Fila</TableCell>
                  <TableCell align='center'>Previsão de Entrega</TableCell>
                  <TableCell align='center'>Cliente</TableCell>
                  <TableCell align='center'>Orçamento</TableCell>
                  <TableCell align='center'>Kit</TableCell>
                  <TableCell align='center'>Geração</TableCell>
                  <TableCell align='center'>Vendedor</TableCell>
                  <TableCell align='center'>Assistente</TableCell>
                  <TableCell align='center'>Status</TableCell>
                  <TableCell align='center'>Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {estimates.length > 0 ? estimates.map((data, index) => (
                  <TableRow key={index} sx={{ '.MuiTableCell-root': { padding: '0.3rem 0.6rem!important' } }}>
                    <TableCell>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>
                        <BsSendArrowDown size={'20px'} color='#ffa802' />{moment(data.createAt.toMillis()).format('DD/MM/YYYY HH:mm')}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>
                        <BsSendArrowUp size={'20px'} color='#06b906' />{estimativeSend(data)}
                      </Box>
                    </TableCell>
                    <TableCell align='center'>{linkCliente(data, 'cliente')}</TableCell>
                    <TableCell align='center'>{linkCliente(data, 'planilha')}</TableCell>
                    <TableCell align='center'>{data.kit} kWp</TableCell>
                    <TableCell align='center'>{data.geracao} kWp</TableCell>
                    <TableCell
                      sx={{ width: '140px' }}
                      align="center" >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.3rem', width: '100%', padding: '0.2rem', backgroundColor: getMemberColor(data.consultora_uid), borderRadius: '10px' }}>
                        <img src={getPhotoMember(data.consultora_uid)} alt={data.consultora_uid} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                        <p style={{ color: data.consultora_uid ? "#fff" : '#000' }}>{data.consultora}</p>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ width: '140px' }}
                      align="center" >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.3rem', width: '100%', padding: '0.2rem', backgroundColor: getMemberColor(data.representante_uid), borderRadius: '10px' }}>
                        <img src={getPhotoMember(data.representante_uid)} alt={data.representante_uid} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                        <p style={{ color: data.representante_uid ? "#fff" : '#000' }}>{data.representante || 'Nenhuma'}</p>
                      </Box>
                    </TableCell>
                    <TableCell align='center'>Aguardando</TableCell>
                    <TableCell align='center'>
                      <NewTooltip title='Visualizar Orçamento' placement='top'>
                        <IconButton color='primary' size='small' disabled={!checkPermission(data)} onClick={() => window.open(`https://www.corpbrasil.app/orcamento/${data.id}`, "_blank")}>
                          <PreviewIcon />
                        </IconButton>
                      </NewTooltip>
                      <NewTooltip title='Enviar Orçamento' placement='top'>
                        <IconButton color='success' size='small' disabled={!checkPermission(data)} onClick={() => sendEstimate(data)}>
                          <SendIcon />
                        </IconButton>
                      </NewTooltip>
                      <NewTooltip title='Cancelar Envio' placement='top'>
                        <IconButton size='small' disabled={!checkPermission(data)} color='error' onClick={() => cancelSend(data)}>
                          <CancelScheduleSendIcon />
                        </IconButton>
                      </NewTooltip>
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell align='center' colSpan={10}>Nenhum orçamento encontrado</TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  )
}

export default EstimateQueue