import { Box, Chip } from '@mui/material';
import React from 'react'

export const ItemKit = ({ item, estimate }) => {

    if (!item) return null;

    const { inversor_quantidade, modulo_quantidade  } = item;
    const structure = estimate?.telhado;

    return (
        <Box sx={{ display: 'flex', gap: '0.1rem', flexWrap: 'wrap', marginTop: '0.5rem', rowGap: '0.3rem' }}>
            <Chip label={`${inversor_quantidade} INVERSOR${inversor_quantidade > 1 ? 'ES' : ''}`} size='small' sx={{ fontWeight: 'bold',  fontSize: '12px', padding: '0', 'span': { padding: '0.3rem' } }} />
            <Chip label={`${modulo_quantidade} PLACAS - MÓDULOS`} size='small' sx={{ fontWeight: 'bold',  fontSize: '12px', padding: '0', 'span': { padding: '0.3rem' } }} />
            <Chip label={`ESTRUTURA ${structure} DE ALUMÍNIO`} size='small' sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px', padding: '0', 'span': { padding: '0.3rem' } }} />
        </Box>
    )
}