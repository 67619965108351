import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Menu from './Menu/Menu';
import { useLocation } from 'react-router-dom';
import { useCadastro } from '../contexts/CadastroContext';

const AppLayoutContainer = styled(Box)({
  display: 'flex',
  width: '100%',
});



const Layout = ({ userRef, estimates, children }) => {
  const location = useLocation();
  const previousUrl = useRef(null);
  const { atualizarCadastro } = useCadastro();

  useEffect(() => {
    // Função que será chamada quando a URL mudar
    const checkPreviousUrl = () => {
      if (previousUrl.current && previousUrl.current.includes("/visitas/criar/")) {
        atualizarCadastro(null);
        // Adicione aqui a lógica que você deseja executar
      }
      // Atualize a URL anterior com a URL atual
      previousUrl.current = location.pathname;
    };

    checkPreviousUrl(); // Chama a função sempre que a URL mudar
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]); 

  return (
    <AppLayoutContainer>
        <Menu userRef={userRef} />
        <Box component="main" sx={{ flexGrow: 1, 
          minHeight: '100vh',
          width: '100%',
          backgroundColor: "#F8F8F9" }}>
          {children}
        </Box>
    </AppLayoutContainer>
  );
};

export default Layout;