import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import { FaFileUpload } from "react-icons/fa";
import { Link } from "react-router-dom";

import CurrencyInput from "react-currency-input-field";
import NewTooltip from "../../../components/Tooltip";
import useViewDocBox from "../../../hooks/useViewDocBox";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteRounded from "@mui/icons-material/DeleteRounded";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import { MdOutlineSendToMobile } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import { GiTakeMyMoney } from "react-icons/gi";
import { TiDocumentText } from "react-icons/ti";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { IoDocumentSharp } from "react-icons/io5";
import { GrMoney } from "react-icons/gr";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { MdAccountBalanceWallet } from "react-icons/md";
import { GiPayMoney } from "react-icons/gi";
import { GiReceiveMoney } from "react-icons/gi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import { BsBuildingAdd } from "react-icons/bs";
import { BsBank2 } from "react-icons/bs";
// import { IoIosClose } from "react-icons/io";

import { theme } from "../../../data/theme";
import { VisuallyHiddenInput } from "../../../components/InputFile";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { dataBase } from "../../../firebase/database";
import { toast } from "../../../components/Toast";
import Swal from "sweetalert2";
import useUploadPhotos from "../../../hooks/useUploadPhotos";
import moment from "moment";
import Loading from "../../../components/Loading";
import { capitalizeString } from "../../../functions/capitalizeString";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AudioRecorder from "../../../components/AudioRecorder/Index";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { generateId } from "../../../functions/generateId";
import CustomAudioPlayerFixed from "../../../components/AudioPlayerFixed/Index";
import axios from "axios";
import removeNullProperties from "../../../functions/removeNullProperties";
import ButtonCB from "../../../components/Button";
import formatCurrency from "../../../functions/formatCurrency";
import LockContent from "../../../components/LockContent";

const optionsSimulation = [
  { nome: "Banco Do Brasil" },
  { nome: "Banco CorSolar" },
  { nome: "Banco BV" },
  { nome: "BTG Pactual" },
  { nome: "Caixa Econômica Federal" },
  { nome: "Cartão de Crédito" },
  { nome: "EOS" },
  { nome: "FGTS" },
  { nome: "Losango" },
  { nome: "Santander" },
  { nome: "Sicred" },
  { nome: "Sicoob" },
  { nome: "Sol Agora" },
  { nome: "Solfacil" },
  { nome: "SolPlace" },
];
const optionsSituation = [
  { nome: "Aprovado" },
  { nome: "Negado" },
  { nome: "Aguardando" },
];

const SendDoc = ({
  userRef,
  customer,
  view,
  close,
  indexProposal,
  type,
  simulationRef,
  setSimulationRef,
  sheetRef,
}) => {
  const { uploadImages } = useUploadPhotos();
  const [nameDocument, setNameDocument] = useState(null);
  const [name, setName] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [includeImprovements, setIncludeImprovements] = useState(false);
  const [values, setValues] = useState({
    entrada: '0',
    valor_financiamento: undefined
  });
  const notMobile = useMediaQuery('(min-width:600px)');
  const [cpfCNPJ, setCpfCNPJ] = useState('');
  const [situation, setSituation] = useState("");
  const [audio, setAudio] = useState("");
  // const [banks, setBanks] = useState([]);
  const [conditionSpecial, setConditionSpecial] = useState({
    visualizar: false,
    banco: "Santander",
    carencia: "",
  });
  const [fgts, setFgts] = useState({
    visualizar: false,
    banco: "FGTS",
    resgate_saldo: undefined,
    resgate_valor: undefined
  });
  const [fgtsResult, setFgtsResult] = useState(0);
  const [document, setDocument] = useState([]);
  const [documentCondition, setDocumentCondition] = useState([]);
  const [documentFgts, setDocumentFgts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [simulation, setSimulation] = useState(null);
  const [activeFinancing, setActiveFinancing] = useState(false);
  const [carencia, setCarencia] = useState("");
  const inputRef = useRef(null);
  const [installments, setInstallments] = useState([
    {
      quantidade: "",
      valor: "",
    },
  ]);
  const [installmentsCondition, setInstallmentsCondition] = useState([
    {
      quantidade: "",
      valor: "",
    },
  ]);

  const { viewDoc } = useViewDocBox();
  const DocsItems = styled(Box)(({ color }) => ({
    padding: "0.5rem 1rem",
    borderRadius: "5px",
    backgroundColor: color || "#fff",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  }));

  const closeBox = () => {
    close();
    setTimeout(() => {
      setNameDocument("");
      setDocument([]);
      setDocumentCondition([]);
      setDocumentFgts([]);
      setSimulationRef(null);
      setActiveFinancing(false);
      setAudio(null);
      setSimulation(null);
      setName("");
      setCpfCNPJ("");
      setSituation("");
      setInstallments([
        {
          quantidade: "",
          valor: "",
        },
      ]);
      setInstallmentsCondition([
        {
          quantidade: "",
          valor: "",
        },
      ]);
    }, 500);
  };

  useEffect(() => {
    if (simulationRef) {
      // Caso o cliente já tenha uma simulação vinculada ao documento
      const simulationsRef = customer.proposta[indexProposal].simulacoes;
      if (simulationsRef) {
        const newSimulationRef = simulationsRef.find(
          (data) => data.id === simulationRef.simulation_id
        );
        setSimulation(newSimulationRef);
        setNameDocument(newSimulationRef.banco);
        setName(newSimulationRef.nome);
        setCpfCNPJ(newSimulationRef.cpfCNPJ);
        setSituation(newSimulationRef.situacao);
        setActiveFinancing(true);
        setCarencia(newSimulationRef.carencia);
        setInstallments(newSimulationRef.parcelas);
        if (newSimulationRef.condicao_especial) {
          setConditionSpecial({
            visualizar: newSimulationRef.condicao_especial?.visualizar,
            banco: newSimulationRef.condicao_especial?.banco,
            carencia: newSimulationRef.condicao_especial?.carencia,
          });
          setInstallmentsCondition(newSimulationRef.condicao_especial?.parcelas || [
            {
              quantidade: "",
              valor: "",
            },
          ]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationRef, indexProposal]);

  // console.log(customer?.proposta[indexProposal]?.simulacoes)
  console.log(simulation)

  // const addBank = () => {
  //   const newBank = {
  //     nome: '',
  //     carencia: '',
  //     creatAt: new Date(),
  //     id: generateId('Banco'),
  //     parcelas: [
  //       {
  //         quantidade: "",
  //         valor: "",
  //       }
  //     ],
  //     documentos: []
  //   }

  //   setBanks([...banks, newBank]);
  // }

  const handleUpload = async () => {
    const storage = getStorage();
    if (!audio) {
      return;
    }

    try {
      // Upload do áudio
      let filesRef = {};

      if (audio) {
        const audioRef = ref(
          storage,
          `Clientes/${customer.id}/Mensagem-${Date.now()}.webm`
        );
        const audioSnapshot = await uploadBytes(audioRef, audio.complete);
        const audioURL = await getDownloadURL(audioSnapshot.ref);
        console.log("Áudio enviado com sucesso! URL:", audioURL);
        filesRef = {
          url: audioURL,
          ref: audioSnapshot.ref.fullPath,
        };
      }

      return filesRef;
    } catch (error) {
      console.error("Erro ao enviar arquivos:", error);
    }
  };

  const saveDoc = async () => {
    try {

      const result = await Swal.fire({
        title: "Atenção",
        text: `Você deseja enviar o Documento?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result.isConfirmed) {
        setLoading(true);

        // Função para realizar upload de documentos
        const uploadDocuments = async (document, folder) => {
          return document
            ? await uploadImages(document, folder, 0, customer.id, "Clientes")
            : null;
        };

        const resultGeradora = await uploadDocuments(document, nameDocument);
        const resultCondition = conditionSpecial.visualizar
          ? await uploadDocuments(documentCondition, conditionSpecial.banco)
          : null;
        const resultFgts = fgts.visualizar
          ? await uploadDocuments(documentFgts, "FGTS")
          : null;

        const file = resultGeradora ? resultGeradora.map(mapDocument) : [];
        const fileCondition = resultCondition ? resultCondition.map(mapDocument) : {};
        const fileFgts = resultFgts ? resultFgts.map(mapDocument) : {};

        const proposals = customer.proposta;
        const fatura = proposals[indexProposal].documentos;
        const proposalRef = proposals[indexProposal];

        // Inicializa o array de documentos se necessário
        if (!fatura[type]) {
          fatura[type] = [];
        }

        if (!proposalRef.id) {
          proposalRef.id = generateId(proposalRef.nome);
        }

        const payments = customer.pagamentos ? [...customer.pagamentos] : [];
        const simulations = proposalRef.simulacoes ? [...proposalRef.simulacoes] : [];
        const simulationId = generateId(nameDocument);

        const generateSimulationData = async () => ({
          nome: name,
          createAt: new Date(),
          cpfCNPJ: cpfCNPJ,
          banco: nameDocument,
          situacao: situation,
          id: simulationId,
          entrada: activeFinancing && situation === "Aprovado" ? values.entrada : null,
          valor_financiamento: activeFinancing && situation === "Aprovado" ? values.valor_financiamento : null,
          carencia: activeFinancing && situation === "Aprovado" ? carencia : null,
          parcelas: activeFinancing && situation === "Aprovado" ? installments : null,
          documentos: file,
          mensagemVoz: await handleUpload(),
          condicao_especial: conditionSpecial.visualizar
            ? { ...conditionSpecial, documentos: fileCondition, parcelas: installmentsCondition }
            : null,
          fgts: fgts.visualizar ? { ...fgts, documentos: fileFgts } : null,
        });

        simulations.push(removeNullProperties(await generateSimulationData()));
        proposalRef.simulacoes = simulations;

        // console.log(simulations);

        payments.push({
          // Envia dados para Pagamentos
          metodo: nameDocument,
          situacao: situation,
          orcamento_id: proposalRef.id,
          simulacao_id: simulationId,
        });

        fatura[type].push({ ...file[0], simulation_id: simulationId });

        const proposalData = {
          proposta: proposals,
          pagamentos: payments,
          historico: [
            ...customer.historico,
            {
              data: moment().format("DD/MM/YYYY - HH:mm"),
              evento: "Orçamento Alterado",
              text: `O documento <b>${nameDocument}</b> foi ADICIONADO na aba <b>${capitalizeString(type)}</b> do orçamento <b>${proposalRef.nome}</b>.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome,
            },
            {
              data: moment().format("DD/MM/YYYY - HH:mm"),
              evento: "Pagamento Alterado",
              text: `<b>[Adicionado]:</b> ${nameDocument} - ${situation}.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome,
            },
          ],
        };

        console.log(proposalData);

        await updateDoc(doc(dataBase, "Clientes", customer.id), proposalData);

        // Exiba uma mensagem de sucesso
        await Swal.fire({
          title: "Documentos",
          html: `O Documento foi enviado com sucesso.`,
          icon: "success",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        });
        closeBox();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast({
        icon: "error",
        text: `Erro ao salvar o documento. Erro: ${error}`,
      });
    }
  };

  const mapDocument = (object) => ({
    url: object.downloadURL,
    ref: object.fullPath,
  });

  const sendSimulation = async () => {
    console.log(nameDocument);

    try {
      const result = await Swal.fire({
        title: "Atenção",
        html: `Você deseja enviar a <b>Simulação</b> ao Cliente?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#05a937",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result.isConfirmed) {
        setLoading(true);

        const { proposta: proposals, planilha: sheets, historico } = customer;
        const proposalRef = proposals[indexProposal] || {};
        const simulations = proposalRef.simulacoes || [];

        // Encontrar a planilha correspondente
        const sheetIndex = sheets.findIndex(
          (data) =>
            data.orcamento_solicitado === sheetRef.orcamento_solicitado ||
            data?.orcamento_id === proposalRef?.id
        );
        const sheet = sheets[sheetIndex];

        // Gerar ID para a proposta, caso não tenha
        if (!proposalRef.id) {
          proposalRef.id = generateId(proposalRef.nome);
        }

        // Atualizar dados da simulação
        const simulationData = {
          ...simulation,
          nome: name,
          createAt: new Date(),
          cpfCNPJ,
          banco: nameDocument,
          situacao: situation,
          status: 'Enviado',
          simulacao_enviada: true,
          carencia: carencia || simulation.carencia,
          parcelas: installments || simulation.parcelas,
          entrada: activeFinancing && situation === "Aprovado" ? values.entrada : null,
          valor_financiamento: activeFinancing && situation === "Aprovado" ? values.valor_financiamento : null,
          condicao_especial: simulation.condicao_especial
            ? {
              ...simulation.condicao_especial,
              ...conditionSpecial,
              parcelas: [...installmentsCondition],
            }
            : null,
        };

        // Função para remover propriedades nulas
        const cleanSimulationData = removeNullProperties(simulationData);

        // Verifica se a simulação já existe no array de simulações com base no ID
        const simulationIndex = simulations.findIndex(sim => sim.id === simulation.id);

        if (simulationIndex > -1) {
          // Se já existe, atualiza a simulação existente
          simulations[simulationIndex] = cleanSimulationData;
        } else {
          // Caso não exista, adiciona uma nova simulação ao array
          simulations.push(cleanSimulationData);
        }

        proposalRef.simulacoes = simulations; // Envia dados para Proposta

        // Marcar a planilha como simulação enviada, caso encontrada
        if (sheet) {
          sheet.simulacao_enviada = true;
        }

        // console.log('PROPOSTAAAA', proposals);

        // Atualizar histórico do cliente
        const proposalData = {
          proposta: proposals,
          planilha: sheets,
          historico: [
            ...historico,
            {
              data: moment().format("DD/MM/YYYY - HH:mm"),
              evento: "Simulação Enviada",
              text: `A <b>simulação</b> do <b>${simulation.banco}</b> foi enviada para o cliente.`,
              usuario_id: userRef?.id,
              usuario_nome: userRef?.nome,
            },
          ],
        };

        // console.log(proposalData);

        const getEstimateQueue = await getDoc(
          doc(dataBase, "Fila", `${customer.id}-${sheetIndex}`)
        );
        const estimateQueueRef = getEstimateQueue.exists()
          ? getEstimateQueue.data()
          : null;

        await updateDoc(doc(dataBase, "Clientes", customer.id), proposalData);

        if (!estimateQueueRef) {
          await Swal.fire({
            title: "Simulação",
            html: `A Simulação foi enviada com sucesso ao cliente.`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#111",
          });
        } else {
          await Swal.fire({
            title: "Simulação",
            html: `O Cliente contém um orçamento na fila e por esse motivo, a simulação irá no aviso do orçamento.`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#111",
          });
        }

        await axios.post(
          "https://n8n.corpbrasil.cloud/webhook/a213344f-021f-4220-ae1d-f710cb332151",
          {
            nome: customer.nome,
            telefone: customer.telefone,
            id: `${customer.id}-${sheetIndex}`,
            chave: situation === "Aprovado" ? "Simulação" : "Simulação Negado",
            cidade: customer.cidade,
            analista: userRef?.nome,
            consultora: customer.consultora,
            id_agenda: customer.id,
            representante: customer?.assistente_nome || userRef?.nome,
            orcamento: sheet.orcamento,
            orcamento_status: estimateQueueRef ? "Em Fila" : "Enviado",
            status: situation === "Aprovado" ? "A" : "N",
          }
        );

        closeBox();

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast({
        icon: "error",
        text: `Erro ao salvar o documento. Erro: ${error}`,
      });
    }
  };

  const sendFile = async (e, type) => {
    const ref = e.target.files;
    const files = [];
    Array.from(ref, (image) =>
      files.push({ file: URL.createObjectURL(image), complete: image })
    );
    if (type === 'Condição') {
      if (documentCondition) {
        setDocumentCondition([...documentCondition, ...files]);
      } else {
        setDocumentCondition(files);
      }
    } else if (type === 'Fgts') {
      if (documentFgts) {
        setDocumentFgts([...documentFgts, ...files]);
      } else {
        setDocumentFgts(files);
      }
    } else {
      if (document) {
        setDocument([...document, ...files]);
      } else {
        setDocument(files);
      }
    }
  };

  const deleteFile = (index, file, type) => {
    if (index >= 0 && index < file.length) {
      const newPhotos = [...file];
      newPhotos.splice(index, 1);

      if (type === 'Condição') {
        setDocumentCondition(newPhotos);
      } else if (type === 'Fgts') {
        setDocumentFgts(newPhotos);
      } else {
        setDocument(newPhotos);
      }
    }
  };

  const hasEmptyOrUndefinedProperty = (objects) =>
    objects.some((obj) =>
      Object.values(obj).some(
        (value) => value === "" || value === undefined || value === null
      )
    );

  console.log(type, values)

  const checkButton = () => {
    if (type === "simulacoes") {

      const checkSimulationEstimate =
        document?.length === 0 ||
        !document ||
        !nameDocument ||
        situation?.length === 0 ||
        !values.entrada ||
        !values.valor_financiamento ||
        carencia?.length === 0 ||
        hasEmptyOrUndefinedProperty(installments) ||
        name?.length < 3 ||
        cpfCNPJ?.length < 14 ||
        !audio;

      const checkSimulation =
        (!simulationRef && document?.length === 0) ||
        nameDocument?.length === 0 ||
        situation?.length === 0 ||
        name?.length < 3 ||
        !values.entrada ||
        !values.valor_financiamento ||
        cpfCNPJ?.length < 14;

      const checkSimulationEstimateCondition =
        document?.length === 0 ||
        !document || !documentCondition || documentCondition?.length === 0 ||
        nameDocument?.length === 0 ||
        situation?.length === 0 ||
        carencia?.length === 0 ||
        !values.entrada ||
        !values.valor_financiamento ||
        name?.length < 3 ||
        cpfCNPJ?.length < 14 ||
        hasEmptyOrUndefinedProperty(installments) ||
        !audio ||
        hasEmptyOrUndefinedProperty(installmentsCondition) ||
        conditionSpecial.carencia?.length === 0;

      // const checkFgts = fgts.visualizar === true ? // Utilizar quando uns dos 2 campos do FGTS forem preenchidos
      //   (!documentFgts || documentFgts?.length === 0) ? false : true
      //   : true;

      // console.log(!documentFgts || documentFgts?.length === 0)
      // console.log(checkFgts);

      const checkMainSimulation =
        conditionSpecial.visualizar === true
          ? checkSimulationEstimateCondition
          : checkSimulationEstimate;

      if (situation === "Aprovado" && activeFinancing) {
        if (checkMainSimulation) return false;
      } else {
        if (checkSimulation) return false;
      }

      return (true);

    } else if (type === "enviar simulação") {
      if (name?.length < 3 || cpfCNPJ?.length < 14) return false;
    } else {
      if (document?.length === 0 || !document || nameDocument?.length === 0)
        return false;
    }

    return true;
  };

  const checkFinancing = useCallback((nameRef) => {
    if (nameRef !== "Cartão de Crédito" && nameRef !== "FGTS") {
      return true;
    }
    return false;
  }, []);

  const handleInstallmentsCondition = (value, index, field) => {
    const currentInstallments = installmentsCondition;
    const newInstallments = [...currentInstallments];
    const newInstallment = { ...newInstallments[index], [field]: value };
    newInstallments[index] = newInstallment;
    return setInstallmentsCondition(newInstallments);
  };

  const handleInstallments = (value, index, field) => {
    const currentInstallments = installments;
    const newInstallments = [...currentInstallments];
    const newInstallment = { ...newInstallments[index], [field]: value };
    newInstallments[index] = newInstallment;
    return setInstallments(newInstallments);
  };

  const addNewInstallments = () => {
    const newInstallment = {
      quantidade: "",
      valor: "",
    };

    setInstallments((currentInstallment) => [
      ...(currentInstallment ?? []),
      newInstallment,
    ]);
  };

  const removeInstallment = async (index) => {
    try {
      const result = await Swal.fire({
        title: "Atenção",
        html: `Você deseja excluir a <b>Parcela?</b>`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });
      if (result.isConfirmed) {
        const newInstallment = installments.filter((_, i) => i !== index);
        setInstallments(newInstallment);
      }
    } catch (error) {
      toast({
        icon: "error",
        text: "Ocorreu um erro ao excluir o pagamento.",
        error: error,
      });
    }
  };

  const sendAudio = (e) => {
    const audio = e.target.files[0];
    setAudio({ file: URL.createObjectURL(audio), complete: audio });
    console.log(audio);
  };

  const mask = (v) => {
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.substring(0, 14); // limita em 14 números
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  };

  const handleFgts = (value, name) => {

    const valueRef = name === 'resgate_valor'
      ? parseFloat(sheetRef?.orcamento?.valor || 0) - parseFloat(value || 0)
      : fgtsResult;

    setFgts(prevFgts => ({
      ...prevFgts,
      [name]: value
    }));

    setFgtsResult(valueRef);

  }

  const ListFGTS = () => {

    const listObj = {
      'Nome do Projeto': sheetRef?.nome,
      'Valor do Projeto': parseFloat(sheetRef?.orcamento?.valor_parcelado || '0'),
      'Saldo Disponível p/ Resgate': parseFloat(fgts?.resgate_saldo || '0'),
      'Valor do Resgate': parseFloat(fgts?.resgate_valor || '0'),
      'Saldo Pendente do Projeto': fgtsResult > 0 ? fgtsResult : 0,
      'Saldo de Reembolso ao Cliente': fgtsResult < 0 ? -fgtsResult : 0
    }

    const icons = {
      'Nome do Projeto': <IoDocumentSharp color="#adadad" />,
      'Valor do Projeto': <GrMoney color="#F0BE5F" />,
      'Saldo Disponível p/ Resgate': <MdAccountBalanceWallet color="#8A4D30" />,
      'Valor do Resgate': <FaMoneyCheckDollar color="#1976d2" />,
      'Saldo Pendente do Projeto': <GiPayMoney color="red" />,
      'Saldo de Reembolso ao Cliente': <GiReceiveMoney color="#388e3c" />
    };

    return (
      <Box sx={{ backgroundColor: "#f3f9ff", padding: '1rem', borderRadius: '10px', margin: '0.5rem 0', display: 'flex', justifyContent: 'center', boxShadow: '0px 10px 24px 0px #0000001A' }}>
        <Grid container spacing={2} sx={{ maxWidth: '740px' }}>
          {Object.entries(listObj).map(([key, value], index) => (
            <Grid key={index} item xs={12} sm={6} sx={{ 'b': { paddingLeft: '0.3rem' } }}>
              <p>{icons[key]} <b>{key}: </b>{typeof value === 'number' ? formatCurrency(value) : value}</p>
            </Grid>
          ))}
        </Grid>
      </Box>

    )
  }

  const handleValueChange = (option) => {
    console.log(option);
    if (selectedValue === option) {
      setSelectedValue('');
    } else {
      setSelectedValue(option);
    }
  };

  const handleImprovementsChange = () => {
    setIncludeImprovements(!includeImprovements); // Toggle improvements state
  };

  useEffect(() => {
    if (view) {
      setValues({ ...values, valor_financiamento: calculateTotal() })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, includeImprovements, view])

  const calculateTotal = () => {
    let total = 0;
    const cashPrice = sheetRef?.orcamento?.valor || 0;
    const installmentPrice = sheetRef?.orcamento?.valor_parcelado || 0;
    const improvementsPrice = sheetRef?.visita_tecnica?.rede_eletrica?.melhorias?.valor_total || 0;

    console.log(selectedValue);

    if (selectedValue === 'vista') {
      total += cashPrice;
    } else if (selectedValue === 'parcelado') {
      total += installmentPrice;
    } else if (selectedValue === '') {
      total = 0;
    }

    if (includeImprovements) {
      total += improvementsPrice;
    }

    return total;
  };

  // console.log(banks)

  return (
    <Dialog sx={{ overflow: "hidden" }} open={view} fullWidth maxWidth="md">
      <IconButton
        aria-label="close"
        onClick={closeBox}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle align="center">
        {capitalizeString(
          type === "simulacoes" || type === "enviar simulação"
            ? "Simulação"
            : type
        )}
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <Loading view={loading} />
        {simulationRef ? (
          <DialogContentText align="center">
            {simulation?.simulacao_enviada ? 'A Simulação já foi enviada para o cliente' : '⚠️ Revise as informações antes de enviar ⚠️'}
          </DialogContentText>
        ) : (
          <DialogContentText align="center">
            Preencha os dados abaixo para <b>salvar</b> a simulação.
          </DialogContentText>
        )}
        <Grid container spacing={1}>
          {type === "simulacoes" || type === "enviar simulação" ? (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nome Completo"
                  sx={{ backgroundColor: "#fff" }}
                  value={name || ""}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="CPF / CNPJ"
                  sx={{ backgroundColor: "#fff" }}
                  value={cpfCNPJ || ""}
                  required
                  onChange={(e) => setCpfCNPJ(mask(e.target.value))}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Autocomplete
                  value={nameDocument}
                  fullWidth
                  sx={{ alignItems: "center", backgroundColor: "#fff" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setNameDocument(newValue);
                    } else if (newValue && newValue.inputValue) {
                      setNameDocument(newValue.inputValue);
                    } else {
                      setNameDocument(newValue ? newValue.nome : "");
                    }
                  }}
                  selectOnFocus
                  noOptionsText="Forma de Pagamento não encontrado"
                  clearOnBlur
                  handleHomeEndKeys
                  // disabled={Boolean(simulationRef)}
                  disableClearable={true}
                  id="free-solo-with-text-demo"
                  options={optionsSimulation}
                  getOptionLabel={(option) => {
                    // Add "inputValue" to handle the newly created option
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.nome;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.nome}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Banco"
                      required
                      type="search"
                    />
                  )}
                  isOptionEqualToValue={(option, value) => {
                    // This function ensures that the selected option matches the value
                    return option.nome === value || option.nome === value.nome;
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <FormControl fullWidth required>
                  <InputLabel id="Situação">Situação</InputLabel>
                  <Select
                    labelId="Situação"
                    label="Situação"
                    value={situation}
                    disabled={Boolean(simulationRef)}
                    onChange={(e) => setSituation(e.target.value)}
                    required
                  >
                    {optionsSituation.map((option) => (
                      <MenuItem key={option.nome} value={option.nome}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {checkFinancing(nameDocument) && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <CurrencyInput
                      customInput={TextField}
                      label="Valor do Financiamento"
                      placeholder="R$ 00"
                      intlConfig={{
                        locale: "pt-BR",
                        currency: "BRL",
                      }}
                      onValueChange={(value) =>
                        setValues({ ...values, valor_financiamento: value })

                      }
                      value={values.valor_financiamento}
                      decimalsLimit={2}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <CurrencyInput
                      customInput={TextField}
                      label="Entrada"
                      placeholder="R$ 00"
                      intlConfig={{
                        locale: "pt-BR",
                        currency: "BRL",
                      }}
                      onValueChange={(value) =>
                        setValues({ ...values, entrada: value })

                      }
                      value={values.entrada}
                      decimalsLimit={2}
                      required
                      fullWidth
                    />
                  </Grid>
                </>
              )}
              {checkFinancing(nameDocument) && sheetRef ? (
                <Grid item xs={12}>
                  <Alert severity="info" sx={{ '.MuiAlert-message': { width: '100%' } }}>
                    <AlertTitle>Informações sobre o Projeto{" "}
                      <Typography sx={{ backgroundColor: "#c4edff", borderRadius: '5px', padding: '0.1rem 0.5rem' }} fontWeight={'800'} variant="b">{sheetRef?.nome}</Typography>
                    </AlertTitle>
                    <Grid container spacing={2} sx={{ '.MuiGrid-item': { paddingTop: '0!important' }, paddingTop: '1rem' }}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControlLabel
                          sx={{ margin: 0 }}
                          control={<Checkbox
                            checked={selectedValue === 'vista'}
                            color="success"
                            label="123"
                            name="vista"
                            value={sheetRef?.orcamento?.valor || 0}
                            onChange={() => handleValueChange('vista')}
                            icon={
                              <RadioButtonUncheckedIcon sx={{
                                fill: "#0FA958",
                                borderRadius: "50%",
                              }} />
                            }
                            checkedIcon={<CheckCircleIcon sx={{
                              fill: "#0FA958",
                              borderRadius: "50%",
                            }} />
                            }
                          />
                          }
                          label={
                            <Box>
                              <b>Valor à vista: </b>
                              <p>{formatCurrency(sheetRef?.orcamento?.valor || 0)}</p>
                            </Box>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControlLabel
                          sx={{ margin: 0 }}
                          control={<Checkbox checked={selectedValue === 'parcelado'}
                            color="success"
                            label="123"
                            name="parcelado"
                            value={sheetRef?.orcamento?.valor_parcelado || 0}
                            onChange={() => handleValueChange('parcelado')}
                            icon={
                              <RadioButtonUncheckedIcon sx={{
                                fill: "#0FA958",
                                borderRadius: "50%",
                              }} />
                            }
                            checkedIcon={<CheckCircleIcon sx={{
                              fill: "#0FA958",
                              borderRadius: "50%",
                            }} />
                            }
                          />
                          }
                          label={
                            <Box>
                              <b>Valor Parcelado: </b>
                              <p>{formatCurrency(sheetRef?.orcamento?.valor_parcelado || 0)}</p>
                            </Box>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControlLabel
                          sx={{ margin: 0 }}
                          control={<Checkbox checked={includeImprovements}
                            color="success"
                            onChange={handleImprovementsChange}
                            icon={
                              <RadioButtonUncheckedIcon sx={{
                                fill: "#0FA958",
                                backgroundColor: "#fff",
                                borderRadius: "50%",
                              }} />
                            }
                            checkedIcon={<CheckCircleIcon sx={{
                              fill: "#0FA958",
                              backgroundColor: "#fff",
                              borderRadius: "50%",
                            }} />
                            }
                          />
                          }
                          label={
                            <Box>
                              <b>Valor de Melhorias: </b>
                              <p>{formatCurrency(sheetRef?.visita_tecnica?.rede_eletrica?.melhorias?.valor_total || 0)}</p>
                            </Box>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Alert>
                </Grid>
              ) : null}
              {checkFinancing(nameDocument) ? (
                <Grid
                  container
                  spacing={1}
                  sx={{
                    backgroundColor: "#fff4e5",
                    borderLeft: "5px solid #663c00!important",
                    padding: "0.2rem 0.5rem 1rem 0.5rem",
                    margin: "1rem 0 0 0",
                    borderRadius: "5px",
                    position: 'relative',
                    overflow: 'hidden'
                  }}
                >
                  {!sheetRef &&
                    <LockContent text="Não é possível ativar o financiamento sem ter uma planilha vinculada ao orçamento" />
                  }
                  <Box sx={{
                    padding: '0.3rem 0.5rem',
                    backgroundColor: "#663c00",
                    borderRadius: '5px',
                    color: "#fff",
                    position: 'absolute',
                    left: '5px',
                    top: '8px',
                    fontSize: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.4rem'
                  }}>
                    <BsBank2 /><p>Principal</p>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Checkbox
                      checked={activeFinancing}
                      color="success"
                      disabled={Boolean(simulationRef)}
                      onChange={() => setActiveFinancing(!activeFinancing)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography variant="h6">Ativar Financiamento</Typography>
                  </Grid>
                  {situation === "Aprovado" && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          padding: "1rem",
                          justifyContent: "center",
                          alignItems: 'center',
                          gap: '0.3rem'
                        }}
                      >
                        <h2>{`${nameDocument}` || 'Banco Não Informado'}</h2>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <FormControl
                          fullWidth
                          required
                          disabled={!activeFinancing}
                        >
                          <InputLabel id="Carência">Carência</InputLabel>
                          <Select
                            labelId="Carência"
                            label="Carência"
                            value={carencia}
                            sx={{ backgroundColor: "#fff" }}
                            onChange={(e) => setCarencia(e.target.value)}
                            required
                          >
                            {[90, 120, 360, 720].map((option) => (
                              <MenuItem key={option} value={option}>
                                {option} Dias
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <TextField
                          fullWidth
                          label="Número de Parcelas"
                          inputProps={{ min: 0 }}
                          disabled={!activeFinancing}
                          sx={{ backgroundColor: "#fff" }}
                          value={installments[0].quantidade || ""}
                          onChange={(e) =>
                            handleInstallments(e.target.value, 0, "quantidade")
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <CurrencyInput
                          customInput={TextField}
                          label="Valor das Parcelas"
                          placeholder="R$ 00"
                          intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                          onValueChange={(value) =>
                            handleInstallments(value, 0, "valor")
                          }
                          decimalsLimit={2}
                          disabled={!activeFinancing}
                          sx={{ backgroundColor: "#fff" }}
                          value={installments[0].valor || ""}
                          required
                          fullWidth
                        />
                      </Grid>
                      {installments?.length > 1 &&
                        installments.map((data, index) =>
                          index === 0 ? null : (
                            <Grid item key={index} xs={12}>
                              <Grid
                                container
                                spacing={1}
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Número de Parcelas"
                                    inputProps={{ min: 0 }}
                                    sx={{ backgroundColor: "#fff" }}
                                    disabled={!activeFinancing}
                                    value={data.quantidade || ""}
                                    onChange={(e) =>
                                      handleInstallments(
                                        e.target.value,
                                        index,
                                        "quantidade"
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.3rem",
                                  }}
                                >
                                  <CurrencyInput
                                    customInput={TextField}
                                    label="Valor das Parcelas"
                                    placeholder="R$ 00"
                                    sx={{ backgroundColor: "#fff" }}
                                    intlConfig={{
                                      locale: "pt-BR",
                                      currency: "BRL",
                                    }}
                                    onValueChange={(value) =>
                                      handleInstallments(value, index, "valor")
                                    }
                                    decimalsLimit={2}
                                    disabled={!activeFinancing}
                                    value={data.valor || ""}
                                    required
                                    fullWidth
                                  />
                                  <NewTooltip
                                    title="Excluir Parcela"
                                    placement="top"
                                  >
                                    <IconButton
                                      disabled={!activeFinancing}
                                      onClick={() => removeInstallment(index)}
                                      color="error"
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </NewTooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}
                      <Grid item sm={12} sx={{ margin: "1rem 0" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.5rem",
                            flexWrap: "wrap",
                          }}
                        >
                          <ButtonCB
                            disabled={!activeFinancing}
                            size="small"
                            background="#025ca5"
                            onClick={addNewInstallments}
                            sx={{ textTransform: "capitalize" }}
                            startIcon={<AddCircleRoundedIcon />}
                          >
                            Adicionar Parcelas
                          </ButtonCB>
                        </Box>
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "0.3rem",
                    }}
                  >
                    <h3>Mensagem de Voz</h3>
                    {!simulationRef && (
                      <p>
                        Envie ou grave um áudio explicando sobre o financiamento
                        para o cliente.
                      </p>
                    )}
                    {simulation?.mensagemVoz?.url ? (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          gap: "0.5rem",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <CustomAudioPlayerFixed
                          url={simulation?.mensagemVoz?.url}
                        />
                        {/* <NewTooltip title='Excluir Áudio' placement='top'>
                                                    <IconButton onClick={() => deleteFile('Áudio')} color='error'>
                                                        <DeleteRounded />
                                                    </IconButton>
                                                </NewTooltip> */}
                      </Box>
                    ) : (
                      <div>
                        <AudioRecorder
                          setAudio={setAudio}
                          audio={audio}
                          disabled={!activeFinancing}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <Button
                            disableElevation
                            sx={{ textTransform: "capitalize" }}
                            component="label"
                            variant="contained"
                            onChange={(e) => sendAudio(e)}
                            disabled={!activeFinancing}
                            startIcon={<AudioFileIcon />}
                          >
                            Enviar Áudio
                            <VisuallyHiddenInput
                              ref={inputRef}
                              multiple={false}
                              type="file"
                              accept="audio/*"
                            />
                          </Button>
                        </Box>
                      </div>
                    )}
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                label="Nome"
                fullWidth
                variant="outlined"
                required
                value={nameDocument || ""}
                onChange={(e) => setNameDocument(e.target.value)}
              />
            </Grid>
          )}

          <Grid item xs={12} sx={{
            marginTop: "0.7rem",
            borderRadius: "5px",
            padding: "0.5rem 0 1rem 0",
            backgroundColor: "#fff4e5",
            borderLeft: "5px solid #663c00",
          }}>
            <Grid
              container
              xs={12}
              spacing={1}
            >
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="h6">Arquivo ({nameDocument || 'Financiamento'})</Typography>
              </Grid>
              {simulation?.documentos ? (
                simulation?.documentos.map((data, index) => (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <DocsItems>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                        <a
                          className="link_black"
                          href={data.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {
                            data?.ref?.split(
                              `Clientes/${customer.id}/`
                            )[1]
                          }
                        </a>
                      </Box>
                      {/* <NewTooltip id="Clickable" title="Excluir" placement="top">
                                        <IconButton onClick={() => deleteFile(index)} color="error">
                                            <DeleteRounded />
                                        </IconButton>
                                    </NewTooltip> */}
                    </DocsItems>
                  </Grid>
                ))
              ) : document.length > 0 ? (
                document?.map((file, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <DocsItems>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                        onClick={() => viewDoc(file)}
                      >
                        <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                        <Link to="" style={{ color: "#000" }}>
                          {file?.complete?.name}
                        </Link>
                      </Box>
                      <NewTooltip
                        id="Clickable"
                        title="Excluir"
                        placement="top"
                      >
                        <IconButton
                          onClick={() => deleteFile(index, document)}
                          color="error"
                        >
                          <DeleteRounded />
                        </IconButton>
                      </NewTooltip>
                    </DocsItems>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} justifyContent={"center"}>
                  <Typography textAlign={"center"} color={"#838383"}>
                    Nenhum arquivo encontrado
                  </Typography>
                </Grid>
              )}
              {!simulationRef?.url && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    variant="contained"
                    component="label"
                    color="primary"
                    onChange={(e) => sendFile(e)}
                    startIcon={<FaFileUpload />}
                    disableElevation
                  >
                    Adicionar Arquivo
                    <VisuallyHiddenInput
                      multiple={true}
                      type="file"
                      accept="image/png,image/jpeg, application/pdf"
                    />
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* {situation === 'Aprovado' && checkFinancing(nameDocument) && // Banco Adicional
            <Grid item xs={12}
              sx={{
                marginTop: "1rem",
                backgroundColor: "#fff4e5",
                borderRadius: "5px",
                padding: "0.3rem 0 1.5rem 0",
                borderLeft: "5px solid #663c00",
                position: 'relative',
                overflow: 'hidden'
              }}>
              {!activeFinancing &&
                <LockContent text="Clique em Ativar Financiamento para desbloquear" />
              }
              <NewTooltip title="Excluir Simulação" placement='top'>
                <Button disableElevation sx={{ position: 'absolute', right: '0', top: '0px', width: '30px', padding: '0', height: '30px', minWidth: '0', zIndex: '111' }} variant="contained" color="error">
                  <IoIosClose size='25px'/>
                </Button>
              </NewTooltip>
              <Box sx={{
                padding: '0.3rem 0.5rem',
                backgroundColor: "#663c00",
                borderRadius: '5px',
                color: "#fff",
                position: 'absolute',
                left: '5px',
                top: '8px',
                fontSize: '15px',
                display: 'flex',
                alignItems: 'center',
                gap: '0.4rem'
              }}>
                <BsBank2 /><p>Secundário</p>
              </Box>
              <Box
                sx={{
                  width: "100%", justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center',
                  position: "relative",
                  top: "2px",
                }}
                icon={false}
              >
                <Grid container>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', margin: '0.5rem 0 0.5rem 0' }}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      sx={{ maxWidth: '350px' }}
                      required
                    >
                      <Select
                        labelId="Banco"
                        value={conditionSpecial.banco}
                        sx={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                          textAlign: 'center'
                        }}
                        onChange={(e) =>
                          setConditionSpecial({
                            ...conditionSpecial,
                            banco: e.target.value,
                          })
                        }
                        required
                      >
                        {optionsSimulation.map((option) => (
                          <MenuItem key={option.nome} value={option.nome}>
                            {option.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormGroup
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={conditionSpecial.visualizar === true}
                        onChange={() =>
                          setConditionSpecial({
                            ...conditionSpecial,
                            visualizar: !conditionSpecial.visualizar,
                          })
                        }
                        name="condicoes_especiais_sim"
                        color="primary"
                      />
                    }
                    disabled={!activeFinancing || Boolean(simulationRef)}
                    label={<Typography variant="b">Mostrar essa simulação ao cliente</Typography>}
                  />
                </FormGroup>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  padding: '0.7rem 1rem 0.5rem 0.5rem',
                  boxSizing: 'border-box'
                }}
              >
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        fullWidth
                        required
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                      >
                        <InputLabel id="Carência">
                          Carência
                        </InputLabel>
                        <Select
                          labelId="Carência"
                          label="Carência"
                          value={conditionSpecial.carencia}
                          sx={{
                            backgroundColor: "#fff"
                          }}
                          onChange={(e) =>
                            setConditionSpecial({
                              ...conditionSpecial,
                              carencia: e.target.value,
                            })
                          }
                          required
                        >
                          {[90, 120, 360, 720].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option} Dias
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Número de Parcelas"
                        inputProps={{ min: 0 }}
                        sx={{
                          backgroundColor: "#fff"
                        }}
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                        value={installmentsCondition[0].quantidade || ""}
                        onChange={(e) =>
                          handleInstallmentsCondition(
                            e.target.value,
                            0,
                            "quantidade"
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CurrencyInput
                        customInput={TextField}
                        label="Valor das Parcelas"
                        placeholder="R$ 00"
                        intlConfig={{
                          locale: "pt-BR",
                          currency: "BRL",
                        }}
                        onValueChange={(value) =>
                          handleInstallmentsCondition(value, 0, "valor")
                        }
                        decimalsLimit={2}
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                        sx={{
                          backgroundColor: "#fff"
                        }}
                        value={installmentsCondition[0].valor || ""}
                        required
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={12} sx={{ margin: "1rem 0" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.5rem",
                            flexWrap: "wrap",
                          }}
                        >
                          <ButtonCB
                            disabled={!activeFinancing}
                            size="small"
                            background="#025ca5"
                            onClick={addNewInstallments}
                            sx={{ textTransform: "capitalize" }}
                            startIcon={<AddCircleRoundedIcon />}
                          >
                            Adicionar Parcelas
                          </ButtonCB>
                        </Box>
                      </Grid>
                </ThemeProvider>
              </Box>
              <Box sx={{ paddingTop: '0.7rem' }}>
                <Grid
                  container
                  spacing={1}
                  xs={12}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="h6">Arquivo Banco</Typography>
                  </Grid>
                  {simulationRef?.url ? (
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <DocsItems>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                          <a
                            className="link_black"
                            href={simulationRef.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {
                              simulationRef?.ref?.split(
                                `Clientes/${customer.id}/`
                              )[1]
                            }
                          </a>
                        </Box>
                      </DocsItems>
                    </Grid>
                  ) : documentCondition.length > 0 ? (
                    documentCondition?.map((file, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <DocsItems>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                            onClick={() => viewDoc(file)}
                          >
                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                            <Link to="" style={{ color: "#000" }}>
                              {file?.complete?.name}
                            </Link>
                          </Box>
                          <NewTooltip
                            id="Clickable"
                            title="Excluir"
                            placement="top"
                          >
                            <IconButton
                              onClick={() => deleteFile(index, documentCondition, 'Condição')}
                              color="error"
                            >
                              <DeleteRounded />
                            </IconButton>
                          </NewTooltip>
                        </DocsItems>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12} justifyContent={"center"}>
                      <Typography textAlign={"center"} color={"#838383"}>
                        Nenhum arquivo encontrado
                      </Typography>
                    </Grid>
                  )}
                  {!simulationRef?.url && (
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        variant="contained"
                        component="label"
                        disabled={conditionSpecial.visualizar === false || !activeFinancing}
                        onChange={(e) => sendFile(e, 'Condição')}
                        startIcon={<FaFileUpload />}
                        disableElevation
                      >
                        Adicionar Arquivo
                        <VisuallyHiddenInput
                          multiple={true}
                          type="file"
                          accept="image/png,image/jpeg, application/pdf"
                        />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          } */}

          {/* {checkFinancing(nameDocument) && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonCB onClick={addBank} startIcon={<BsBuildingAdd />}>Adicionar banco</ButtonCB>
            </Grid>
          )} */}

          {checkFinancing(nameDocument) && // Ativar FGTS
            (<Grid item xs={12}
              sx={{
                marginTop: "1rem",
                backgroundColor: "#e8f4ff",
                borderRadius: "5px",
                padding: "0.3rem 1rem 1rem 1rem",
                borderLeft: "5px solid #003F74",
                position: 'relative',
                overflow: 'hidden'
              }}>
              {!activeFinancing &&
                <LockContent text="Clique em Ativar Financiamento para desbloquear" />
              }
              <Box
                sx={{
                  width: "100%", justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center',
                  position: "relative", paddingTop: '0.6rem', boxSizing: 'border-box'
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={fgts.visualizar}
                    color="success"
                    onChange={() => handleFgts(!fgts.visualizar, 'visualizar')}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.7rem' }}>
                    <Typography variant="h6">Ativar Resgate FGTS</Typography>
                    {notMobile &&
                      <GiTakeMyMoney size='30px' />
                    }
                  </Box>
                </Box>
              </Box>
              <Box
                icon={false}
                sx={{
                  width: "100%",
                  padding: '1rem 0'
                }}
              >
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', justifyContent: 'center' }}>
                        <TiDocumentText size='25px' />
                        <Typography variant="h6">
                          Resumo
                        </Typography>
                      </Box>
                      <ListFGTS data={fgts} />
                    </Grid>
                    <Grid item xs={6}>
                      <CurrencyInput
                        customInput={TextField}
                        label="Valor Disponivel p/ Resgate"
                        placeholder="R$ 00"
                        intlConfig={{
                          locale: "pt-BR",
                          currency: "BRL",
                        }}
                        onValueChange={(value) =>
                          handleFgts(value, 'resgate_saldo')
                        }
                        decimalsLimit={2}
                        disabled={
                          !fgts.visualizar
                        }
                        InputLabelProps={{
                          sx: {
                            color: "#003F74", // Cor normal do texto da label
                          },
                        }}
                        sx={{
                          backgroundColor: "#fff",
                          ".MuiSvgIcon-root": {
                            color: "#003F74",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#003F74", // Cor da borda normal
                          },
                        }}
                        value={fgts.resgate_saldo}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CurrencyInput
                        customInput={TextField}
                        label="Valor do Resgate"
                        placeholder="R$ 00"
                        intlConfig={{
                          locale: "pt-BR",
                          currency: "BRL",
                        }}
                        onValueChange={(value) =>
                          handleFgts(value, 'resgate_valor')

                        }
                        decimalsLimit={2}
                        disabled={
                          !fgts.visualizar
                        }
                        InputLabelProps={{
                          sx: {
                            color: "#003F74", // Cor normal do texto da label
                          },
                        }}
                        sx={{
                          backgroundColor: "#fff",
                          ".MuiSvgIcon-root": {
                            color: "#003F74",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#003F74", // Cor da borda normal
                          },
                        }}
                        value={fgts.resgate_valor}
                        required
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </Box>
              <Box>
                <Grid
                  container
                  spacing={1}
                  xs={12}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="h6">Arquivo FGTS</Typography>
                  </Grid>
                  {simulationRef?.fgts?.documentos ? (
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <DocsItems>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                          <a
                            className="link_black"
                            href={simulationRef.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {
                              simulationRef?.ref?.split(
                                `Clientes/${customer.id}/`
                              )[1]
                            }
                          </a>
                        </Box>
                      </DocsItems>
                    </Grid>
                  ) : documentFgts.length > 0 ? (
                    documentFgts?.map((file, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <DocsItems>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                            onClick={() => viewDoc(file)}
                          >
                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                            <Link to="" style={{ color: "#000" }}>
                              {file?.complete?.name}
                            </Link>
                          </Box>
                          <NewTooltip
                            id="Clickable"
                            title="Excluir"
                            placement="top"
                          >
                            <IconButton
                              onClick={() => deleteFile(index, documentFgts, 'Fgts')}
                              color="error"
                            >
                              <DeleteRounded />
                            </IconButton>
                          </NewTooltip>
                        </DocsItems>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12} justifyContent={"center"}>
                      <Typography textAlign={"center"} color={"#838383"}>
                        Nenhum arquivo encontrado
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      variant="contained"
                      component="label"
                      color="info"
                      disabled={fgts.visualizar === false || !activeFinancing}
                      onChange={(e) => sendFile(e, 'Fgts')}
                      startIcon={<FaFileUpload />}
                      disableElevation
                    >
                      Adicionar Arquivo
                      <VisuallyHiddenInput
                        multiple={true}
                        type="file"
                        accept="image/png,image/jpeg, application/pdf"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>)
          }

          {situation === 'Aprovado' && checkFinancing(nameDocument) && // Condição Especial
            <Grid item xs={12}
              sx={{
                marginTop: "1rem",
                backgroundColor: "#edf7ed",
                borderRadius: "5px",
                padding: "0.3rem 0 1rem 0",
                borderLeft: "5px solid #2e7d32",
                position: 'relative',
                overflow: 'hidden'
              }}>
              {!activeFinancing &&
                <LockContent text="Clique em Ativar Financiamento para desbloquear" />
              }
              {/* Condição Especial */}
              <Alert
                sx={{
                  ".MuiAlert-message": { width: "100%", justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' },
                  position: "relative",
                  top: "2px",
                }}
                icon={false}
                severity="success"
              >
                <AlertTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.7rem' }}>
                  <BiSolidOffer size='30px' />
                  <h3>Condição Especial</h3>
                </AlertTitle>
                <p>Atenção: O Cliente irá ter uma condição especial de pagamento?</p>
                <FormGroup
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={conditionSpecial.visualizar === true}
                        onChange={() =>
                          setConditionSpecial({
                            ...conditionSpecial,
                            visualizar: true,
                          })
                        }
                        name="condicoes_especiais_sim"
                        color="primary"
                      />
                    }
                    disabled={!activeFinancing || Boolean(simulationRef)}
                    label={<b>Sim</b>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={conditionSpecial.visualizar === false}
                        onChange={() =>
                          setConditionSpecial({
                            ...conditionSpecial,
                            visualizar: false,
                          })
                        }
                        name="condicoes_especiais_nao"
                        color="primary"
                      />
                    }
                    disabled={!activeFinancing || Boolean(simulationRef)}
                    label={<b>Não</b>}
                  />
                </FormGroup>
              </Alert>
              <Alert
                icon={false}
                severity="success"
                sx={{
                  ".MuiAlert-message": { width: "100%" }
                }}
              >
                <ThemeProvider theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                      <FormControl
                        fullWidth
                        required
                        color="success"
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                      >
                        <InputLabel id="Banco" sx={{ color: "green" }}>
                          Banco
                        </InputLabel>
                        <Select
                          labelId="Banco"
                          label="Banco"
                          value={conditionSpecial.banco}
                          sx={{
                            backgroundColor: "#fff",
                            ".MuiSvgIcon-root": {
                              color: "green",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "green", // Cor da borda normal
                            },
                          }}
                          onChange={(e) =>
                            setConditionSpecial({
                              ...conditionSpecial,
                              banco: e.target.value,
                            })
                          }
                          //   IconComponent={LockIcon}
                          required
                        >
                          {optionsSimulation.map((option) => (
                            <MenuItem key={option.nome} value={option.nome}>
                              {option.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControl
                        fullWidth
                        required
                        color="success"
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                      >
                        <InputLabel id="Carência" sx={{ color: "green" }}>
                          Carência
                        </InputLabel>
                        <Select
                          labelId="Carência"
                          label="Carência"
                          value={conditionSpecial.carencia}
                          sx={{
                            backgroundColor: "#fff",
                            ".MuiSvgIcon-root": {
                              color: "green",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "green", // Cor da borda normal
                            },
                          }}
                          onChange={(e) =>
                            setConditionSpecial({
                              ...conditionSpecial,
                              carencia: e.target.value,
                            })
                          }
                          required
                        >
                          {[90, 120, 360, 720].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option} Dias
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        color="success"
                        label="Número de Parcelas"
                        inputProps={{ min: 0 }}
                        InputLabelProps={{
                          sx: {
                            color: "green", // Cor normal do texto da label
                          },
                        }}
                        sx={{
                          backgroundColor: "#fff",
                          ".MuiSvgIcon-root": {
                            color: "green",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "green", // Cor da borda normal
                          },
                        }}
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                        value={installmentsCondition[0].quantidade || ""}
                        onChange={(e) =>
                          handleInstallmentsCondition(
                            e.target.value,
                            0,
                            "quantidade"
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <CurrencyInput
                        customInput={TextField}
                        label="Valor das Parcelas"
                        color="success"
                        placeholder="R$ 00"
                        intlConfig={{
                          locale: "pt-BR",
                          currency: "BRL",
                        }}
                        onValueChange={(value) =>
                          handleInstallmentsCondition(value, 0, "valor")
                        }
                        decimalsLimit={2}
                        disabled={
                          !activeFinancing || !conditionSpecial.visualizar
                        }
                        InputLabelProps={{
                          sx: {
                            color: "green", // Cor normal do texto da label
                          },
                        }}
                        sx={{
                          backgroundColor: "#fff",
                          ".MuiSvgIcon-root": {
                            color: "green",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "green", // Cor da borda normal
                          },
                        }}
                        value={installmentsCondition[0].valor || ""}
                        required
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </Alert>
              <Box>
                <Grid
                  container
                  spacing={1}
                  xs={12}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="h6">Arquivo Condição</Typography>
                  </Grid>
                  {simulationRef?.url ? (
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <DocsItems>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                          <a
                            className="link_black"
                            href={simulationRef.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {
                              simulationRef?.ref?.split(
                                `Clientes/${customer.id}/`
                              )[1]
                            }
                          </a>
                        </Box>
                        {/* <NewTooltip id="Clickable" title="Excluir" placement="top">
                                        <IconButton onClick={() => deleteFile(index)} color="error">
                                            <DeleteRounded />
                                        </IconButton>
                                    </NewTooltip> */}
                      </DocsItems>
                    </Grid>
                  ) : documentCondition.length > 0 ? (
                    documentCondition?.map((file, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <DocsItems>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                            onClick={() => viewDoc(file)}
                          >
                            <InsertDriveFileOutlinedIcon sx={{ fill: "#484848" }} />
                            <Link to="" style={{ color: "#000" }}>
                              {file?.complete?.name}
                            </Link>
                          </Box>
                          <NewTooltip
                            id="Clickable"
                            title="Excluir"
                            placement="top"
                          >
                            <IconButton
                              onClick={() => deleteFile(index, documentCondition, 'Condição')}
                              color="error"
                            >
                              <DeleteRounded />
                            </IconButton>
                          </NewTooltip>
                        </DocsItems>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12} justifyContent={"center"}>
                      <Typography textAlign={"center"} color={"#838383"}>
                        Nenhum arquivo encontrado
                      </Typography>
                    </Grid>
                  )}
                  {!simulationRef?.url && (
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        variant="contained"
                        component="label"
                        color="info"
                        disabled={conditionSpecial.visualizar === false || !activeFinancing}
                        onChange={(e) => sendFile(e, 'Condição')}
                        startIcon={<FaFileUpload />}
                        disableElevation
                      >
                        Adicionar Arquivo
                        <VisuallyHiddenInput
                          multiple={true}
                          type="file"
                          accept="image/png,image/jpeg, application/pdf"
                        />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          }

        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}
      >
        {simulationRef && simulation?.status !== 'Enviado' ? (
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            disableElevation
            onClick={sendSimulation}
            startIcon={<MdOutlineSendToMobile />}
            disabled={!checkButton()}
            color="info"
          >
            Enviar
          </Button>
        ) :
          <ThemeProvider theme={theme}>
            <Button
              sx={{ textTransform: "capitalize" }}
              variant="contained"
              disableElevation
              onClick={saveDoc}
              disabled={!checkButton() || simulationRef?.banco}
              color="success"
            >
              Salvar
            </Button>
          </ThemeProvider>
        }
        <Button
          onClick={closeBox}
          sx={{ textTransform: "capitalize" }}
          disableElevation
          variant="contained"
          color="primary"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendDoc;
