import React, { useEffect, useRef, useState } from 'react'
import { Box, Checkbox, Collapse, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, styled, TextField, ThemeProvider, Typography, useMediaQuery } from '@mui/material'
import styles from '../styles.module.scss';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { TbSunElectricity } from "react-icons/tb";
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import PixRoundedIcon from '@mui/icons-material/PixRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import Placa from '../../../images/system/Placa.png';
import Cabo from '../../../images/system/Cabo.png';
import Mais_Vendido from '../../../images/system/MaisVendido.png';

import { theme } from '../../../data/theme';

import ButtonCB from '../../../components/Button';
import formatCurrency from '../../../functions/formatCurrency';
import CurrencyInput from 'react-currency-input-field';
import NewTooltip from '../../../components/Tooltip';
import InversorImage from './InversorImage';
import ButtonSection from './ButtonSection';
import Simulation from './Simulation';
import { ItemKit } from './ItemKit';
import Financing from './Finacing';

const calculateInstallments = (total, installments) => {
  const installmentList = [];
  for (let i = installments; i >= 1; i--) {
    installmentList.push({ id: i, label: `${i}x ${formatCurrency((total / i))}`, value: (total / i) });
  }
  return installmentList;
};


const Payment = ({ open, close, estimate, sendContract, type, paymentValueTotal, system, customer, typePayment, assistant, setRefresh, simulation, openVisit,
  proposal, installment, setInstallment, openPaymentContract }) => {
  const isContract = typePayment === 'Contrato' && type === 'Contrato';
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [valueEntrance, setValueEntrance] = useState('');
  const step = 'Payments';
  const [valueTotal, setValueTotal] = useState(0);
  // const [checkButton, setCheckButton] = useState(false);
  const [info, setInfo] = useState({
    tipo: '',
    parcelas: '',
    valor: '',
    info: '',
    entrada: ''
  });
  const [view, setView] = useState({
    simulation: false,
    credit: false,
    pix: false
  });

  const maxInstallments = 18; // Número máximo de parcelas
  const splitIndex = 9;
  const buttonRef = useRef(null);
  const buttonPaymentRef = useRef(null);

  const handleClick = (type) => {
    if (type === 'simulação') {
      if (buttonRef.current) {
        buttonRef.current.click(); // Aciona o clique no ButtonCB
      }
    } else {
      if (buttonPaymentRef.current) {
        buttonPaymentRef.current.click(); // Aciona o clique no botão de pagamento
      }
    };
  }

  console.log(typePayment, type);

  const paymentOptions = calculateInstallments(paymentValueTotal.parcelado - (valueEntrance || 0), maxInstallments);
  const firstInstallments = paymentOptions.slice(0, splitIndex); // Primeiras 9 parcelas
  const remainingInstallments = paymentOptions.slice(splitIndex); // Restantes

  const NewDivider = styled(Divider)(({ height, color, margin }) => ({
    width: '100%',
    height: height || '4px',
    border: 'none',
    margin: margin || 0,
    background: `linear-gradient(90deg, rgba(243, 146, 0, 0) 0%, ${color || '#F39200'} 49.5%, rgba(243, 146, 0, 0) 100%)`,
  }));

  const handleChange = (event, typeRef, label) => {

    const { name, value } = event.target;
    let valueCalc = value;
    let labelRef = label;
    let valueEntranceRef = valueEntrance;

    if (typeRef === 'À Vista') {
      if (label === '80% de entrada + 20% na instalação') {
        valueCalc = Number(paymentValueTotal.vista) + ((Number(paymentValueTotal.vista) / 100) * 5);
        valueEntranceRef = Number(paymentValueTotal.vista) - ((Number(paymentValueTotal.vista) / 100) * 20);
      } else if (label === '70% de entrada + 2x boletos') {
        valueCalc = Number(paymentValueTotal.vista) + ((Number(paymentValueTotal.vista) / 100) * 10);
        valueEntranceRef = Number(paymentValueTotal.vista) - ((Number(paymentValueTotal.vista) / 100) * 30);
      } else {
        valueCalc = paymentValueTotal.vista;
        valueEntranceRef = paymentValueTotal.vista;
      }
    }

    // else 
    // if(type === 'Entrada') {
    //   const paymentOptionsRef = paymentOptions.reverse()[Number(info.parcelas - 1)];
    //   console.log(paymentOptionsRef)
    //   labelRef = paymentOptionsRef.label;
    //   valueEntranceRef = value;
    //   setValueEntrance(value);
    // }

    setValueTotal(typeRef === 'À Vista' ? valueCalc : paymentValueTotal.vista);
    setInfo({
      tipo: typeRef,
      parcelas: name,
      valor: Number(valueCalc).toFixed(2),
      info: labelRef,
      entrada: Number(valueEntranceRef).toFixed(2)
    });
    // setCheckButton(true);
  }

  useEffect(() => {
    if (open) {
      setValueTotal(paymentValueTotal.vista);
    }
  }, [open, paymentValueTotal])

  // useEffect(() => {
  //   if (info) {
  //     if (view.credit) {
  //       setCheckButton(info.tipo === "Cartão de Crédito");
  //     } else {
  //       setCheckButton(info.tipo === "À Vista");
  //     }
  //   }
  // }, [view, info])

  useEffect(() => {
    if (info) {
      setInfo({
        tipo: '',
        parcelas: '',
        valor: '',
        info: '',
        entrada: ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  useEffect(() => {
    if (valueEntrance && info.tipo === 'Cartão de Crédito') {
      const paymentOptionsRef = paymentOptions.reverse()[Number(info.parcelas - 1)]
      setInfo({ ...info, valor: paymentOptionsRef.value, info: paymentOptionsRef.label })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueEntrance])

  const onClose = () => {
    close();
    setTimeout(() => {
      setValueEntrance('');
      setView({
        simulation: false,
        credit: false,
        pix: false
      });
      setInfo({
        tipo: '',
        parcelas: '',
        valor: '',
        info: '',
        entrada: ''
      });
    }, 500);
  }

  const calcValue = (data) => {
    if (data.info === "70% de entrada + 2x boletos") {
      return {
        restante_1: ((data.valor_total - data.entrada) / 2).toFixed(2),
        restante_2: ((data.valor_total - data.entrada) / 2).toFixed(2),
      }
    } else if (data.info === "80% de entrada + 20% na instalação") {
      return {
        restante_1: (data.valor_total - data.entrada).toFixed(2),
        restante_2: 0
      }
    }
    return {
      restante_1: 0,
      restante_2: 0
    }
  }

  const sendPayment = (e) => {
    e.preventDefault();

    if (!info.info) {
      // setCheckButton(false);
      return null;
    }

    const contractRef = {
      ...info,
      valor_total: info.tipo === 'À Vista' ? Number(valueTotal).toFixed(2) : Number(paymentValueTotal.parcelado).toFixed(2),
      entrada: info.tipo === 'À Vista' ? info.entrada : Number(valueEntrance).toFixed(2)
    };

    const resultCalc = calcValue(contractRef)

    // console.log({
    //   ...resultCalc,
    //   ...contractRef
    // });

    sendContract({
      ...resultCalc,
      ...contractRef
    }, type === 'Visita' ? 'visit' : 'contract');

    setTimeout(() => {
      setView({
        simulation: false,
        credit: false,
        pix: false
      });
      setInfo({
        tipo: '',
        parcelas: '',
        valor: '',
        info: '',
        entrada: ''
      });
    }, 500);
  }

  // const sendSimulation = () => {
  //   onCloseInSimulation(); // Ajustar - 05/09
  //   setTimeout(() => {
  //     setView({
  //       simulation: false,
  //       credit: false,
  //       pix: false
  //     });
  //   }, 500);
  // }

  console.log(typePayment, type)

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CancelIcon sx={{ fill: '#fff', fontSize: '30px' }} />
      </IconButton>
      <DialogTitle sx={{ backgroundColor: "#000", color: "#fff" }}>
        Formas de pagamento
      </DialogTitle>
      <DialogContent className={styles.dialog_content} sx={{ justifyContent: 'flex-start', gap: '0.7rem' }}>
        {type === 'Contrato' ?
          <DialogContentText marginTop={"1rem"}>Para <b> solicitar seu contrato</b>, é necessário escolher a forma de pagamento, que será efetuada após a assinatura do documento.</DialogContentText> : null
        }
        <Grid container spacing={1} marginTop={'1rem'}>
          <Grid item xs={4} className={styles.flex_center}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%', position: 'relative' }}>
              <img style={{ width: '90px', zIndex: '11', position: 'absolute', left: 'calc(50% + 0px)', top: '-10px' }} src={Mais_Vendido} alt='Mais Vendido' />
              <div className={styles.irradiation_img} style={{ position: 'relative', display: 'flex', alignItems: 'center', minHeight: '180px', width: 'auto', maxHeight: 'none' }}>
                <img src={Placa} alt='Placa' style={{ maxWidth: '100px' }} />
              </div>
              <Box sx={{ position: 'absolute', bottom: 0, left: 'calc(50% - 7px)', maxWidth: '110px' }}>
                <InversorImage inversorName={system?.orcamento?.inversor || 'Padrão'} />
              </Box>
              <Box sx={{ position: 'absolute', bottom: 0, right: 'calc(50% + 10px)', maxWidth: '45px' }}>
                <img src={Cabo} alt='Cabo' style={{ maxWidth: '45px' }} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Box sx={{ padding: '0rem 0.3rem 1rem 1rem', fontSize: '14px' }}>
              <p>Kit fotovoltaico completo instalado</p>
              <ItemKit item={system?.orcamento} estimate={estimate} />
            </Box>
            <Box className={styles.box_payment_value}>
              <p>Geração média mensal</p>
              <Box className={styles.flex_row}>
                <TbSunElectricity size='1.6rem' />
                <h2>{system?.geracao} kWh</h2>
              </Box>
            </Box>
          </Grid>
          <NewDivider height="2px" color='#C2C2C2' margin="1rem 0" />
        </Grid>

        {/* {simulation ?
          <ButtonCB fullWidth startIcon={<AccountBalanceIcon />} colorText="#209CFF" background="transparent" variant='outlined'>
            Simulação Bancária Solicitada
          </ButtonCB> :
          <ButtonCB fullWidth background="#209CFF" onClick={sendSimulation}>
            Simular Financiamento
          </ButtonCB>
        } */}

        {typePayment === 'Boleto' || isContract ?
          !estimate?.simulacao ?
            <Box width={'100%'}>
              <ButtonSection icon={<AccountBalanceIcon />} viewArrow={isContract} text="Boleto Bancário" radius='10px 10px 0 0' open={view.simulation} onClick={() => setView({ ...view, simulation: !view.simulation, credit: false, pix: false })}>
              </ButtonSection>
              <Collapse
                sx={{
                  width: '100%',
                  backgroundColor: "#F2F2F2",
                  padding: '0.5rem 0.2rem',
                  boxSizing: 'border-box',
                  borderRadius: '0px 0px 10px 10px'
                }}
                in={isContract ? view.simulation : true}
                timeout="auto"
                unmountOnExit>
                {system?.simulacao_enviada ?
                  <Financing simulation={simulation} assistant={assistant} system={system} customer={customer}
                    openVisit={openVisit} setRefresh={setRefresh} proposal={proposal} installment={installment}
                    setInstallment={setInstallment} openPaymentContract={openPaymentContract} type={type} step={step} /> :
                  <Simulation ref={isContract ? null : buttonRef} customer={customer} system={system} assistant={assistant} setRefresh={setRefresh} type="Payments" />
                }

              </Collapse>
            </Box> :
            <ButtonCB fullWidth startIcon={<AccountBalanceIcon />} isClickable={false} colorText='#000' background="transparent" variant='outlined'>Análise de Crédito Solicitado</ButtonCB> : null
        }

        {typePayment === 'Cartão' || isContract ?
          <Box width={'100%'}>
            <ButtonSection icon={<CreditCardRoundedIcon />} viewArrow={isContract} text="Cartão de Crédito" radius='10px 10px 0 0' open={view.credit} onClick={() => setView({ ...view, credit: !view.credit, simulation: false, pix: false })}>
            </ButtonSection>
            <Collapse
              sx={{
                width: '100%',
                backgroundColor: "#F2F2F2",
                padding: '1.3rem 0.2rem 1rem',
                boxSizing: 'border-box',
                borderRadius: '0px 0px 10px 10px'
              }}
              in={isContract ? view.credit : true}
              timeout="auto"
              unmountOnExit>
              <Grid container spacing={1}>
                <Grid item xs={12} margin={'0 1rem'}>
                  <ThemeProvider theme={theme}>
                    <CurrencyInput
                      customInput={TextField}
                      label="Entrada"
                      color='info'
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input': {
                            backgroundColor: '#fff', // Aplica o fundo branco ao input
                          },
                        },
                      }}
                      placeholder="R$ 00"
                      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                      onValueChange={(formatted) => setValueEntrance(formatted)}
                      decimalsLimit={2}
                      helperText={`Limite de Entrada ${formatCurrency(paymentValueTotal.parcelado)}`}
                      value={valueEntrance}
                      fullWidth
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup sx={{ display: 'flex', gap: '0.5rem', padding: '0 1rem 0 0.5rem' }}>
                    {firstInstallments.map((data, index) => (
                      <FormControlLabel
                        key={index}
                        sx={{ margin: 0, display: 'flex', justifyContent: 'center', gap: '0.5rem' }}
                        control={
                          <Checkbox
                            checked={info.parcelas === String(data.id) && info.tipo === 'Cartão de Crédito'}
                            onChange={(e) => handleChange(e, 'Cartão de Crédito', data.label)}
                            name={data.id}
                            value={data.value}
                            icon={<RadioButtonUncheckedIcon sx={{ backgroundColor: "#fff", borderRadius: '50%' }} />}
                            checkedIcon={<RadioButtonCheckedIcon sx={{ fill: "#747474", backgroundColor: "#fff", borderRadius: '50%' }} />} />
                        }
                        label={
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              color: "#4A4A4A",
                              width: '100%',
                              backgroundColor: "#fff",
                              padding: '0.5rem 1rem',
                              borderRadius: '5px',
                              display: 'flex',
                              justifyContent: 'flex-start'
                            }}>
                            <Box sx={{ display: 'flex', gap: '0.2rem', "p": { paddingRight: '0.3rem' }, "span": { fontWeight: '400' } }}>
                              <p>{data.id}x</p>
                              <span>de</span>
                              <b>{formatCurrency(data.value)}</b>
                              {/* <span>sem juros</span> */}
                            </Box>
                          </Typography>
                        }
                      />
                    ))}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup sx={{ display: 'flex', gap: '0.5rem', padding: '0 1rem 0 0.5rem' }}>
                    {remainingInstallments.map((data, index) => (
                      <FormControlLabel
                        key={index}
                        sx={{ margin: 0, display: 'flex', justifyContent: 'center', gap: '0.5rem' }}
                        control={
                          <Checkbox
                            checked={info.parcelas === String(data.id) && info.tipo === 'Cartão de Crédito'}
                            onChange={(e) => handleChange(e, 'Cartão de Crédito', data.label)}
                            name={data.id}
                            value={data.value}
                            icon={<RadioButtonUncheckedIcon sx={{ backgroundColor: "#fff", borderRadius: '50%' }} />}
                            checkedIcon={<RadioButtonCheckedIcon sx={{ fill: "#747474", backgroundColor: "#fff", borderRadius: '50%' }} />} />
                        }
                        label={
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              color: "#4A4A4A",
                              width: '100%',
                              backgroundColor: "#fff",
                              padding: '0.5rem 1rem',
                              borderRadius: '5px',
                              display: 'flex',
                              justifyContent: 'flex-start'
                            }}>
                            <Box sx={{ display: 'flex', gap: '0.2rem', "p": { paddingRight: '0.3rem' }, "span": { fontWeight: '400' } }}>
                              <p>{data.id}x</p>
                              <span>de</span>
                              <b>{formatCurrency(data.value)}</b>
                              {/* <span>sem juros</span> */}
                            </Box>
                          </Typography>
                        }
                      />
                    ))}
                  </FormGroup>
                </Grid>
                <Grid item display={'flex'} xs={12} justifyContent={'center'} marginTop={'1rem'}>
                  {type === 'Contrato' && !system.pagamento && (
                    <Box sx={{ display: 'flex', margin: '1rem 1rem 0.7rem 1rem', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                      {!info.info &&
                        <Typography variant="caption">
                          Selecione uma das opções de pagamento
                        </Typography>
                      }
                      <ButtonCB onClick={sendPayment} fullWidth background="#209CFF" sx={{ margin: '0 1rem' }}>Solicitar Contrato</ButtonCB>
                    </Box>)}
                </Grid>
              </Grid>
            </Collapse>
          </Box> : null
        }

        {typePayment === 'Pix' || isContract ?
          <Box width={'100%'}>
            <ButtonSection icon={<PixRoundedIcon />} viewArrow={isContract} text="Pix" subText='(desconto no pix)' radius='10px 10px 0 0' open={view.pix} onClick={() => setView({ ...view, pix: !view.pix, credit: false, simulation: false })}>
            </ButtonSection>
            <Collapse
              sx={{
                width: '100%',
                backgroundColor: "#F2F2F2",
                padding: '0.5rem 0.2rem',
                boxSizing: 'border-box',
                borderRadius: '0px 0px 10px 10px'
              }}
              in={isContract ? view.pix : true}
              timeout="auto"
              unmountOnExit>
              <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 0.4rem', gap: '0.5rem' }}>
                <PixRoundedIcon sx={{ fill: "#0099B2", fontSize: '30px' }} />
                <Box className={styles.box_payment_title} sx={{ lineHeight: '35px', margin: '0.3rem 0', padding: '0', width: 'auto!important' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <h4>{formatCurrency(paymentValueTotal.parcelado)}</h4>
                    <Box>
                      <b className={styles.discount}>% DESCONTO NO PIX</b>
                    </Box>
                  </Box>
                  <h1 style={{ color: "#4A4A4A", fontWeight: 900 }}>{formatCurrency(valueTotal)}</h1>
                </Box>
              </Box>
              <FormControl component="fieldset" sx={{ width: "100%" }}>
                <FormGroup>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={info.info === 'Pix ou Transferência Bancária'}
                        label='123'
                        onChange={(e) => handleChange(e, 'À Vista', 'Pix ou Transferência Bancária')}
                        name={'1'}
                        value={system?.orcamento?.valor}
                        icon={<RadioButtonUncheckedIcon sx={{ backgroundColor: "#fff", borderRadius: '50%' }} />}
                        checkedIcon={<RadioButtonCheckedIcon sx={{ fill: "#747474", backgroundColor: "#fff", borderRadius: '50%' }} />}
                      />
                    }
                    label={
                      <Typography sx={{ fontWeight: 'bold', width: 'calc(100% - 65px)', backgroundColor: "#fff", color: "#4A4A4A", padding: '0.2rem 0.4rem', borderRadius: '5px' }}>
                        Pix ou Transferência Bancária
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={info.info === '80% de entrada + 20% na instalação'}
                        label='123'
                        onChange={(e) => handleChange(e, 'À Vista', '80% de entrada + 20% na instalação')}
                        name={'1'}
                        value={system?.orcamento?.valor}
                        icon={<RadioButtonUncheckedIcon sx={{ backgroundColor: "#fff", borderRadius: '50%' }} />}
                        checkedIcon={<RadioButtonCheckedIcon sx={{ fill: "#747474", backgroundColor: "#fff", borderRadius: '50%' }} />} />
                    }
                    label={
                      <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', width: 'calc(100% - 65px)', backgroundColor: "#fff", color: "#4A4A4A", padding: '0.2rem 0.4rem', borderRadius: '5px' }}>
                        80% de entrada + 20% na instalação
                        <NewTooltip title='5% de acréscimo no valor' placement='top'>
                          <IconButton size='small'><InfoOutlinedIcon /></IconButton>
                        </NewTooltip>
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={info.info === '70% de entrada + 2x boletos'}
                        label='123'
                        onChange={(e) => handleChange(e, 'À Vista', '70% de entrada + 2x boletos')}
                        name={'1'}
                        value={system?.orcamento?.valor}
                        icon={<RadioButtonUncheckedIcon sx={{ backgroundColor: "#fff", borderRadius: '50%' }} />}
                        checkedIcon={<RadioButtonCheckedIcon sx={{ fill: "#747474", backgroundColor: "#fff", borderRadius: '50%' }} />} />
                    }
                    label={
                      <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', width: 'calc(100% - 65px)', backgroundColor: "#fff", color: "#4A4A4A", padding: '0.2rem 0.4rem', borderRadius: '5px' }}>
                        70% de entrada + 2x boletos
                        <NewTooltip
                          title={<p><b>Parcelamento no boleto bancário depende de aprovação de crédito.</b> <br /><br />10% acréscimo no valor (1° boleto no ato da instalação e 2° boleto depois de 30 dias).</p>}
                          placement='top'>
                          <IconButton size='small'><InfoOutlinedIcon /></IconButton>
                        </NewTooltip>
                      </Typography>
                    }
                  />
                  {type === 'Contrato' && !system.pagamento && (
                      <Box sx={{ display: 'flex', margin: '1rem 1rem 0.7rem 1rem', alignItems: 'center', flexDirection: 'column' }}>
                        {!info.info &&
                          <Typography variant="caption">
                            Selecione uma das opções de pagamento
                          </Typography>
                        }
                        <ButtonCB fullWidth onClick={sendPayment} background="#209CFF" >Solicitar Contrato</ButtonCB>
                      </Box>
                      )}
                </FormGroup>
              </FormControl>
            </Collapse>
          </Box> : null
        }
        {!system?.simulacao_enviada && (
          typePayment === 'Boleto' ?
          !estimate?.simulacao && (
            <ButtonCB
              onClick={() => handleClick('simulação')}
              fullWidth
              size='large'
              background="#209CFF"
              sx={{ maxWidth: '400px', marginTop: '0.3rem' }}>Pedir análise de crédito</ButtonCB>) :
            Boolean((system?.pagamento_ref || system?.visita_tecnica) && !isContract && type !== 'Contrato') ?
              <ButtonCB fullWidth startIcon={<CalendarMonthIcon />} isClickable={false} colorText='#000' background="transparent" variant='outlined'>
                Visita Técnica Solicitada
              </ButtonCB> :
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center', alignItems: 'center' }}>
                {!info.info && !isContract && type !== 'Contrato' &&
                  <Typography variant="caption">
                    Selecione uma das opções de pagamento
                  </Typography>
                }
                {!isContract && type !== 'Contrato' &&
                  <ButtonCB fullWidth size='large' disabled={Boolean(system?.pagamento_ref)} onClick={sendPayment} background="#209CFF" sx={{ maxWidth: '400px', marginTop: '0.3rem' }}>
                    Agendar Visita Técnica
                  </ButtonCB>
                }
              </Box>
        )
        }
      </DialogContent>
    </Dialog>
  )
}

export default Payment;