import { Box, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#000', color: '#fff', padding: '1rem 1rem', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
        flexDirection: 'column', gap: '0rem', boxSizing: 'border-box', marginTop: '1rem' }}>
        <Typography padding={'0 1rem'} textAlign={'center'} fontWeight={'bold'}>Todos os direitos reservados © 2024</Typography>
        <Typography textAlign={'center'}>CorpBrasil Energia Renovável LTDA</Typography>
        <Typography textAlign={'center'}>CNPJ: 34.691.677/0001-35</Typography>
    </Box>
  )
}

export default Footer