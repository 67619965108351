import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
  useMemo,
} from "react";
import ReactDOMServer from "react-dom/server";
import Logo from "../../../images/LogoCORPBRASIL.png";

import styles from "../styles.module.scss";
import LogoMulher from "../../../images/LogoMulher.png";
import Warning from "../../../images/system/icons/Aviso.svg";
import Irradiation from "../../../images/system/IrradiacaoGoogle.png";
import Placa from "../../../images/system/Placa.png";
import Cabos from "../../../images/system/Cabos.png";
import Cabo from "../../../images/system/Cabo.png";

import {
  Alert,
  Avatar,
  Box,
  Checkbox,
  Collapse,
  Grid,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import LoupeRoundedIcon from "@mui/icons-material/LoupeRounded";
import ElectricBoltRoundedIcon from "@mui/icons-material/ElectricBoltRounded";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import FilterNoneRoundedIcon from "@mui/icons-material/FilterNoneRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import { TbSunElectricity } from "react-icons/tb";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PixRoundedIcon from "@mui/icons-material/PixRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaBarcode } from "react-icons/fa";

import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CustomAudioPlayer from "../../../components/AudioPlayerFixed/CustomAudioPlayer";
import IrradiationModal from "../components/IrradiationModal";
import { doc, getDoc } from "firebase/firestore";
import { dataBase } from "../../../firebase/database";

import Visita from "../../../images/system/servicos/Visita.png";
import Irradiacao2 from "../../../images/system/servicos/Irradiacao.png";
import Irradiacao_Grafico from "../../../images/system/Irradiacao_Grafico2.png";
import Homologacao from "../../../images/system/servicos/Homologacao.png";
import Engenharia from "../../../images/system/servicos/Engenharia.png";
import Instalacao from "../../../images/system/servicos/Instalacao.png";
import Monitoramento from "../../../images/system/servicos/Monitoramento.png";
import PosVenda from "../../../images/system/PosVenda.jpg";
import Mais_Vendido from "../../../images/system/MaisVendido.png";

import formatCurrency from "../../../functions/formatCurrency";
import axios from "axios";
import Loading from "../../../components/Loading";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BatteryChargingFullRoundedIcon from "@mui/icons-material/BatteryChargingFullRounded";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
// import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import Visit from "../components/Visit";
import Payment from "../components/Payment";
import ModalContract from "../components/ModalContract";
import ButtonCB from "../../../components/Button";
import InversorImage from "../components/InversorImage";
import ButtonSection from "../components/ButtonSection";
import ModalImprovements from "../components/ModalImprovements";
import { ItemKit } from "../components/ItemKit";
import moment from "moment";
import FindInversor from "../components/InversorImage";
import Footer from "../components/Footer";
import Financing from "../components/Finacing";
import { showBoxMessage } from "../../../functions/showBoxMessage";
// import Payment from '../components/Payment';


const services = [
  {
    name: "Visita Técnica",
    img: Visita,
    info: "Nosso técnico especializado visita o local para avaliar todos os aspectos elétricos e estruturais, garantindo uma instalação solar segura e eficiente.",
  },
  {
    name: "Estudo de Irradiação",
    img: Irradiacao2,
    info: "Utilizamos softwares certificados para analisar a irradiação e posicionar as placas solares de forma otimizada, garantindo a máxima eficiência do seu sistema.",
  },
  {
    name: "Homologação ART",
    img: Homologacao,
    info: "Nossos analistas cuidarão de todas as etapas de homologação junto à concessionária de energia, garantindo que seu sistema esteja pronto para operar dentro das normas e regulamentações.",
  },
  {
    name: "Engenharia",
    img: Engenharia,
    info: "Desenvolvemos projetos elétricos que integram seu sistema fotovoltaico à rede do imóvel, garantindo máxima segurança e desempenho.",
  },
  {
    name: "Instalação Fotovoltaica",
    img: Instalacao,
    info: "Nossos serviços de instalação seguem rigorosamente todas as normas, especificações e requisitos para cada componente do sistema de geração de energia solar.",
  },
  {
    name: "Monitoramento",
    subname: "(3 meses Facilit)",
    img: Monitoramento,
  },
  {
    name: "Nosso Pós-Venda",
    img: PosVenda,
    info: "Tenha tranquilidade ao usufruir dos nossos serviços de pós-venda, comprometidos com a melhoria contínua no atendimento, garantindo suporte ágil e eficaz para manter o desempenho ideal do seu sistema fotovoltaico.",
  },
];

const sctrutures = {
  Cerâmico: {
    src: "https://sketchfab.com/models/0b715a3091a240eda3b8ef47b2c8b217/embed",
    label: "Gancho Colonial Explosão",
  },
  Fibrocimento: {
    src: "https://sketchfab.com/models/9772a68d88194ba9a5f73e3a317536bd/embed",
    label: "Prisioneiro Madeira",
  },
  Metálico: {
    src: "https://sketchfab.com/models/c1ae9f81829747abaad5c96c3c77704d/embed",
    label: "Mini Trilho",
  },
  Solo: {
    src: "https://sketchfab.com/models/4cc7a00332924654a30a3b1e71f63a80/embed",
    label: "Solo Aluminio",
  },
  Carport: {
    src: "https://sketchfab.com/models/4cc7a00332924654a30a3b1e71f63a80/embed",
    label: "Solo Aluminio",
  },
  "Ecológico - PVC Plástico": {
    src: "https://sketchfab.com/models/0b715a3091a240eda3b8ef47b2c8b217/embed",
    label: "Gancho Colonial Explosão",
  },
  Shingle: {
    src: "https://sketchfab.com/models/0b715a3091a240eda3b8ef47b2c8b217/embed",
    label: "Gancho Colonial Explosão",
  },
  Zipado: {
    src: "https://sketchfab.com/models/0b715a3091a240eda3b8ef47b2c8b217/embed",
    label: "Gancho Colonial Explosão",
  },
};

const meses = [
  "janeiro",
  "fevereiro",
  "marco",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];

const Estimate = () => {
  const [open, setOpen] = useState({
    irradiation: false,
    modal: false,
    estimate: true,
    visit_tec: true,
    benefits: false,
    simulation: false,
    new_simulation: false,
    visit: false,
    improvements: false,
  });
  const [simulation, setSimulation] = useState(null);
  const navigate = useNavigate();
  const { combinedParam } = useParams();
  const [idCustomer, index, keyURL] = combinedParam.split("-");
  const [type, setType] = useState("");
  const [typePayment, setTypePayment] = useState(null);
  const [irradiaton, setIrradiaton] = useState(null);
  const [installment, setInstallment] = useState(null);
  const [proposal, setProposal] = useState({});
  const [, setInstallmentOptions] = useState([]);
  const [customer, setCustomer] = useState({});
  const [assistant, setAssistant] = useState(null);
  const [analyst, setAnalyst] = useState(null);
  const [seller, setSeller] = useState(null);
  const [visit, setVisit] = useState(null);
  const [system, setSystem] = useState(null);
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [valueTotal, setvalueTotal] = useState({
    parcelado: 0,
    vista: 0,
  });
  const [acceptImprovements, setAcceptImprovements] = useState(null);
  const [contractPayment, setContractPayment] = useState(null);

  const NewDivider = styled(Divider)(({ height, color, margin }) => ({
    width: "100%",
    height: height || "4px",
    border: "none",
    margin: margin || 0,
    background: `linear-gradient(90deg, rgba(243, 146, 0, 0) 0%, ${color || "#F39200"
      } 49.5%, rgba(243, 146, 0, 0) 100%)`,
  }));

  const NewBox = styled("div")(({ padding, margin }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "15px",
    width: "100%",
    backgroundColor: "#fff",
    margin: margin || 0,
    padding: padding || "1rem",
    boxSizing: "border-box",
    boxShadow: "0px 10px 24px 0px #0000001A",
  }));

  const Square = styled("div")(() => ({
    padding: "0.5rem",
    backgroundColor: "#FFAB00",
  }));

  const ButtonCondition = styled(Button)(() => ({
    borderRadius: "5px",
    fontSize: "15px",
    boxShadow: "none",
    fontWeight: "600",
    textTransform: "initial",
    padding: "0rem 0.5rem",
    height: "23px",
    color: "#fff",
    backgroundColor: "#000",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#4D4D4D",
    },
  }));

  const orcamentoRef = useRef(null);
  const headerRef = useRef(null);
  const [viewedOrcamento, setViewedOrcamento] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const visitaRef = useRef(null);
  const simulacaoRef = useRef(null);
  const formatPayment = useRef(null);
  const contractRef = useRef(null);

  useEffect(() => {
    if (keyURL === "visita" && visitaRef.current) {
      setTimeout(() => {
        visitaRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 500); // Ajuste o tempo conforme necessário
    } else if (keyURL === "simulacao" && simulacaoRef.current) {
      setTimeout(() => {
        simulacaoRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 500);
    } else if (keyURL === "agendarvisita" && formatPayment.current) {
      setTimeout(() => {
        formatPayment.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 500); // Ajuste o tempo conforme necessário
    } else if (keyURL === "contrato" && contractRef.current) {
      setTimeout(() => {
        contractRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 500); // Ajuste o tempo conforme necessário
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, system, simulation]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Atualize o estado com o id do elemento visível
          setViewedOrcamento(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.9, // Considera 50% de visibilidade para acionar
    });

    // Salve as referências dentro do effect
    const orcamentoElement = orcamentoRef.current;
    const headerElement = headerRef.current;
    // const visitaElement = visitaRef.current;

    if (orcamentoElement) {
      observer.observe(orcamentoElement);
    }
    if (headerElement) {
      observer.observe(headerElement);
    }
    // if (visitaElement) {
    //     observer.observe(visitaElement);
    // }

    return () => {
      // Utilize as referências salvas no momento da execução do efeito
      if (orcamentoElement) {
        observer.unobserve(orcamentoElement);
      }
      if (headerElement) {
        observer.unobserve(headerElement);
      }
      // if (visitaElement) {
      //     observer.unobserve(visitaElement);
      // }
    };
  }, [orcamentoRef, headerRef, visitaRef]);

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const customerDoc = await getDoc(doc(dataBase, "Clientes", idCustomer));

        if (!customerDoc.exists()) {
          setCustomer({});
          setLoading(false);
          window.location.replace("https://corpbrasil.com/");
          return;
        }

        const customerData = { ...customerDoc.data(), id: customerDoc.id };
        setCustomer(customerData);

        const fetchMemberData = async (uid) => {
          const memberDoc = await getDoc(doc(dataBase, "Membros", uid));
          return memberDoc.exists()
            ? { ...memberDoc.data(), id: memberDoc.id }
            : {};
        };

        const [assistant, seller] = await Promise.all([
          fetchMemberData(customerData.assistente),
          fetchMemberData(customerData.consultora_uid),
        ]);

        setAssistant(assistant);
        setSeller(seller);

        if (customerData && index !== undefined) {
          // console.log('oi22')
          const systemRef = customerData.planilha
            ? customerData.planilha[index]
            : null;
          // console.log(systemRef)

          if (systemRef) {
            setEstimate(
              customerData?.proposta?.find(
                (data) =>
                  data.nome.replace(/ /g, "_") ===
                  systemRef?.orcamento_solicitado.replace(/ /g, "_") ||
                  data.id === systemRef?.orcamento_id
              )
            );
          }

          if (!systemRef || !systemRef.extra) {
            window.location.replace("https://corpbrasil.com/");
            return;
          }

          if (systemRef.extra) {
            setSystem(systemRef);
            const proposalData =
              customerData?.proposta?.find((data) => (data.nome === systemRef.orcamento_solicitado || data.id === systemRef.orcamento_id)) || {};

            console.log('PROPOSTA', proposalData);

            const simulationData = proposalData?.simulacoes
              ? proposalData.simulacoes?.filter(
                (data) => data.status === "Enviado"
              )
              : null;
              
              // Ordena as simulações por 'createAt' em ordem crescente
              simulationData.sort((a, b) => new Date(a.createAt) - new Date(b.createAt));
              
              // Retorna a última simulação (a mais recente)
              const latestSimulation = simulationData[simulationData.length - 1];

            setProposal(proposalData);
            setSimulation(latestSimulation);
            setInstallmentOptions(simulationData?.parcelas || []);
            setAnalyst(
              await fetchMemberData(
                systemRef?.visita_tecnica?.responsavel_id || {}
              )
            );
            // const equipments = systemRef.equipamentos || [];
            // setKwhTotal(equipments.reduce((acc, item) => acc + item.kwh_total, 0).toFixed(2));
          } else {
            window.location.replace("https://corpbrasil.com/");
          }
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCustomer, index, dataBase, navigate, refresh]);

  useEffect(() => {
    if (system) {
      setVisit(system?.visita_tecnica || null);
      if (system?.melhorias) {
        setAcceptImprovements(true);
      }
    }
  }, [system]);

  useEffect(() => {
    if (acceptImprovements && system) {
      setvalueTotal({
        parcelado:
          Number(system?.orcamento?.valor_parcelado) +
          Number(visit?.rede_eletrica?.melhorias?.valor_total),
        vista:
          Number(system?.orcamento?.valor) +
          Number(visit?.rede_eletrica?.melhorias?.valor_total),
      });
    } else {
      setvalueTotal({
        parcelado: system?.orcamento?.valor_parcelado,
        vista: system?.orcamento?.valor,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptImprovements, system]);

  useEffect(() => {
    const getIrradiaton = async () => {
      if (!system || !system.lat || !system.lng) {
        console.log("Latitude e longitude não fornecidas.");
        return;
      }

      const { lat, lng } = system;

      const apiKey = "6uP1kWgERYYPfFm27apXso5RNcaJN3jUb17aOFlO";
      const url = `https://developer.nrel.gov/api/pvwatts/v8.json?api_key=${apiKey}&azimuth=180&system_capacity=4&losses=14&array_type=1&module_type=0&gcr=0.4&dc_ac_ratio=1.2&inv_eff=96.0&radius=0&dataset=nsrdb&tilt=10&soiling=12|4|45|23|9|99|67|12.54|54|9|0|7.6&albedo=0.3&bifaciality=0.7&lat=${lat}&lon=${lng}`;

      try {
        const { data } = await axios.get(url);

        setIrradiaton({
          annual: data.outputs.solrad_annual.toFixed(2),
          monthly: data.outputs.solrad_monthly,
        });
      } catch (error) {
        console.log("Ocorreu um erro na irradiação: ", error);
      }
    };

    getIrradiaton();
  }, [system]);

  const viewMessage = () => {
    return Swal.fire({
      html:
        `<p>A CorpBrasil prioriza a transparência, diferentemente de outras empresas que escondem dados e oferecem sistemas de <b>menor potência</b> para baixar o preço.<p><br/><br/>` +
        `<p>A energia solar varia com o clima, as estações, sujeira e imprevistos que afetam a absorção pelos módulos.</p>`,
      iconHtml: `<img src=${Warning} />`,
      customClass: {
        icon: "no-border",
      },
      confirmButtonColor: "#D9D9D9",
      confirmButtonText: "OK",
    });
  };

  const viewMessageGeneration = () => {
    return Swal.fire({
      html: `<p>A geração média mensal flutua de acordo com o clima, as estações, a sujeira e imprevistos que <b>afetam a absorção de luz</b> pelos módulos fotovoltaicos.<p>`,
      iconHtml: `<img src=${Warning} />`,
      customClass: {
        icon: "no-border",
      },
      confirmButtonColor: "#D9D9D9",
      confirmButtonText: "OK",
    });
  };

  const sumValue = useCallback(
    (type) => {
      let sum = 0;
      if (system) {
        meses.forEach((value) => {
          const geracao = system[value]?.geracao
            ? Number(system[value]?.geracao.replace(",", "."))
            : 0;
          const consumoAtual = system[value]?.consumo_atual
            ? Number(system[value]?.consumo_atual)
            : 0;
          const consumoAdicionado = system[value]?.consumo_adicionado
            ? Number(system[value]?.consumo_adicionado)
            : 0;

          if (type === "CSC") {
            sum += geracao - consumoAtual;
          } else if (type === "CCC") {
            sum += geracao - (consumoAtual + consumoAdicionado);
          } else if (type === "MSC") {
            sum += consumoAtual / 12;
          } else if (type === "MCC") {
            sum += (consumoAtual + consumoAdicionado) / 12;
          } else if (type === "MCA") {
            sum += consumoAdicionado / 12;
          }
        });
      }

      return sum;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [system]
  );

  // const onSubmit = async (type, value) => {

  //     const result = await Swal.fire({
  //         title: 'Parabéns! Você está fazendo uma ótima escolha!',
  //         iconHtml: `<img src=${LogoMulher} style="width: 120px" />`,
  //         customClass: {
  //             icon: 'no-border'
  //         },
  //         html: `Ao confirmar o método de pagamento, você garantirá a <b>reserva dos equipamentos</b> e o <b>agendamento de sua visita técnica</b>. Deseja prosseguir?`,
  //         icon: 'question',
  //         showCancelButton: true,
  //         showCloseButton: true,
  //         confirmButtonColor: '#00B707',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Sim',
  //         cancelButtonText: 'Não'
  //     });

  //     if (result.isConfirmed) {
  //         try {
  //             setLoading(true);

  //             const payment = type === 'Pagamento à Vista' ? {
  //                 tipo: type,
  //                 valor: Number(system.orcamento?.valor)
  //             } : {
  //                 tipo: type,
  //                 parcelas: value.quantidade,
  //                 valor: Number(value.valor),
  //             };

  //             let newSheets = customer.planilha;
  //             newSheets.splice(index, 1, {
  //                 ...system,
  //                 reserva: true,
  //                 pagamento: payment,
  //                 historico: [
  //                     ...system.historico,
  //                     {
  //                         data: moment().format('DD/MM/YYYY HH:mm'),
  //                         text: `Orçamento: O cliente aceitou ✅ a reserva dos materiais e optou pelo ${type}.`,
  //                     }
  //                 ]
  //             })

  //             const proposals = customer.proposta;
  //             if (type === 'Financiamento') {
  //                 proposal.status = 'Aceito';
  //             }

  //             await updateDoc(doc(dataBase, 'Clientes', idCustomer), {
  //                 planilha: newSheets,
  //                 proposta: proposals,
  //                 historico: [...customer.historico, {
  //                     data: moment().format('DD/MM/YYYY - HH:mm'),
  //                     evento: 'Orçamento',
  //                     text: `O Cliente ✅ aceitou a reserva dos materias do orçamento <b>${system.orcamento_solicitado}</b> e selecionou o método de pagamento '${type}'.`,
  //                     usuario_id: '0',
  //                     usuario_nome: 'robo'
  //                 }]
  //             });

  //             await axios.post('https://n8n.corpbrasil.cloud/webhook/a68ee6cd-2d99-4015-85bd-f8cdce709cbc', {
  //                 nome: customer.nome,
  //                 cidade: customer.cidade,
  //                 telefone: customer.telefone,
  //                 telefone_assistente: assistant.telefone,
  //                 kit: system.kit,
  //                 consultora: customer.consultora,
  //                 assistente: assistant.nome,
  //                 reserva: '✅ Aceito',
  //                 metodo: type,
  //                 chave: 'Reserva',
  //                 valor: value,
  //                 idFull: `${idCustomer}-${index}`,
  //                 id: idCustomer,
  //             });

  //             setLoading(false);
  //             await Swal.fire({
  //                 title: 'Parabéns!',
  //                 html: `A <b>Reserva</b> foi solicitada com sucesso.`,
  //                 icon: "success",
  //                 showConfirmButton: true,
  //                 showCloseButton: true,
  //                 confirmButtonColor: "#111",
  //             })
  //             setRefresh(true);

  //         } catch (error) {
  //             setLoading(false);
  //             console.log('Erro ao enviar feedback:', error);
  //         }
  //     }
  // }

  // const onSubmit = async (type, value) => {

  //     const result = await Swal.fire({
  //         title: 'Parabéns! Você está fazendo uma ótima escolha!',
  //         iconHtml: `<img src=${LogoMulher} style="width: 120px" />`,
  //         customClass: {
  //             icon: 'no-border'
  //         },
  //         html: `Ao confirmar o método de pagamento, você garantirá a <b>reserva dos equipamentos</b> e o <b>agendamento de sua visita técnica</b>. Deseja prosseguir?`,
  //         icon: 'question',
  //         showCancelButton: true,
  //         showCloseButton: true,
  //         confirmButtonColor: '#00B707',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Sim',
  //         cancelButtonText: 'Não'
  //     });

  //     if (result.isConfirmed) {
  //         try {
  //             setLoading(true);

  //             const payment = type === 'Pagamento à Vista' ? {
  //                 tipo: type,
  //                 valor: Number(system.orcamento?.valor)
  //             } : {
  //                 tipo: type,
  //                 parcelas: value.quantidade,
  //                 valor: Number(value.valor),
  //             };

  //             let newSheets = customer.planilha;
  //             newSheets.splice(index, 1, {
  //                 ...system,
  //                 reserva: true,
  //                 pagamento: payment,
  //                 historico: [
  //                     ...system.historico,
  //                     {
  //                         data: moment().format('DD/MM/YYYY HH:mm'),
  //                         text: `Orçamento: O cliente aceitou ✅ a reserva dos materiais e optou pelo ${type}.`,
  //                     }
  //                 ]
  //             })

  //             const proposals = customer.proposta;
  //             if(type === 'Financiamento') {
  //                 proposal.status = 'Aceito';
  //             }

  //             await updateDoc(doc(dataBase, 'Clientes', idCustomer), {
  //                 planilha: newSheets,
  //                 proposta: proposals,
  //                 historico: [...customer.historico, {
  //                     data: moment().format('DD/MM/YYYY - HH:mm'),
  //                     evento: 'Orçamento',
  //                     text: `O Cliente ✅ aceitou a reserva dos materias do orçamento <b>${system.orcamento_solicitado}</b> e selecionou o método de pagamento '${type}'.`,
  //                     usuario_id: '0',
  //                     usuario_nome: 'robo'
  //                 }]
  //             });

  //             await axios.post('https://n8n.corpbrasil.cloud/webhook/a68ee6cd-2d99-4015-85bd-f8cdce709cbc', {
  //                 nome: customer.nome,
  //                 cidade: customer.cidade,
  //                 telefone: customer.telefone,
  //                 telefone_assistente: assistant.telefone,
  //                 kit: system.kit,
  //                 consultora: customer.consultora,
  //                 assistente: assistant.nome,
  //                 reserva: '✅ Aceito',
  //                 metodo: type,
  //                 chave: 'Reserva',
  //                 valor: value,
  //                 idFull: `${idCustomer}-${index}`,
  //                 id: idCustomer,
  //             });

  //             setLoading(false);
  //             await Swal.fire({
  //                 title: 'Parabéns!',
  //                 html: `A <b>Reserva</b> foi solicitada com sucesso.`,
  //                 icon: "success",
  //                 showConfirmButton: true,
  //                 showCloseButton: true,
  //                 confirmButtonColor: "#111",
  //             })
  //             setRefresh(true);

  //         } catch (error) {
  //             setLoading(false);
  //             console.log('Erro ao enviar feedback:', error);
  //         }
  //     }
  // }

  const showServicesInfo = (data) => {
    return Swal.fire({
      title: data.name,
      html: data.info,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonColor: "#000",
      confirmButtonText: "Fechar",
    });
  };

  const showBenefitsInfo = () => {
    const htmlContent = ReactDOMServer.renderToString(
      <Stack width={"100%"} spacing={2}>
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          {/* <Square /> */}
          <Typography color="#4A4A4A">
            🟧 Monitoramento de geração e consumo de energia;
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <Typography color="#4A4A4A">
            🟧 Relatórios mensais de geração e consumo de energia;
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <Typography color="#4A4A4A">
            🟧 Acesso ao portal da concessionária para dados de consumo e
            geração;
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <Typography color="#4A4A4A">
            🟧 Suporte com analista fotovoltaico para dúvidas;
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <Typography color="#4A4A4A">
            🟧 Monitoramento constante com alerta de desempenho.
          </Typography>
        </Box>
      </Stack>
    );
    return Swal.fire({
      title: "Benefícios",
      html: htmlContent,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonColor: "#000",
      confirmButtonText: "Fechar",
      customClass: {
        htmlContainer: "benefits",
      },
    });
  };

  // const installmentValue = (value, rate = 3.05, installmentsValue = 60) => {
  //     let taxaJurosMensal = rate / 100;
  //     let numeroParcelas = installmentsValue;
  //     let pmt = (value * taxaJurosMensal * Math.pow(1 + taxaJurosMensal, numeroParcelas)) /
  //         (Math.pow(1 + taxaJurosMensal, numeroParcelas) - 1);
  //     return pmt.toFixed(2);
  // }

  // const installmentBox = async () => {

  //     let values = [];
  //     const rates = [4.16, 3.15, 2.72, 2.46, 2.29, 2.17, 2.08, 2.01, 1.95, 1.90, 1.86, 1.83, 1.88, 1.86, 1.83, 1.81, 1.79, 1.77];

  //     for (let i = 0; i < 18; i++) {
  //         values.push({
  //             texto: `${i + 1}x de ${formatCurrency(installmentValue(system?.orcamento?.valor, rates[i], i + 1))}`,
  //             quantidade: i + 1,
  //             valor: installmentValue(system?.orcamento?.valor, rates[i], i + 1)
  //         })
  //     }

  //     const inputOptions = values.reduce((acc, parcela, index) => {
  //         acc[index] = parcela.texto;
  //         return acc;
  //     }, {});

  //     const { value: estimate } = await Swal.fire({
  //         title: "Cartão de Crédito",
  //         html: `Selecione em quantas vezes você deseja parcelar`,
  //         input: "select",
  //         inputOptions: inputOptions,
  //         showCloseButton: true,
  //         inputPlaceholder: "Selecione a quantidade de parcelas",
  //         showCancelButton: true,
  //         confirmButtonText: 'Confirmar',
  //         confirmButtonColor: "#0eb05f",
  //         cancelButtonText: 'Fechar',
  //         inputValidator: (value) => {
  //             if (!value) {
  //                 return "Selecione uma quantidade";
  //             }
  //         }
  //     });

  //     if (estimate) {
  //         const selectedEstimate = values[estimate];
  //         onSubmit('Cartão de Crédito', selectedEstimate);
  //     }
  // }

  const sendPaymentData = (data, type) => {
    setContractPayment(data);
    if (type === "visit") {
      setType("Gostou");
    }
    setOpen({ ...open, [type]: true, payment: false });
  };

  const closePaymentData = (type) => {
    setOpen({ ...open, [type]: false });
    setTimeout(() => {
      setContractPayment(null);
    }, 300);
  };

  const memoizedCustomer = useMemo(() => customer, [customer]);
  const memoizedSystem = useMemo(() => system, [system]);
  const memoizedAssistant = useMemo(() => assistant, [assistant]);

  const memoizedSetRefresh = useCallback(
    (value) => {
      setRefresh(value);
    },
    [setRefresh]
  );

  const openPaymentContract = (typeRef, typePaymentRef) => {

    if (typePaymentRef === 'Financiamento' && !installment) {
      return showBoxMessage({ icon: 'warning', title: 'Atenção', text: 'Escolha uma das parcelas acima para solicitar o contrato' });
    }

    if (visit?.rede_eletrica?.melhorias?.custos === "Sim") { // Caso tenha melhorias no projeto
      if (typePaymentRef === 'Financiamento' && installment && acceptImprovements !== null) {
        return openSendContract("contract");
      }

      if (acceptImprovements === null && !open.improvements) {
        setTypePayment(typePaymentRef);
        return setOpen((prevOpen) => ({ ...prevOpen, improvements: true }));
      }
    } else {
      if (typePaymentRef === 'Financiamento' && installment) {
        return openSendContract("contract");
      }
    }

    setType(typeRef);
    setTypePayment(typePaymentRef);
    setOpen((prevOpen) => ({
      ...prevOpen,
      payment: true,
      improvements: false,
    }));

  };

  const openPayment = (typeRef, typePaymentRef) => {
    console.log(typeRef, typePaymentRef)

    // Verifica se o tipo é 'Contrato' e melhorias não foram aceitas nem abertas
    if (typeRef === "Contrato" && !acceptImprovements && !open.improvements) {
      
      setTypePayment(typeRef);
      return setOpen((prevOpen) => ({ ...prevOpen, improvements: true }));
    }

    // Verifica se o tipo de pagamento é 'Contrato' e se melhorias foram aceitas ou não
    if (typePaymentRef === "Contrato" && acceptImprovements !== undefined) {
      setType(typeRef);
      return setOpen((prevOpen) => ({
        ...prevOpen,
        payment: true,
        improvements: false,
      }));
    }

    // Caso geral: define o tipo e o tipo de pagamento e abre a seção de pagamento
    setType(typeRef);
    setTypePayment(typePaymentRef);
    setOpen((prevOpen) => ({
      ...prevOpen,
      payment: true,
      improvements: false,
    }));
  };

  const openVisit = (typeRef, ref) => {
    if (ref === "Simulação") {
      openSendContract("visit");
      return setType(typeRef);
    }

    setType(typeRef);
    setOpen({ ...open, visit: true });
  };

  //   const openContract = async () => {
  //     if (acceptImprovements) {
  //       const result = await Swal.fire({
  //         title: "Atenção",
  //         iconHtml: `<img src=${LogoMulher} style="width: 120px" />`,
  //         customClass: {
  //           icon: "no-border",
  //         },
  //         html: `As melhorias elétricas incluidas no seu projeto <b>irão alterar os valores das parcelas</b>. Você está ciente dessa mudança e deseja continuar?`,
  //         icon: "question",
  //         showCancelButton: true,
  //         showCloseButton: true,
  //         confirmButtonColor: "#00B707",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Sim",
  //         cancelButtonText: "Não",
  //       });
  //       if (!result.isConfirmed) {
  //         return null;
  //       }
  //       openSendContract("contract");
  //     } else {
  //       openSendContract("contract");
  //     }
  //   };

  const openSendContract = (type) => {

    const simulationInfo = { ...installment };
    simulationInfo.info = `${installment.quantidade}x ${formatCurrency(
      installment.valor
    )}`;
    simulationInfo.valor_total =
      Number(installment.valor) * Number(installment.quantidade);

    sendPaymentData({
      entrada: "0",
      info: simulationInfo.info,
      parcelas: simulationInfo.quantidade,
      restante_1: acceptImprovements
        ? formatCurrency(visit?.rede_eletrica?.melhorias?.valor_total)
        : 0,
      restante_2: 0,
       
      tipo: installment.condicao_especial ? `Condição Especial (${simulation.condicao_especial?.banco})` : `Financiamento (${simulation.banco})`,
      valor: simulationInfo.valor,
      aviso: visit?.rede_eletrica?.melhorias?.custos === 'Sim' && acceptImprovements
        ? `⚠ Fazer nova simulação de financiamento (${formatCurrency(
          valueTotal.parcelado
        )})`
        : "",
      valor_total: valueTotal.parcelado,
    });
    setOpen({ ...open, [type]: true, improvements: false });
  };

  const openWhatsApp = useCallback((phoneNumber, text) => {
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}${text}`;
    return window.open(url, "_blank");
  },[]);

  const getFirstName = (fullName) => {
    if (!fullName) return "";
    const nameParts = fullName?.trim().split(" ");
    return nameParts[0];
  };

  // const calculatePercentageDifference = useCallback((initialValue, finalValue) => {
  //     const difference = finalValue - initialValue;
  //     const percentage = (difference / initialValue) * 100;
  //     return percentage.toFixed(2);
  //   },[])

  const getMonthAfterGracePeriod = useCallback((date) => {
    const currentDate = moment(new Date());
    const futureDate = currentDate.add(date || 90, "days"); // Adiciona 90 dias à data atual
    console.log(futureDate);
    return futureDate.format("MMMM / YYYY"); // Retorna o nome do mês
  }, []);

  return (
    <>
      <style>
        {`
            .benefits {
                text-align: left!important;
                line-height: 30px!important;
            }
            `}
      </style>
      <HelmetProvider>
        <Helmet>
          <title>Orçamento | CORPBRASIL Energia Renovável</title>
          <meta
            name="title"
            content="Orçamento | CORPBRASIL Energia Renovável"
          />
          <meta
            name="description"
            content="A Experiência dos Nossos Clientes com Energia Solar"
          />
        </Helmet>
      </HelmetProvider>
      <div className={styles.estimate}>
        <Loading view={loading} />
        <Payment
          open={open.payment}
          close={() => closePaymentData("payment")}
          onCloseInSimulation={() =>
            setOpen({ ...open, payment: false, simulation: true })
          }
          customer={customer}
          index={index}
          estimate={estimate}
          sendContract={sendPaymentData}
          type={type}
          openVisit={openVisit}
          typePayment={typePayment}
          simulation={simulation}
          paymentValueTotal={valueTotal}
          system={system}
          assistant={assistant}
          setRefresh={setRefresh}
          proposal={proposal}
          installment={installment}
          openPaymentContract={openPaymentContract}
          setInstallment={setInstallment}
        />
        <div className={styles.estimate_header_fixed}>
          <div>
            <img src={Logo} alt="" />
            <h3>{viewedOrcamento || "Orçamento"}</h3>
          </div>
          <NewDivider />
        </div>
        <div className={`${styles.estimate_content} ${styles.flex_center}`}>
          <Grid
            className={styles.flex_center}
            container
            spacing={1}
            sx={{ width: "100%", maxWidth: "800px" }}
          >
            <Grid item xs={9}>
              <NewBox>
                <Box sx={{ lineHeight: "25px" }}>
                  <h3>Olá {getFirstName(customer?.nome)},</h3>
                  <p>confira o seu orçamento!</p>
                </Box>
              </NewBox>
            </Grid>
            <Grid item xs={3} sm={"auto"}>
              <NewBox padding="0.5rem">
                <img
                  className={styles.logo}
                  src={LogoMulher}
                  alt="CORPBRASIL"
                />
              </NewBox>
            </Grid>
          </Grid>
          <Box
            ref={headerRef}
            className={styles.flex_center_column}
            sx={{ width: "100%", padding: 2 }}
          >
            <div className={styles.system_equipment_title}>
              <Square />
              <h3>Descubra o Segredo por Trás da Precisão no seu Orçamento</h3>
            </div>
            <Box className={styles.video_container}>
              <div>
                <iframe allowFullScreen title="Apresentação" src="https://streamable.com/e/2p65ti?loop=0" style={{ border:"none", width:"100%", height:"100%", position: "absolute", left:"0px", top:"0px", overflow: "hidden" }} />
              </div>
            </Box>
            <Link onClick={viewMessage} className={styles.link}>
              <InfoIcon />
              Informações importantes, clique aqui!
            </Link>
          </Box>
          <Grid container spacing={1} sx={{ width: "100%", maxWidth: "800px" }}>
            <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
              <NewBox margin="0.5rem 0 0 0">
                <h3>Seu Consumo</h3>
                <NewDivider
                  height="3px"
                  color="#F1F1F1"
                  margin="0.6rem 0 0.9rem 0"
                />
                <Stack
                  sx={{ width: "100%", paddingBottom: "0.5rem" }}
                  spacing={1}
                >
                  <Alert
                    icon={false}
                    color="error"
                    sx={{ border: "1px solid #F9DBD4", 
                      "& .MuiAlert-message": {
                        width: "100%",
                      }, }}
                  >
                    {/* <Box className={styles.flex_row}>
                      <p>Consumo Médio Mensal</p>
                      <p>
                        <b>{sumValue("MSC").toFixed(0)}</b> kWh
                      </p>
                    </Box> */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>Consumo Médio Mensal</p>
                      <Box className={styles.flex_row}>
                        <TrendingUpRoundedIcon sx={{ fill: "red" }} />
                        <h2>{sumValue("MSC").toFixed(0)} kWh</h2>
                      </Box>
                    </Box>
                  </Alert>
                  {system?.equipamentos?.length > 0 ? (
                    <>
                      <Alert
                        icon={false}
                        color="warning"
                        sx={{ border: "1px solid #F9EBCB" }}
                      >
                        <Box className={styles.flex_row}>
                          <Box className={styles.flex_row}>
                            <LoupeRoundedIcon sx={{ fill: "#FFAB00" }} />
                            <p>Consumo Médio Adicionado</p>
                          </Box>
                          <p>
                            <b>{sumValue("MCA").toFixed(0)}</b> kWh
                          </p>
                        </Box>
                        <NewDivider
                          height="2px"
                          color="#EDDCBF"
                          margin="0.2rem 0 0 0"
                        />
                        <Box sx={{ marginTop: "0.5rem" }}>
                          {system?.equipamentos?.map((data, index) => (
                            <Box key={index} className={styles.flex_row}>
                              <p>
                                <b>{data.quantidade}</b> - {data.nome}
                              </p>
                              <p>
                                <b>
                                  {data.horas}{" "}
                                  {data.categoria === "Locomoção"
                                    ? "recarga(s) "
                                    : "hora(s) "}
                                </b>
                                {data?.dias === 30 || !data?.dias
                                  ? data.categoria === "Locomoção"
                                    ? "por mês"
                                    : "por dia"
                                  : `durante ${data?.dias} dias`}
                              </p>
                            </Box>
                          ))}
                        </Box>
                      </Alert>
                      <Alert
                        icon={false}
                        color="error"
                        sx={{
                          border: "1px solid #F9DBD4",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <p>Somas dos Consumos</p>
                          <Box className={styles.flex_row}>
                            <CalculateOutlinedIcon
                              size="1.6rem"
                              sx={{ fill: "#FF5630" }}
                            />
                            <h2>{sumValue("MCC").toFixed(0)} kWh</h2>
                          </Box>
                        </Box>
                      </Alert>
                    </>
                  ) : null}
                </Stack>
              </NewBox>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
              <NewBox margin="0.5rem 0 0 0" sx={{ flex: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <h3>Sua Geração</h3>
                  <IconButton size="small" onClick={viewMessageGeneration}>
                    <InfoIcon />
                  </IconButton>
                </Box>
                <NewDivider
                  height="3px"
                  color="#F1F1F1"
                  margin="0.3rem 0 0.5rem 0"
                />
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <Alert
                    icon={false}
                    color="info"
                    sx={{
                      border: "1px solid #C9EEF4",
                      "& .MuiAlert-message": {
                        width: "100%",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>Geração Média Mensal</p>
                      <Box className={styles.flex_row}>
                        <TbSunElectricity size="1.6rem" />
                        <h2>{system?.geracao} kWh</h2>
                      </Box>
                    </Box>
                  </Alert>
                  <Alert
                    icon={<ElectricBoltRoundedIcon />}
                    color="success"
                    sx={{ border: "1px solid #D0F0DE" }}
                  >
                    <Box className={styles.flex_row}>
                      <p>Potência Recomendada</p>
                      <b>{system?.kit} kWp</b>
                    </Box>
                  </Alert>
                  <Alert
                    icon={<BatteryChargingFullRoundedIcon />}
                    color="success"
                    sx={{ border: "1px solid #D0F0DE" }}
                  >
                    <Box className={styles.flex_row} sx={{ gap: "0.4rem" }}>
                      <p>
                        {sumValue("CCC") / 12 < 0
                          ? "Créditos Insuficientes"
                          : "Créditos Excedentes"}{" "}
                      </p>
                      <b>
                        {(sumValue("CCC") / 12).toFixed(0)}
                        <b>kWh ({system?.gordura}%)</b>
                      </b>
                    </Box>
                  </Alert>
                </Stack>
              </NewBox>
            </Grid>
            <Grid item xs={12} sm={12}>
              <NewBox margin="0.5rem 0 0 0" sx={{ flex: 1 }}>
                <h3>
                  {system?.extra?.audioExclusivo?.url
                    ? "Entenda o seu orçamento"
                    : "Áudio exclusivo para você"}
                </h3>
                <NewDivider
                  height="3px"
                  color="#F1F1F1"
                  margin="0.3rem 0 0.5rem 0"
                />
                <Box
                  className={styles.flex_row}
                  sx={{ width: "100%", maxWidth: "500px" }}
                >
                  <Avatar
                    src={assistant?.photo?.url}
                    alt=""
                    sx={{ width: 60, height: 60 }}
                  />
                  <Box className={styles.flex_center}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "end",
                      }}
                      fontSize={14}
                    >
                      <b>Analista fotovoltaica:</b>
                      <p>{assistant?.nome_completo}</p>
                    </Box>
                    <CustomAudioPlayer
                      url={system?.extra?.mensagemVoz?.url || ""}
                      color="#747474"
                    />
                  </Box>
                </Box>
              </NewBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NewBox margin="0.5rem 0 0 0" sx={{ flex: 1 }}>
                <h3>Irradiação solar</h3>
                <NewDivider
                  height="3px"
                  color="#F1F1F1"
                  margin="0.3rem 0 0.5rem 0"
                />
                <Box
                  className={styles.flex_center_column}
                  sx={{ width: "100%" }}
                >
                  <p>
                    A quantidade de irradiação solar no local de instalação é
                    crucial para calcular a geração de energia de cada placa
                    solar. Veja abaixo o gráfico de irradiação por hora:
                  </p>
                  <div className={styles.irradiation_img}>
                    <img src={Irradiacao_Grafico} alt="Irrradiação" />
                  </div>
                  <div className={styles.irradiation_img}>
                    <img src={Irradiation} alt="Irrradiação" />
                  </div>
                  <ButtonSection
                    icon={false}
                    align="center"
                    viewArrow={false}
                    text="Ver Minha Irradiação"
                    onClick={() => setOpen({ ...open, irradiation: true })}
                  />
                  <IrradiationModal
                    irradiaton={irradiaton}
                    open={open.irradiation}
                    close={() => setOpen({ ...open, irradiation: false })}
                  />
                </Box>
              </NewBox>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
              <NewBox margin="0.5rem 0 0 0" sx={{ flex: 1 }}>
                <h3>Local da instalação</h3>
                <NewDivider
                  height="3px"
                  color="#F1F1F1"
                  margin="0.3rem 0 0.5rem 0"
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <div
                    className={styles.irradiation_img}
                    style={{ maxHeight: "300px" }}
                  >
                    <img
                      src={system?.extra?.googleEarth?.url || ""}
                      alt="Irrradiação"
                    />
                  </div>
                </Box>
              </NewBox>
            </Grid>
            <Grid
              className={styles.flex_center_column}
              item
              xs={12}
              sx={{ marginTop: "1.5rem" }}
              id="orcamento"
            >
              <ButtonSection
                icon={false}
                size="large"
                align="center"
                open={open.estimate}
                text="Ver meu orçamento"
                onClick={() => setOpen({ ...open, estimate: !open.estimate })}
              />
              <Collapse
                sx={{ width: "100%" }}
                in={open.estimate}
                timeout="auto"
                unmountOnExit
              >
                <Grid container spacing={2}>
                  <Grid
                    id="Orçamento"
                    ref={orcamentoRef}
                    item
                    xs={12}
                    sm={6}
                    sx={{ display: "flex" }}
                  >
                    {" "}
                    {/* Módulos Fotovoltaico */}
                    <NewBox margin="0.5rem 0 0 0" sx={{ flex: 1 }}>
                      <h3>Módulos Fotovoltaicos</h3>
                      <NewDivider
                        height="3px"
                        color="#F1F1F1"
                        margin="0.3rem 0 0.5rem 0"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flex: 1,
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            width: "110px",
                            position: "absolute",
                            left: "0",
                            top: "0",
                          }}
                          src={Mais_Vendido}
                          alt="Mais Vendido"
                        />
                        <div
                          className={styles.irradiation_img}
                          style={{ maxHeight: "500px", width: "auto" }}
                        >
                          <img
                            src={Placa}
                            alt="Placa"
                            style={{ maxWidth: "90px" }}
                          />
                        </div>
                      </Box>
                      <Stack
                        sx={{ width: "100%", margin: "1rem 1rem 0 1rem" }}
                        spacing={1}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.8rem",
                          }}
                        >
                          <CheckBoxOutlinedIcon sx={{ fill: "#00B8D9" }} />
                          <p>
                            <b>Modelo:</b>{" "}
                            {system?.orcamento.modulo || "Não Informado"}
                          </p>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.8rem",
                          }}
                        >
                          <FilterNoneRoundedIcon sx={{ fill: "#00B8D9" }} />
                          <p>
                            <b>Quantidade:</b>{" "}
                            {system?.orcamento.modulo_quantidade || "0"}
                          </p>
                        </Box>
                      </Stack>
                    </NewBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {" "}
                    {/* Inversor Fotovoltaico */}
                    <NewBox margin="0.5rem 0 0 0" sx={{ flex: 1 }}>
                      <h3><FindInversor inversorName={(system?.orcamento?.inversor || "Padrão")} type="info" /> Fotovoltaico</h3>
                      <NewDivider
                        height="3px"
                        color="#F1F1F1"
                        margin="0.3rem 0 0.5rem 0"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flex: 1,
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            width: "110px",
                            position: "absolute",
                            left: "0",
                            top: "0",
                          }}
                          src={Mais_Vendido}
                          alt="Mais Vendido"
                        />
                        <div
                          className={styles.irradiation_img}
                          style={{
                            maxHeight: "500px",
                            display: "flex",
                            alignItems: "center",
                            minHeight: "180px",
                            width: "auto",
                          }}
                        >
                          <InversorImage
                            inversorName={
                              system?.orcamento?.inversor || "Padrão"
                            }
                            maxWidth={"150px"}
                          />
                        </div>
                      </Box>
                      <Stack
                        sx={{ width: "100%", margin: "1rem 1rem 0 1rem" }}
                        spacing={1}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.8rem",
                          }}
                        >
                          <CheckBoxOutlinedIcon sx={{ fill: "#00B8D9" }} />
                          <p>
                            <b>Modelo:</b>{" "}
                            {system?.orcamento.inversor || "Não Informado"}
                          </p>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.8rem",
                          }}
                        >
                          <FilterNoneRoundedIcon sx={{ fill: "#00B8D9" }} />
                          <p>
                            <b>Quantidade:</b>{" "}
                            {system?.orcamento.inversor_quantidade ||
                              "Não Informado"}
                          </p>
                        </Box>
                      </Stack>
                    </NewBox>
                  </Grid>
                  <Grid item xs={12}>
                    {" "}
                    {/* Estrutura Fotovoltaica */}
                    <NewBox margin="0.5rem 0 0 0" sx={{ flex: 1 }}>
                      <h3>Estrutura Fotovoltaica</h3>
                      <NewDivider
                        height="3px"
                        color="#F1F1F1"
                        margin="0.3rem 0 0.5rem 0"
                      />
                      <div className={styles.system_structure}>
                        <div className={styles.structure_3d}>
                          <iframe
                            title={sctrutures[estimate?.telhado]?.label}
                            allowFullScreen
                            mozallowfullscreen="true"
                            webkitallowfullscreen="true"
                            allow="autoplay; fullscreen; xr-spatial-tracking"
                            xr-spatial-tracking="true"
                            execution-while-out-of-viewport="true"
                            execution-while-not-rendered="true"
                            web-share="true"
                            src={sctrutures[estimate?.telhado]?.src}
                          ></iframe>
                        </div>
                      </div>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Box
                          sx={{
                            padding: "0.5rem 1rem",
                            boxSizing: "border-box",
                            lineHeight: "30px",
                          }}
                        >
                          <div className={styles.system_equipment_title}>
                            <Square />
                            <h3>Hooks e Grampos</h3>
                          </div>
                          <p>Liga de alumínio de alta densidade</p>
                        </Box>
                        <Box
                          sx={{
                            padding: "0.5rem 1rem",
                            boxSizing: "border-box",
                            lineHeight: "30px",
                          }}
                        >
                          <div className={styles.system_equipment_title}>
                            <Square />
                            <h3>Perfis</h3>
                          </div>
                          <p>Alumínio extrudado</p>
                        </Box>
                        <Box
                          sx={{
                            padding: "0.5rem 1rem",
                            boxSizing: "border-box",
                            lineHeight: "30px",
                          }}
                        >
                          <div className={styles.system_equipment_title}>
                            <Square />
                            <h3>Parafusos</h3>
                          </div>
                          <p>Aço inoxidável</p>
                        </Box>
                      </Box>
                    </NewBox>
                  </Grid>
                  <Grid item xs={12}>
                    {" "}
                    {/* Componentes Fotovoltaicos */}
                    <NewBox margin="0.5rem 0 0 0" sx={{ flex: 1 }}>
                      <h3>Componentes Fotovoltaicos</h3>
                      <NewDivider
                        height="3px"
                        color="#F1F1F1"
                        margin="0.3rem 0 0.5rem 0"
                      />
                      <Box sx={{ padding: "1rem", width: "100%" }}>
                        <p>
                          Os cabos são especialmente projetados para energia de
                          corrente contínua e produzidos com materiais de alta
                          resistência às intempéries, incluindo proteção UV.
                          Eles são capazes de suportar temperaturas de até 120ºC
                          em operação contínua.
                        </p>
                        <div className={styles.flex_center}>
                          <img src={Cabos} alt="Cabos" />
                        </div>
                        <p>
                          Os conectores MC4 e MC6 garantem conexões seguras
                          entre o inversor e os módulos fotovoltaicos,
                          assegurando uma geração contínua de energia.
                        </p>
                      </Box>
                    </NewBox>
                  </Grid>
                  <Grid item xs={12}>
                    {" "}
                    {/* Prazo máximo de entrega */}
                    <NewBox
                      margin="0.5rem 0 0 0"
                      sx={{ flex: 1, border: "1px solid #FFAB00" }}
                    >
                      <h3>Cronograma de Entrega</h3>
                      <NewDivider
                        height="3px"
                        color="#FFAB00"
                        margin="0.3rem 0 1rem 0"
                      />
                      <Box sx={{ padding: "0 1rem 1rem 1rem" }}>
                        <p>
                          Todos os serviços, incluindo entrega de materiais,
                          engenharia, instalação, homologação, vistoria da
                          concessionária e troca do relógio,{" "}
                          <b>
                            são concluídos dentro de um prazo máximo de 90 dias.
                          </b>
                        </p>
                      </Box>
                    </NewBox>
                  </Grid>
                  <Grid item xs={12}>
                    {" "}
                    {/* Serviços Inclusos */}
                    <NewBox margin="0.5rem 0 0 0" sx={{ flex: 1 }}>
                      <h3>Serviços Inclusos</h3>
                      <NewDivider
                        height="3px"
                        color="#FFAB00"
                        margin="0.3rem 0 0.5rem 0"
                      />
                      <Grid
                        sx={{ width: "100%", margin: "0.2rem 0 1rem 0" }}
                        container
                        spacing={2}
                      >
                        {services.map((data, index) => (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              borderBottom:
                                index !== 6 ? "1px dashed #ccc" : "0",
                              paddingBottom: "15px",
                            }}
                          >
                            <div className={styles.service_list}>
                              {/* <CheckBoxOutlinedIcon sx={{ fill: "#00B8D9" }} /> */}
                              <div className={styles.service_list_img}>
                                <img src={data.img} alt="" />
                              </div>
                              <div className={styles.service_list_extra}>
                                <h4>
                                  {data.name}{" "}
                                  {data.info && (
                                    <>
                                      <IconButton
                                        onClick={() => showServicesInfo(data)}
                                        size="small"
                                      >
                                        <InfoOutlinedIcon />
                                      </IconButton>
                                    </>
                                  )}
                                </h4>
                                {data.subname && (
                                  <div>
                                    <b>{data.subname}</b>
                                    <IconButton
                                      size="small"
                                      onClick={showBenefitsInfo}
                                    >
                                      <InfoIcon />
                                    </IconButton>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </NewBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      className={styles.flex_center_column}
                      sx={{ width: "100%", margin: '0.7rem 0 0.5rem 0', 'h3': { wordBreak: 'break-word' } }}
                    >
                    <div className={styles.system_equipment_title}>
                      <Square />
                      <h3>Descubra como a CorpBrasil garante suporte em até 2 horas para você!</h3>
                    </div>
                    {/* <p>Utilizamos nosso software de engenharia para analisar o histórico de consumo da sua fatura de energia e a localização da instalação do sistema. Com esses dados, calculamos a potência ideal (kWp) do seu sistema de energia solar.</p> */}
                    <Box className={styles.video_container}>
                      <div>
                        <iframe allowFullScreen title="Apresentação" src="https://streamable.com/e/a9vbg4?loop=0" style={{ border:"none", width:"100%", height:"100%", position: "absolute", left:"0px", top:"0px", overflow: "hidden" }} />
                      </div>
                    </Box>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12}> // Kit Fotovoltaico
                                        <NewBox margin='0.5rem 0 0 0' sx={{ flex: 1 }}>
                                            <h3>Kit Fotovoltaico</h3>
                                            <NewDivider height='3px' color='#F1F1F1' margin='0.3rem 0 1rem 0' />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%', position: 'relative' }}>
                                                <img style={{ width: '110px', zIndex: '11', position: 'absolute', left: 'calc(50% + 50px)', top: '-10px' }} src={Mais_Vendido} alt='Mais Vendido' />
                                                <div className={styles.irradiation_img} style={{ position: 'relative', display: 'flex', alignItems: 'center', minHeight: '180px', width: 'auto', maxHeight: 'none' }}>
                                                    <img src={Placa} alt='Placa' style={{ maxWidth: '120px' }} />
                                                </div>
                                                <Box sx={{ position: 'absolute', bottom: 0, left: 'calc(50% - 7px)', maxWidth: '110px' }}>
                                                    <InversorImage inversorName={system?.orcamento?.inversor || 'Padrão'} />
                                                </Box>
                                                <Box sx={{ position: 'absolute', bottom: 0, right: 'calc(50% + 10px)', maxWidth: '110px' }}>
                                                    <img src={Cabo} alt='Cabo' style={{ maxWidth: '70px' }} />
                                                </Box>
                                            </Box>
                                            <Box className={styles.box_payment_value}>
                                                <p>Geração média mensal</p>
                                                <Box className={styles.flex_row}>
                                                    <TbSunElectricity size='1.6rem' />
                                                    <h2>{system?.geracao} kWh</h2>
                                                </Box>
                                            </Box>
                                        </NewBox>
                                    </Grid> */}

                  {system?.simulacao_enviada && !system?.visita_tecnica && (
                    <Grid item xs={12} sm={12}>
                      <Financing simulation={simulation} simulacaoRef={simulacaoRef} assistant={assistant} system={system} customer={customer}
                      openVisit={openVisit} setRefresh={setRefresh} proposal={proposal} installment={installment} setInstallment={setInstallment}/>
                    </Grid>
                  )}
                  
                  {system?.extra?.audioExclusivo?.url && (
                    <Grid item xs={12} sm={12}>
                      {" "}
                      {/* Audio 2 - Exclusivo  */}
                      <NewBox margin="0" sx={{ flex: 1 }}>
                        <h3>Áudio exclusivo para você</h3>
                        <NewDivider
                          height="3px"
                          color="#F1F1F1"
                          margin="0.3rem 0 0.5rem 0"
                        />
                        <Box
                          className={styles.flex_row}
                          sx={{ width: "100%", maxWidth: "500px" }}
                        >
                          <Avatar
                            src={assistant?.photo?.url}
                            alt=""
                            sx={{ width: 60, height: 60 }}
                          />
                          <Box className={styles.flex_center}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "end",
                              }}
                              fontSize={14}
                            >
                              <b>Analista fotovoltaica:</b>
                              <p>{assistant?.nome_completo}</p>
                            </Box>
                            <CustomAudioPlayer
                              url={system?.extra?.audioExclusivo?.url || ""}
                              color="#747474"
                            />
                          </Box>
                        </Box>
                      </NewBox>
                    </Grid>
                  )}

                  {!system?.visita_tecnica && (
                    <Grid item xs={12} ref={formatPayment} id="Pagamento">
                      {" "}
                      {/* Pagamento 1 - Visita */}
                      <div className={styles.box_payment}>
                        <Box
                          className={styles.box_payment_header}
                          sx={{ flexDirection: "column" }}
                        >
                          <b>Formas de pagamento</b>
                          <p>Promoção válida por 7 dias</p>
                        </Box>
                        <Grid
                          container
                          width="100%"
                          spacing={2}
                          sx={{ maxWidth: 440 }}
                        >
                          <Grid item xs={4} className={styles.flex_center}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flex: 1,
                                width: "100%",
                                position: "relative",
                              }}
                            >
                              <img
                                style={{
                                  width: "80px",
                                  zIndex: "11",
                                  position: "absolute",
                                  left: "calc(50% + 0px)",
                                  top: "-10px",
                                }}
                                src={Mais_Vendido}
                                alt="Mais Vendido"
                              />
                              <div
                                className={styles.irradiation_img}
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  alignItems: "center",
                                  minHeight: "180px",
                                  width: "auto",
                                  maxHeight: "none",
                                }}
                              >
                                <img
                                  src={Placa}
                                  alt="Placa"
                                  style={{ maxWidth: "70px" }}
                                />
                              </div>
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: "calc(50% - 7px)",
                                  maxWidth: "80px",
                                }}
                              >
                                <InversorImage
                                  inversorName={
                                    system?.orcamento?.inversor || "Padrão"
                                  }
                                />
                              </Box>
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: 0,
                                  right: "calc(50% + 10px)",
                                  maxWidth: "35px",
                                }}
                              >
                                <img
                                  src={Cabo}
                                  alt="Cabo"
                                  style={{ maxWidth: "35px" }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Box
                              sx={{
                                padding: "0rem 0.3rem 1rem 0.5rem",
                                fontSize: "14px",
                              }}
                            >
                              <p>Kit fotovoltaico completo instalado</p>
                              <ItemKit
                                item={system?.orcamento}
                                estimate={estimate}
                              />
                            </Box>
                            <Box className={styles.box_payment_value}>
                              <p>Geração média mensal</p>
                              <Box className={styles.flex_row}>
                                <TbSunElectricity size="1.6rem" />
                                <h3>{system?.geracao} kWh</h3>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} className={styles.flex_center}>
                            <div className={styles.box_payment_title}>
                              <NewDivider
                                height="3px"
                                color="#CCC"
                                margin="0rem 0 1rem 0"
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <PixRoundedIcon
                                  sx={{ fill: "#0099B2", fontSize: "30px" }}
                                />
                                <Box
                                  sx={{
                                    lineHeight: "35px",
                                    margin: "0.3rem 0",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "0.5rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h4>
                                      {formatCurrency(valueTotal.parcelado)}
                                    </h4>
                                    <Box>
                                      <span className={styles.discount}>
                                        DESCONTO NO PIX
                                      </span>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "0.5rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        color: "#4A4A4A",
                                        fontSize: "35px",
                                      }}
                                    >
                                      {formatCurrency(valueTotal.vista)}
                                    </h2>
                                  </Box>
                                  <ButtonCondition
                                    onClick={() => openPayment("Visita", "Pix")}
                                  >
                                    Consulte condições
                                  </ButtonCondition>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "1rem",
                                  marginTop: "1rem",
                                }}
                              >
                                <CreditCardRoundedIcon
                                  sx={{ fontSize: "30px", marginTop: "0.2rem" }}
                                />
                                <Box
                                  sx={{
                                    lineHeight: "21px",
                                    margin: "0.3rem 0 0.5rem 0",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.2rem",
                                  }}
                                >
                                  <b>Parcelamento no Cartão de Crédito</b>
                                  <p>
                                    <b
                                      style={{
                                        color: "#4A4A4A",
                                        marginBottom: "0.3rem",
                                      }}
                                    >
                                      {formatCurrency(valueTotal.parcelado)}
                                    </b>
                                    <span>
                                      {" "}
                                      em até <b>18x</b> parcelas iguais.
                                    </span>
                                  </p>
                                  <Box sx={{ marginTop: "0.1rem" }}>
                                    <ButtonCondition
                                      onClick={() =>
                                        openPayment("Visita", "Cartão")
                                      }
                                    >
                                      Consulte condições
                                    </ButtonCondition>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "1rem",
                                  marginTop: "1rem",
                                }}
                              >
                                <FaBarcode
                                  size="27px"
                                  style={{ marginTop: "0.3rem" }}
                                />
                                <Box
                                  sx={{
                                    lineHeight: "20px",
                                    margin: "0.3rem 0 0.5rem 0",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.2rem",
                                  }}
                                >
                                  <Box>
                                    <Chip
                                      sx={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                        backgroundColor: "#1caad9",
                                      }}
                                      label={<p>Comece a pagar em{" "} 
                                        <Typography textTransform={'uppercase'} marginTop={'0.5rem'} variant="p">{getMonthAfterGracePeriod(simulation?.carencia)}</Typography></p>}
                                      size="small"
                                    />
                                  </Box>
                                  <b>Parcelamento via Boleto Bancário</b>
                                  <p>
                                    <b
                                      style={{
                                        color: "#4A4A4A",
                                        marginBottom: "0.3rem",
                                      }}
                                    >
                                      {formatCurrency(valueTotal.parcelado)}
                                    </b>
                                    <span>
                                      {" "}
                                      em até <b>72x</b> parcelas.
                                    </span>
                                  </p>
                                  <Box sx={{ marginTop: "0.1rem" }}>
                                    <ButtonCondition
                                      onClick={() =>
                                        openPayment("Visita", "Boleto")
                                      }
                                    >
                                      Consulte condições
                                    </ButtonCondition>
                                  </Box>
                                </Box>
                              </Box>
                              {visit?.rede_eletrica?.melhorias?.custos ===
                                "Sim" ? (
                                <Box
                                  sx={{
                                    border: "1px solid #FFAB00",
                                    borderRadius: "100px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    gap: "0.3rem",
                                    fontSize: "16px",
                                    marginTop: "1rem",
                                    padding: "0.3rem",
                                  }}
                                >
                                  {acceptImprovements ? (
                                    <>
                                      <VerifiedUserIcon
                                        sx={{ fill: "#0FA958" }}
                                      />
                                      <span>
                                        Valor <b>inclui</b> melhorias elétricas.
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <PrivacyTipIcon
                                        sx={{ fill: "#ff4343" }}
                                      />
                                      <span>
                                        Valor <b>não inclui</b> melhorias
                                        elétricas.
                                      </span>
                                    </>
                                  )}
                                </Box>
                              ) : null}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.5rem",
                            }}
                          >
                            <Typography
                              padding={"0 1rem"}
                              variant="b"
                              component="b"
                            >
                              Promoção por tempo limitado!
                            </Typography>
                            <Typography
                              padding={"0 1rem"}
                              lineHeight={"20px"}
                              component="p"
                            >
                              Para garantir a promoção, agende a visita técnica clicando em <b className={styles.like}>Próxima Etapa</b>.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <div className={styles.box_payment_buttons}>
                              {system?.visita ? (
                                <ButtonCB
                                  fullWidth
                                  size="large"
                                  startIcon={<EventAvailableIcon />}
                                  colorText="#209CFF"
                                  background="transparent"
                                  variant="outlined"
                                >
                                  Visita Solicitada
                                </ButtonCB>
                              ) : (
                                <>
                                  <ButtonCB
                                    fullWidth
                                    size="large"
                                    // startIcon={<ThumbUpIcon />}
                                    background="#209CFF"
                                    onClick={() => openVisit("Gostou")}
                                  >
                                    Próxima Etapa
                                  </ButtonCB>
                                  <ButtonCB
                                    fullWidth
                                    size="large"
                                    background="#383737"
                                    colorText="#fff"
                                    onClick={() => openVisit("Não Gostou")}
                                  >
                                    Ajustar Orçamento
                                  </ButtonCB>
                                  <ButtonCB
                                    fullWidth
                                    size="large"
                                    background="#22b558"
                                    startIcon={<WhatsAppIcon />}
                                    onClick={() => window.open(`https://wa.me/${assistant?.telefone}?text=Ol%C3%A1%2C%20tenho%20uma%20d%C3%BAvida%20referente%20ao%20meu%20or%C3%A7amento`, '_blank')}
                                  >
                                   Dúvidas? Clique aqui
                                  </ButtonCB>
                                </>
                              )}
                              {/* {system?.visita ?
                                                                <ButtonST fullWidth startIcon={<EventAvailableIcon />} variant='outlined' size='large' colorText="#209CFF" background="transparent">Visita Solicitada</ButtonST> :
                                                                <ButtonST fullWidth variant='contained' size='large' onClick={() => setOpen({ ...open, visit: true })} background="#209CFF">Gostei, Quero uma Visita Técnica</ButtonST>
                                                            } */}
                              <Visit
                                open={open.visit}
                                close={() => closePaymentData("visit")}
                                customer={customer}
                                index={index}
                                assistant={assistant}
                                seller={seller}
                                paymentData={contractPayment}
                                type={type}
                                system={system}
                                setRefresh={setRefresh}
                                sendPayment={contractPayment}
                              />
                              {/* {system?.contrato ?
                                                                <ButtonST fullWidth startIcon={<IoBagCheck />} variant='outlined' size='large' colorText="#01c845" background="transparent">Contrato Solicitado</ButtonST> :
                                                                <ButtonST fullWidth variant='contained' size='large' onClick={() => openPayment('Visita')} background="#01c845">Opções de pagamento</ButtonST>
                                                            } */}
                              {/* <ButtonST fullWidth variant='contained' background='#9c9c9c' size='large' onClick={() => window.open(`https://api.whatsapp.com/send/?phone=${seller.telefone}`, '_blank')}>Falar com Especialista Fotovoltaico</ButtonST> */}
                            </div>
                          </Grid>
                        </Grid>
                        <Footer />
                        {/* {!system?.simulacao_enviada ?
                          <Footer /> : <Box marginTop={'1rem'} />
                        } */}
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12} ref={visitaRef} id="Visita Técnica">
                    {" "}
                    {/* Visita Técnica Box */}
                    {system?.visita_tecnica && (
                      <Grid Container spacing={2}>
                        <Grid item xs={12}>
                          <ButtonSection
                            icon={false}
                            size="large"
                            align="center"
                            open={open.visit_tec}
                            text="Relatório de Visita Técnica"
                            onClick={() =>
                              setOpen({ ...open, visit_tec: !open.visit_tec })
                            }
                          />
                        </Grid>
                        <Collapse
                          sx={{ width: "100%" }}
                          in={open.visit_tec}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid item xs={12}>
                            <Grid
                              className={styles.flex_center}
                              container
                              spacing={1}
                              sx={{
                                width: "100%",
                                maxWidth: "800px",
                                margin: "1rem 0",
                              }}
                            >
                              <Grid item xs={9}>
                                <NewBox
                                  sx={{
                                    minHeight: "83px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      lineHeight: "25px",
                                      fontSize: "20px",
                                    }}
                                  >
                                    <p>
                                      <b>Olá {getFirstName(customer?.nome)},</b>{" "}
                                      sou {analyst?.nome_ref}, Analista de
                                      Projetos!
                                    </p>
                                  </Box>
                                </NewBox>
                              </Grid>
                              <Grid item xs={3} sm={"auto"}>
                                <NewBox padding="0.5rem">
                                  <img
                                    style={{
                                      borderRadius: "50%",
                                      maxWidth: "69px",
                                    }}
                                    src={analyst?.photo?.url}
                                    alt="CORPBRASIL"
                                  />
                                </NewBox>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Box
                            ref={headerRef}
                            className={styles.flex_center_column}
                            padding={2}
                          >
                            <div className={styles.system_equipment_title}>
                              <Square />
                              {/* <h3>Olá {customer?.nome}, sou {analyst?.nome_ref}, Analista de Projetos CorpBrasil Energia Renovável.</h3> */}
                              <h3>Visita Técnica</h3>
                            </div>
                            <p>
                              Irei te explicar como é que garantimos a você a
                              qualidade em nossos projetos e a precisão na
                              geração de energia solar
                            </p>
                          </Box>
                          {visit?.audio_apresentacao?.url ? (
                            <Grid item xs={12} sm={12}>
                              {" "}
                              {/* Audio */}
                              <NewBox margin="1rem 0 0 0" sx={{ flex: 1 }}>
                                <h3>Entenda a visita técnica</h3>
                                <NewDivider
                                  height="3px"
                                  color="#F1F1F1"
                                  margin="0.3rem 0 0.5rem 0"
                                />
                                <Box
                                  className={styles.flex_row}
                                  sx={{ width: "100%", maxWidth: "500px" }}
                                >
                                  <Avatar
                                    src={analyst?.photo?.url}
                                    alt=""
                                    sx={{ width: 60, height: 60 }}
                                  />
                                  <Box className={styles.flex_center}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "end",
                                      }}
                                      fontSize={14}
                                    >
                                      <b>Analista de projetos:</b>
                                      <p>{analyst?.nome_completo}</p>
                                    </Box>
                                    <CustomAudioPlayer
                                      url={visit?.audio_apresentacao?.url || ""}
                                      color="#747474"
                                    />
                                  </Box>
                                </Box>
                              </NewBox>
                            </Grid>
                          ) : null}
                          <Grid item xs={12} sm={12}>
                            {" "}
                            {/* Visita Técnica - Projeto */}
                            <NewBox margin="1rem 0" sx={{ flex: 1 }}>
                              <div className={styles.visit_container}>
                                <div className={styles.system_equipment_title}>
                                  <Square />
                                  <h3>Imagem Aérea</h3>
                                </div>
                                {Array.isArray(visit?.projeto?.telhado) ? (
                                  visit?.projeto?.telhado?.map(
                                    (data, index) => (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                        key={index}
                                      >
                                        <img src={data.url} alt="irradiação" />
                                        {index === 0 ? (
                                          <div
                                            className={styles.visit_commentary}
                                          >
                                            <b>Análise:</b>
                                            <p>
                                              Nesta primeira imagem, utilizamos
                                              uma foto aérea capturada por
                                              drone, feita pelo nosso técnico
                                              responsável pela vistoria. Durante
                                              essa etapa, analisamos a área
                                              útil, a altura e o layout do seu
                                              telhado.
                                            </p>
                                          </div>
                                        ) : null}
                                      </Box>
                                    )
                                  )
                                ) : (
                                  <Box>
                                    <img
                                      src={visit?.projeto?.telhado?.url || ""}
                                      alt="irradiação"
                                    />
                                    <div className={styles.visit_commentary}>
                                      <b>Análise:</b>
                                      <p>
                                        Nesta primeira imagem, utilizamos uma
                                        foto aérea capturada por drone, feita
                                        pelo nosso técnico responsável pela
                                        vistoria. Durante essa etapa, analisamos
                                        a área útil, a altura e o layout do seu
                                        telhado.
                                      </p>{" "}
                                      {/* {visit?.projeto?.telhado?.comentario} */}
                                    </div>
                                  </Box>
                                )}
                              </div>
                              <div className={styles.visit_container}>
                                <div className={styles.system_equipment_title}>
                                  <Square />
                                  <h3>Projeto</h3>
                                </div>
                                <img
                                  src={visit?.projeto?.projeto?.url || ""}
                                  alt="irradiação"
                                />
                                <div className={styles.visit_commentary}>
                                  <b>Análise:</b>
                                  <p>
                                    {" "}
                                    Com um software de engenharia israelense,
                                    projetamos a área onde o sistema será
                                    instalado e analisamos qualquer possível
                                    obstáculo que pode interferir na geração de
                                    energia{" "}
                                  </p>{" "}
                                  {/* {visit?.projeto?.projeto?.comentario} */}
                                </div>
                              </div>
                              <div className={styles.visit_container}>
                                <div className={styles.system_equipment_title}>
                                  <Square />
                                  <h3>Estudo de Irradiação</h3>
                                </div>
                                <img
                                  src={visit?.projeto?.irradiacao?.url || ""}
                                  alt="irradiação"
                                />
                                <div className={styles.visit_commentary}>
                                  <b>Análise:</b>
                                  <p>
                                    Essa etapa é muito importante para garantir
                                    que nossos clientes tenham a melhor geração
                                    de energia. Nela, fazemos um estudo da luz
                                    solar no telhado, encontramos o melhor lugar
                                    para instalar as placas solares e
                                    aproveitamos ao máximo a eficiência, de
                                    acordo com a inclinação e a posição do
                                    telhado.
                                  </p>{" "}
                                  {/* {visit?.projeto?.irradiacao?.comentario} */}
                                </div>
                              </div>
                              <div className={styles.visit_container}>
                                <div className={styles.system_equipment_title}>
                                  <Square />
                                  <h3>Projeto Finalizado</h3>
                                </div>
                                <img
                                  src={visit?.projeto?.projeto_final?.url || ""}
                                  alt="irradiação"
                                />
                                <div className={styles.visit_commentary}>
                                  <b>Análise:</b>
                                  <p>
                                    Esta imagem é para você,{" "}
                                    {getFirstName(customer?.nome)}, visualizar
                                    como os módulos ficarão lindos instalados no
                                    telhado do seu imóvel, aproveitando a luz do
                                    sol e transformando em economia
                                  </p>{" "}
                                  {/* {visit?.projeto?.projeto_final?.comentario} */}
                                </div>
                              </div>
                              <ButtonCB
                                startIcon={<WhatsAppIcon />}
                                background="#00cc4c"
                                fullWidth
                                sx={{ maxWidth: '350px' }}
                                onClick={() => openWhatsApp(analyst?.telefone)}
                              >
                                Dúvidas? Clique aqui
                              </ButtonCB>
                            </NewBox>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            {" "}
                            {/* Audio */}
                            <NewBox margin="1rem 0 0 0" sx={{ flex: 1 }}>
                              <h3>Áudio da analista de projetos</h3>
                              <NewDivider
                                height="3px"
                                color="#F1F1F1"
                                margin="0.3rem 0 0.5rem 0"
                              />
                              <Box
                                className={styles.flex_row}
                                sx={{ width: "100%", maxWidth: "500px" }}
                              >
                                <Avatar
                                  src={analyst?.photo?.url}
                                  alt=""
                                  sx={{ width: 60, height: 60 }}
                                />
                                <Box className={styles.flex_center}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "end",
                                    }}
                                    fontSize={14}
                                  >
                                    <b>Analista de projetos:</b>
                                    <p>{analyst?.nome_completo}</p>
                                  </Box>
                                  <CustomAudioPlayer
                                    url={visit?.projeto?.audio?.url || ""}
                                    color="#747474"
                                  />
                                </Box>
                              </Box>
                            </NewBox>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            {" "}
                            {/* Visita Técnica - Rede Elétrica */}
                            <NewBox margin="1rem 0 0 0" sx={{ flex: 1 }}>
                              <h3>Rede Elétrica</h3>
                              <NewDivider
                                height="3px"
                                color="#F1F1F1"
                                margin="0.3rem 0 1rem 0"
                              />
                              {visit?.rede_eletrica?.analise?.map(
                                (data, index) => (
                                  <div
                                    className={styles.visit_container}
                                    key={index}
                                  >
                                    <img src={data.url} alt="irradiação" />
                                    {data.comentario ? (
                                      <div className={styles.visit_commentary}>
                                        <b>Análise:</b>
                                        <p>{data.comentario}</p>
                                      </div>
                                    ) : null}
                                  </div>
                                )
                              )}
                              <ButtonCB
                                startIcon={<WhatsAppIcon />}
                                background="#00cc4c"
                                sx={{ maxWidth: '350px' }}
                                fullWidth
                                onClick={() => openWhatsApp(analyst?.telefone)}
                              >
                                Dúvidas? Clique aqui
                              </ButtonCB>
                            </NewBox>
                          </Grid>
                          {visit?.rede_eletrica?.melhorias ? (
                            <>
                              <Grid item xs={12}>
                                {" "}
                                {/* Visita Técnica - Melhorias Elétrica */}
                                <NewBox margin="0.5rem 0 0 0">
                                  <h3>Melhorias elétricas</h3>
                                  <NewDivider
                                    height="3px"
                                    color="#F1F1F1"
                                    margin="0.3rem 0 1rem 0"
                                  />
                                  <Table>
                                    <TableHead
                                      sx={{
                                        backgroundColor: "#EDEDED",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 1rem",
                                          border: "none",
                                        }}
                                      >
                                        Descrição
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem",
                                          border: "none",
                                        }}
                                      >
                                        Qntd
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          width: "90px",
                                          padding: "0.2rem 1rem",
                                          border: "none",
                                        }}
                                      >
                                        Preço
                                      </TableCell>
                                    </TableHead>
                                    <TableBody
                                      sx={{ td: { padding: "0.4rem 0.8rem" } }}
                                    >
                                      {visit?.rede_eletrica?.melhorias?.material?.map(
                                        (data, index) => (
                                          <TableRow key={index}>
                                            <TableCell>{data.nome}</TableCell>
                                            <TableCell>
                                              {data.categoria === "Mão de Obra"
                                                ? "1"
                                                : data.quantidade}
                                            </TableCell>
                                            <TableCell>
                                              {formatCurrency(data.valor_total)}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      margin: "1rem 0 0.5rem 0",
                                      justifyContent: "flex-end",
                                      width: "100%",
                                    }}
                                  >
                                    <Typography
                                      variant="b"
                                      component="b"
                                      sx={{
                                        padding: "0.65rem 1rem",
                                        backgroundColor: "#FFAB00",
                                        borderRadius: "15px",
                                      }}
                                    >
                                      Valor total:{" "}
                                      {formatCurrency(
                                        visit?.rede_eletrica?.melhorias
                                          ?.valor_total
                                      )}
                                    </Typography>
                                  </Box>
                                </NewBox>
                              </Grid>
                              {visit?.rede_eletrica?.melhorias?.custos ===
                                "Sim" ? (
                                <Grid item xs={12}>
                                  {" "}
                                  {/* Atenção - Melhorias Elétricas */}
                                  <NewBox
                                    margin="1rem 0 0 0"
                                    sx={{
                                      flex: 1,
                                      border: "1px solid #FFAB00",
                                    }}
                                  >
                                    <h3>Atenção - Melhorias elétricas</h3>
                                    <NewDivider
                                      height="3px"
                                      color="#FFAB00"
                                      margin="0.3rem 0 1rem 0"
                                    />
                                    <Box
                                      sx={{ padding: "0 0.6rem 0.6rem 0.6rem" }}
                                    >
                                      <p>
                                        Quer incluir essas melhorias elétricas e
                                        contar com a instalação garantida pela
                                        CorpBrasil?
                                      </p>
                                      <FormGroup sx={{ marginTop: "1rem" }}>
                                        <FormControlLabel
                                          sx={{ margin: 0 }}
                                          control={
                                            <Checkbox
                                              checked={
                                                system?.melhorias
                                                  ? system?.melhorias
                                                  : acceptImprovements
                                              }
                                              color="success"
                                              label="123"
                                              onChange={() => {
                                                if (system?.melhorias) {
                                                  return; // Não faz nada se "melhorias" estiver ativado
                                                }
                                                setAcceptImprovements(true); // Executa ação se "melhorias" não estiver ativado
                                              }}
                                              icon={
                                                <RadioButtonUncheckedIcon
                                                  sx={{
                                                    fill: "#0FA958",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              }
                                              checkedIcon={
                                                <CheckCircleIcon
                                                  sx={{
                                                    fill: "#0FA958",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              }
                                            />
                                          }
                                          label={
                                            <Typography
                                              sx={{
                                                fontWeight: "bold",
                                                width: "calc(100% - 65px)",
                                                backgroundColor: "#fff",
                                                color: "#4A4A4A",
                                                padding: "0.2rem 0.4rem",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              ✅ Sim, adicionar melhorias.
                                            </Typography>
                                          }
                                        />
                                        <FormControlLabel
                                          sx={{ margin: 0 }}
                                          control={
                                            <Checkbox
                                              checked={
                                                system?.melhorias
                                                  ? system?.melhorias
                                                  : acceptImprovements === false
                                              }
                                              color="success"
                                              label="123"
                                              onChange={() => {
                                                if (system?.melhorias) {
                                                  return; // Não faz nada se "melhorias" estiver ativado
                                                }
                                                setAcceptImprovements(false); // Executa ação se "melhorias" não estiver ativado
                                              }}
                                              icon={
                                                <RadioButtonUncheckedIcon
                                                  sx={{
                                                    fill: "#0FA958",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              }
                                              checkedIcon={
                                                <CheckCircleIcon
                                                  sx={{
                                                    fill: "#0FA958",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              }
                                            />
                                          }
                                          label={
                                            <Typography
                                              sx={{
                                                fontWeight: "bold",
                                                width: "calc(100% - 65px)",
                                                backgroundColor: "#fff",
                                                color: "#4A4A4A",
                                                padding: "0.2rem 0.4rem",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              ❌ Não, manter o orçamento atual.
                                            </Typography>
                                          }
                                        />
                                      </FormGroup>
                                    </Box>
                                  </NewBox>
                                </Grid>
                              ) : null}
                            </>
                          ) : null}
                          <Grid item xs={12} sm={12}>
                            {" "}
                            {/* Audio - rede elétrica */}
                            <NewBox margin="1rem 0 0 0" sx={{ flex: 1 }}>
                              <h3>Áudio da analista sobre rede elétrica</h3>
                              <NewDivider
                                height="3px"
                                color="#F1F1F1"
                                margin="0.3rem 0 0.5rem 0"
                              />
                              <Box
                                className={styles.flex_row}
                                sx={{ width: "100%", maxWidth: "500px" }}
                              >
                                <Avatar
                                  src={analyst?.photo?.url}
                                  alt=""
                                  sx={{ width: 60, height: 60 }}
                                />
                                <Box className={styles.flex_center}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "end",
                                    }}
                                    fontSize={14}
                                  >
                                    <b>Analista de projetos:</b>
                                    <p>{analyst?.nome_completo}</p>
                                  </Box>
                                  <CustomAudioPlayer
                                    url={
                                      visit?.rede_eletrica?.audio_rede?.url ||
                                      ""
                                    }
                                    color="#747474"
                                  />
                                </Box>
                              </Box>
                            </NewBox>
                          </Grid>
                          {system?.visita_tecnica?.rede_eletrica?.audio_reversao?.url ? (
                            <>
                              <Grid item xs={12}>
                                {/* Análise de reversão de fluxo*/}
                                <NewBox
                                  margin="1rem 0 0 0"
                                  sx={{ flex: 1, border: "1px solid #FFAB00" }}
                                >
                                  <h3>Análise de reversão de fluxo </h3>
                                  <NewDivider
                                    height="3px"
                                    color="#FFAB00"
                                    margin="0.3rem 0 1rem 0"
                                  />
                                  <Box
                                    sx={{ padding: "0 0.6rem 0.6rem 0.6rem" }}
                                  >
                                    <p>
                                      De acordo com a norma da Aneel 1.098,
                                      projetos de energia solar com inversores{" "}
                                      <b>acima de 7.5 kW</b> precisam passar por
                                      uma análise para verificar se a rede pode
                                      suportar o sistema.
                                    </p>
                                    <Box marginTop={"1rem"}>
                                      <p>
                                        Projetos que tiverem beneficiarias mesmo{" "}
                                        <b>abaixo de 7.5 kW</b> será submetido a
                                        análise de reversão de fluxo pela
                                        concessionária de energia.
                                      </p>
                                    </Box>
                                  </Box>
                                </NewBox>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                {" "}
                                {/* Audio - reversão de fluxo*/}
                                <NewBox margin="1rem 0 0 0" sx={{ flex: 1 }}>
                                  <h3>Áudio da analista - Reversão de fluxo</h3>
                                  <NewDivider
                                    height="3px"
                                    color="#F1F1F1"
                                    margin="0.3rem 0 0.5rem 0"
                                  />
                                  <Box
                                    className={styles.flex_row}
                                    sx={{ width: "100%", maxWidth: "500px" }}
                                  >
                                    <Avatar
                                      src={analyst?.photo?.url}
                                      alt=""
                                      sx={{ width: 60, height: 60 }}
                                    />
                                    <Box className={styles.flex_center}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          textAlign: "end",
                                        }}
                                        fontSize={14}
                                      >
                                        <b>Analista de projetos:</b>
                                        <p>{analyst?.nome_completo}</p>
                                      </Box>
                                      <CustomAudioPlayer
                                        url={
                                          visit?.rede_eletrica?.audio_reversao
                                            ?.url || ""
                                        }
                                        color="#747474"
                                      />
                                    </Box>
                                  </Box>
                                </NewBox>
                              </Grid>
                            </>
                          ) : null}
                        </Collapse>

                        {system?.simulacao_enviada && system?.visita_tecnica && (
                          <Grid item xs={12} sm={12}>
                            <Financing simulation={simulation} simulacaoRef={simulacaoRef} assistant={assistant} system={system} customer={customer}
                            openVisit={openVisit} setRefresh={setRefresh} proposal={proposal} installment={installment} setInstallment={setInstallment}
                            type={'Contrato'} openPaymentContract={openPaymentContract}/>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          marginTop={"1rem"}
                          ref={contractRef}
                          id="Contrato"
                        >
                          <div className={styles.box_payment}>
                            <Box
                              className={styles.box_payment_header}
                              sx={{ flexDirection: "column" }}
                            >
                              <b>Formas de pagamento</b>
                              <p>Promoção válida por 7 dias</p>
                            </Box>
                            <Grid
                              container
                              width="100%"
                              spacing={2}
                              sx={{ maxWidth: 440 }}
                            >
                              <Grid item xs={4} className={styles.flex_center}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flex: 1,
                                    width: "100%",
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "80px",
                                      zIndex: "11",
                                      position: "absolute",
                                      left: "calc(50% + 0px)",
                                      top: "-10px",
                                    }}
                                    src={Mais_Vendido}
                                    alt="Mais Vendido"
                                  />
                                  <div
                                    className={styles.irradiation_img}
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      alignItems: "center",
                                      minHeight: "180px",
                                      width: "auto",
                                      maxHeight: "none",
                                    }}
                                  >
                                    <img
                                      src={Placa}
                                      alt="Placa"
                                      style={{ maxWidth: "70px" }}
                                    />
                                  </div>
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      bottom: 0,
                                      left: "calc(50% - 7px)",
                                      maxWidth: "80px",
                                    }}
                                  >
                                    <InversorImage
                                      inversorName={
                                        system?.orcamento?.inversor || "Padrão"
                                      }
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      bottom: 0,
                                      right: "calc(50% + 10px)",
                                      maxWidth: "35px",
                                    }}
                                  >
                                    <img
                                      src={Cabo}
                                      alt="Cabo"
                                      style={{ maxWidth: "35px" }}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  sx={{
                                    padding: "0rem 0.3rem 1rem 0.5rem",
                                    fontSize: "15px",
                                  }}
                                >
                                  <p>Kit fotovoltaico completo instalado</p>
                                  <ItemKit
                                    item={system?.orcamento}
                                    estimate={estimate}
                                  />
                                </Box>
                                <Box className={styles.box_payment_value}>
                                  <p>Geração média mensal</p>
                                  <Box className={styles.flex_row}>
                                    <TbSunElectricity size="1.6rem" />
                                    <h3>{system?.geracao} kWh</h3>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} className={styles.flex_center}>
                                <div className={styles.box_payment_title}>
                                  <NewDivider
                                    height="3px"
                                    color="#CCC"
                                    margin="0rem 0 1rem 0"
                                  />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "1rem",
                                    }}
                                  >
                                    <PixRoundedIcon
                                      sx={{ fill: "#0099B2", fontSize: "30px" }}
                                    />
                                    <Box
                                      sx={{
                                        lineHeight: "35px",
                                        margin: "0.3rem 0",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: "0.5rem",
                                          alignItems: "center",
                                        }}
                                      >
                                        <h4>
                                          {formatCurrency(valueTotal.parcelado)}
                                        </h4>
                                        <Box>
                                          <span className={styles.discount}>
                                            DESCONTO NO PIX
                                          </span>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: "0.5rem",
                                          alignItems: "center",
                                        }}
                                      >
                                        <h2
                                          style={{
                                            color: "#4A4A4A",
                                            fontSize: "35px",
                                          }}
                                        >
                                          {formatCurrency(valueTotal.vista)}
                                        </h2>
                                      </Box>
                                      <ButtonCondition
                                        onClick={() =>
                                          openPaymentContract("Contrato", "Pix")
                                        }
                                      >
                                        Consulte condições
                                      </ButtonCondition>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "1rem",
                                      marginTop: "1rem",
                                    }}
                                  >
                                    <CreditCardRoundedIcon
                                      sx={{
                                        fontSize: "30px",
                                        marginTop: "0.2rem",
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        lineHeight: "21px",
                                        margin: "0.3rem 0 0.5rem 0",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "0.2rem",
                                      }}
                                    >
                                      <b>Parcelamento no Cartão de Crédito</b>
                                      <p>
                                        <b
                                          style={{
                                            color: "#4A4A4A",
                                            marginBottom: "0.3rem",
                                          }}
                                        >
                                          {formatCurrency(valueTotal.parcelado)}
                                        </b>
                                        <span>
                                          {" "}
                                          em até <b>18x</b> parcelas iguais.
                                        </span>
                                      </p>
                                      <Box sx={{ marginTop: "0.1rem" }}>
                                        <ButtonCondition
                                          onClick={() =>
                                            openPaymentContract(
                                              "Contrato",
                                              "Cartão"
                                            )
                                          }
                                        >
                                          Consulte condições
                                        </ButtonCondition>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "1rem",
                                      marginTop: "1rem",
                                    }}
                                  >
                                    <FaBarcode
                                      size="27px"
                                      style={{ marginTop: "0.3rem" }}
                                    />
                                    <Box
                                      sx={{
                                        lineHeight: "20px",
                                        margin: "0.3rem 0 0.5rem 0",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "0.2rem",
                                      }}
                                    >
                                      <Box>
                                        <Chip
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#fff",
                                            backgroundColor: "#1caad9",
                                          }}
                                          label={<p>Comece a pagar apenas em{" "} 
                                            <Typography textTransform={'uppercase'} variant="p">{getMonthAfterGracePeriod(simulation?.carencia)}</Typography>
                                          </p>}
                                          size="small"
                                        />
                                      </Box>
                                      <b>Parcelamento via Boleto Bancário</b>
                                      <p>
                                        <b
                                          style={{
                                            color: "#4A4A4A",
                                            marginBottom: "0.3rem",
                                          }}
                                        >
                                          {formatCurrency(valueTotal.parcelado)}
                                        </b>
                                        <span>
                                          {" "}
                                          em até <b>72x</b> parcelas.
                                        </span>
                                      </p>
                                      <Box sx={{ marginTop: "0.1rem" }}>
                                        <ButtonCondition
                                          onClick={() =>
                                            openPaymentContract(
                                              "Contrato",
                                              "Boleto"
                                            )
                                          }
                                        >
                                          Consulte condições
                                        </ButtonCondition>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {visit?.rede_eletrica?.melhorias?.custos ===
                                    "Sim" ? (
                                    <Box
                                      sx={{
                                        border: "1px solid #FFAB00",
                                        borderRadius: "100px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: "flex",
                                        gap: "0.3rem",
                                        fontSize: "16px",
                                        marginTop: "1rem",
                                        padding: "0.3rem",
                                      }}
                                    >
                                      {acceptImprovements ? (
                                        <>
                                          <VerifiedUserIcon
                                            sx={{ fill: "#0FA958" }}
                                          />
                                          <span>
                                            Valor <b>inclui</b> melhorias
                                            elétricas.
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <PrivacyTipIcon
                                            sx={{ fill: "#ff4343" }}
                                          />
                                          <span>
                                            Valor <b>não inclui</b> melhorias
                                            elétricas.
                                          </span>
                                        </>
                                      )}
                                    </Box>
                                  ) : null}
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className={styles.box_payment_buttons}>
                                  {system?.contrato ? (
                                    <ButtonCB
                                      fullWidth
                                      size="large"
                                      startIcon={
                                        <AssignmentTurnedInRoundedIcon />
                                      }
                                      background="transparent"
                                      variant="outlined"
                                      colorText="#000"
                                      onClick={() =>
                                        setOpen({ ...open, contract: true })
                                      }
                                    >
                                      Contrato Solicitado
                                    </ButtonCB>
                                  ) : (
                                    <><ButtonCB
                                      fullWidth
                                      size="large"
                                      startIcon={
                                        <AssignmentTurnedInRoundedIcon />
                                      }
                                      background="#209CFF"
                                      onClick={() =>
                                        openPaymentContract("Contrato", "Contrato")
                                      }
                                    >
                                      Solicite seu Contrato
                                    </ButtonCB>
                                    <ButtonCB
                                    fullWidth
                                    size="large"
                                    background="#22b558"
                                    startIcon={<WhatsAppIcon />}
                                    onClick={() => window.open(`https://wa.me/5515988339165?text=Ol%C3%A1%2C%20tenho%20uma%20d%C3%BAvida%20referente%20ao%20meu%20or%C3%A7amento`, '_blank')}
                                  >
                                    Dúvidas? Clique aqui
                                  </ButtonCB></>
                                  )}
                                  <ModalImprovements
                                    open={open.improvements}
                                    close={() =>
                                      setOpen({ ...open, improvements: false })
                                    }
                                    acceptImprovements={acceptImprovements}
                                    setAcceptImprovements={
                                      setAcceptImprovements
                                    }
                                    visit={visit}
                                    openPaymentContract={openPaymentContract}
                                    type={typePayment}
                                  />
                                  <ModalContract
                                    open={open.contract}
                                    close={() => closePaymentData("contract")}
                                    customer={memoizedCustomer}
                                    system={memoizedSystem}
                                    assistant={memoizedAssistant}
                                    setRefresh={memoizedSetRefresh}
                                    paymentData={contractPayment}
                                    index={index}
                                    estimate={estimate}
                                    visit={visit}
                                    acceptImprovements={acceptImprovements}
                                    proposal={proposal}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Footer />
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default memo(Estimate);
