import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import Swal from "sweetalert2";
import moment from 'moment';
import { useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import ReplyIcon from '@mui/icons-material/Reply';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import AudioFileIcon from '@mui/icons-material/AudioFile';
// import LockIcon from '@mui/icons-material/Lock';
import HistoryIcon from '@mui/icons-material/History';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import MapIcon from '@mui/icons-material/Map';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { MdEngineering } from "react-icons/md";
import EngineeringIcon from '@mui/icons-material/Engineering';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import Logo from '../../../images/LogoCORPBRASIL.png';
import PainelSolar from '../../../images/icons/PainelSolar.png';
import "cooltipz-css";

import style from '../Components/style.module.scss';
import { Badge, Box, Button, IconButton, styled, Tab, Tabs, ThemeProvider } from '@mui/material';
import { theme } from '../../../data/theme';
import { doc, updateDoc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { dataBase } from '../../../firebase/database';
import NewTooltip from '../../../components/Tooltip';
import isEmptyObj from '../../../functions/isEmptyObj';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import PanelSolar from '../../../images/icons/PainelSolar.png';
import axios from 'axios';
import Loading from '../../../components/Loading';
import removeNullProperties from '../../../functions/removeNullProperties';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import AudioRecorder from '../../../components/AudioRecorder/Index';
import Estimate from '../Components/Estimate';
import parse from 'html-react-parser';
import ButtonCB from '../../../components/Button';
import Consumption from '../Components/Consumption';
import CustomAudioPlayerFixed from '../../../components/AudioPlayerFixed/Index';
import { toast } from '../../../components/Toast';
import { generateId } from '../../../functions/generateId';
import Visit from '../Components/Visit';
import { handleDownload } from '../../../functions/handleDownload';

const initialState = {
  janeiro: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  fevereiro: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  marco: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  abril: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  maio: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  junho: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  julho: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  agosto: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  setembro: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  outubro: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  novembro: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  },
  dezembro: {
    geracao: 0,
    consumo_atual: 0,
    consumo_adicionado: 0
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'set_field': {
      const { field, value } = action;
      if (field.includes('.')) {
        const [mainField, nestedField] = field.split('.');
        return {
          ...state,
          [mainField]: {
            ...state[mainField],
            [nestedField]: value.replace(',', '.'),
          },
        };
      }
      return { ...state, [field]: value };
    }
    default:
      return state;
  }
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Sheet = ({ userRef }) => {
  const navigate = useNavigate();
  const [observacao, setObservacao] = useState('');
  const [kit, setKit] = useState('');
  const [generation, setGeneration] = useState('0');
  const [customer, setCustomer] = useState({});
  const [sheet, setSheet] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cloneSheet, setCloneSheet] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [name, setName] = useState('');
  let { idSheet, index } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  // const [showExtra, setShowExtra] = useState(false);
  const [googleEarth, setGoogleEarth] = useState({
    file: '',
    complete: null
  });
  const [audio, setAudio] = useState(null);
  const [audioExclusive, setAudioExclusive] = useState(null);
  const [open, setOpen] = useState({
    history: false
  });
  const [proposal, setProposal] = useState(null);
  const inputRef = useRef(null);
  const storage = getStorage();
  const [TabsValue, setTabsValue] = useState(0);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  // useEffect(() => {
  //   const getVisit = async () => {
  //     onSnapshot(doc(dataBase, "Clientes", idSheet), (doc) => {
  //       setSheet({ ...doc.data(), id: doc.id });
  //       setCustomer({ ...doc.data(), id: doc.id });
  //     });
  //   };
  //   getVisit();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleDataChange = useCallback((name, value) => {
  //   dispatch({ type: 'set_field', field: name, value });
  // }, [dispatch]);

  useEffect(() => {
    const getVisit = async () => {
      try {
        // Obtém o documento específico com o id
        const docRef = doc(dataBase, "Clientes", idSheet);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Atualiza os estados com os dados retornados
          setCustomer({ ...docSnap.data(), id: docSnap.id });
        } else {
          console.log("Documento não encontrado");
        }
      } catch (error) {
        console.error("Erro ao buscar o documento:", error);
      }
    };

    getVisit();

  }, [navigate, idSheet, refresh]);

  const meses = useMemo(() => [
    'janeiro', 'fevereiro', 'marco', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
  ], []);

  const handleDataChange = useCallback((name, value) => {
    dispatch({ type: 'set_field', field: name, value });
  }, [dispatch]);

  useEffect(() => {
    if (customer) {
      if (customer.planilha && !cloneSheet) {
        setKit(customer?.planilha[index]?.kit);
        setGeneration(customer?.planilha[index]?.geracao ?? '0');
        setSheet(customer?.planilha[index]);
        setLoading(false);
        setObservacao(customer?.planilha[index]?.observacao ?? '');
        setEquipments(customer?.planilha[index]?.equipamentos ?? []);
        setName(customer?.planilha[index]?.nome || customer?.nome);
        meses.forEach((m) => {
          handleDataChange(`${m}.geracao`, String(customer?.planilha[index][m]?.geracao));
          handleDataChange(`${m}.consumo_atual`, String(customer?.planilha[index][m]?.consumo_atual));
          handleDataChange(`${m}.consumo_adicionado`, String(customer?.planilha[index][m]?.consumo_adicionado));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, meses, index, cloneSheet]);

  useEffect(() => {
    if (!open.consumption) {
      const monthTotals = {};
      console.log(equipments);
      if (equipments.length > 0) {
        // Itera sobre cada equipamento
        equipments.forEach(item => {
          // Itera sobre os meses do equipamento
          item.meses.forEach(mes => {
            const mesFormatado = mes.toLowerCase() === 'março' ? 'marco' : mes.toLowerCase();

            // Se o mês já existe no objeto monthTotals, adicione ao total existente
            if (monthTotals[mesFormatado]) {
              monthTotals[mesFormatado] += item.kwh_total;
            } else {
              // Se não, inicie o total com o valor kwh_total do item atual
              monthTotals[mesFormatado] = item.kwh_total;
            }
          });
        });

        // Chama handleDataChange para cada mês com o total acumulado
        meses.forEach(m => {
          handleDataChange(`${m}.consumo_adicionado`, String((monthTotals[m] || 0).toFixed(2)));
        });
      } else {
        meses.forEach((m) => {
          handleDataChange(`${m}.consumo_adicionado`, '0');
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipments, open.consumption]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    // focusedInputRef.current = e.target; // Armazena o input focado
    dispatch({ type: 'set_field', field: name, value });
  }, [dispatch]);

  const SumValue = useCallback((type) => {
    let sum = 0;
    meses.forEach(value => {
      const geracao = state[value]?.geracao ? Number(state[value]?.geracao.replace(',', '.')) : 0;
      const consumoAtual = state[value]?.consumo_atual ? Number(state[value]?.consumo_atual) : 0;
      const consumoAdicionado = state[value]?.consumo_adicionado ? Number(state[value]?.consumo_adicionado) : 0;

      if (type === 'CSC') {
        sum += geracao - consumoAtual;
      } else if (type === 'CCC') {
        sum += geracao - (consumoAtual + consumoAdicionado);
      } else if (type === 'MSC') {
        sum += consumoAtual / 12;
      } else if (type === 'MCC') {
        sum += (consumoAtual + consumoAdicionado) / 12;
      }
    });
    return sum;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const onSubmit = async (e) => {
    e.preventDefault();

    // if (cloneSheet && showExtra) {
    //   if (!audio || !googleEarth.complete || !proposal || !audioExclusive) {
    //     const msg = !audio || !audioExclusive ? 'Envie a mensagem de voz para prosseguir!' : !googleEarth.complete ? 'Envie a imagem via satelite para prosseguir!' : !proposal ? 'Envie a proposta para prosseguir!' : '';
    //     return await Swal.fire({
    //       title: 'Atenção',
    //       html: msg,
    //       icon: "warning",
    //       showConfirmButton: true,
    //       showCloseButton: true,
    //       confirmButtonColor: "#111",
    //     });
    //   }
    // }

    const title = cloneSheet ? 'Clonagem da Planilha' : 'Planilha de Crédito';
    const msg = cloneSheet ? 'Planilha Clonada' : 'Planilha';
    try {
      Swal.fire({
        title: title,
        html: `Você deseja salvar a <b>${msg}</b>?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await sendData();
        }
      })
    } catch (error) {
      console.error('Ocorreu um erro' + error)
    }
  }

  const handleUpload = async () => {

    if (!audio && !googleEarth.complete && !audioExclusive) {
      return;
    }


    try {
      // Upload do áudio
      const filesRef = {};

      if (audio) {
        const audioRef = ref(storage, `Clientes/${customer.id}/Mensagem-${Date.now()}.webm`);
        const audioSnapshot = await uploadBytes(audioRef, audio.complete);
        const audioURL = await getDownloadURL(audioSnapshot.ref);
        // console.log('Áudio enviado com sucesso! URL:', audioURL);
        filesRef.mensagemVoz = {
          url: audioURL,
          ref: audioSnapshot.ref.fullPath
        };
      }

      if (audioExclusive) {
        const audioRef = ref(storage, `Clientes/${customer.id}/Mensagem-${Date.now()}.webm`);
        const audioSnapshot = await uploadBytes(audioRef, audioExclusive.complete);
        const audioURL = await getDownloadURL(audioSnapshot.ref);
        // console.log('Áudio enviado com sucesso! URL:', audioURL);
        filesRef.audioExclusivo = {
          url: audioURL,
          ref: audioSnapshot.ref.fullPath
        };
      }

      if (googleEarth.complete) {
        const photoRef = ref(storage, `Clientes/${customer.id}/GoogleEarth-${Date.now()}.${googleEarth.complete.type.split('/')[1]}`);
        // Upload da foto
        const photoSnapshot = await uploadBytes(photoRef, googleEarth.complete);
        const photoURL = await getDownloadURL(photoSnapshot.ref);

        // console.log('Foto enviada com sucesso! URL:', photoURL);
        filesRef.googleEarth = {
          url: photoURL,
          ref: photoSnapshot.ref.fullPath
        };

      }


      return filesRef;

    } catch (error) {
      console.error('Erro ao enviar arquivos:', error);
    }
  };

  const sendData = async () => {
    try {
      let motivo = '';

      if (!checkPercentageRecommended() && cloneSheet) {
        const result = await Swal.fire({
          title: 'Planilha de Crédito',
          html: `A Gordura com Consumo Adicionado está fora do padrão <b>(5 a 12%)</b>. Explique o motivo:`,
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#27d327",
          confirmButtonText: "Continuar",
          cancelButtonText: "Fechar",
          input: 'textarea',
          inputValidator: (value) => {
            if (!value) {
              return 'Deixe uma explicação sobre a Gordura';
            }
          },
        });
        motivo = result.value;

        if (result.isDismissed) {
          return null;
        } else if (result.isConfirmed) {
          setLoading(true);
        }
      }

      setLoading(true);

      const gordura = (((SumValue('CCC') / 12) / SumValue('MCC')) * 100).toFixed(2);

      const extra = cloneSheet ? null : (!checkPercentage().check && !audio && !googleEarth?.complete && !audioExclusive) ? null : await handleUpload();
      const extraRef = cloneSheet ? null : audio || googleEarth?.complete || audioExclusive ? extra : null;

      const newExtra = {
        ...sheet.extra,
        ...extraRef
      }

      const proposalRef = proposal || sheet.orcamento;

      const contentSheet = {
        ...state,
        kit: kit,
        nome: name,
        motivo: cloneSheet ? motivo : sheet.motivo,
        gordura: gordura,
        geracao: generation,
        observacao: observacao,
        extra: cloneSheet ?
          extra ? extra : null
          : newExtra || null,
        endereco: sheet.endereco ?? null,
        lat: sheet.lat ?? null,
        lng: sheet.lng ?? null,
        feedback: cloneSheet ? null : sheet.feedback ?? null,
        sistemaRecomendado: cloneSheet ? false : sheet.sistemaRecomendado ?? false,
        orcamento: cloneSheet ? null : proposalRef,
        reenviar_orcamento: cloneSheet ? false : sheet?.sistemaRecomendado || false,
        equipamentos: equipments,
        responsavel: {
          nome: userRef?.nome,
          id: userRef?.id
        },
        historico: cloneSheet ? [
          {
            data: moment().format('DD/MM/YYYY HH:mm'),
            text: `Planilha clonada a partir da planilha de <a href=https://corpbrasil.app/credito/${idSheet}/${index} rel="noreferrer" target="_blank">${sheet.kit} kWp</a> pelo(a) ${userRef && userRef.nome}.`
          }] : sheet.historico ? [
            ...sheet.historico,
            {
              data: moment().format('DD/MM/YYYY HH:mm'),
              text: `Planilha alterada pelo(a) ${userRef?.nome}.`
            }
          ] : [
          {
            data: moment().format('DD/MM/YYYY HH:mm'),
            text: `Planilha alterada pelo(a) ${userRef?.nome}.`
          }
        ]
      };

      const updatedSheets = [...customer.planilha];
      if (cloneSheet) {
        updatedSheets.push(contentSheet);
      } else {
        updatedSheets[index] = {
          ...sheet,
          ...contentSheet
        };
      }

      const newHistoricoEntry = cloneSheet ? {
        data: moment().format('DD/MM/YYYY - HH:mm'),
        evento: 'Planilha Clonada',
        text: `<a href=https://corpbrasil.app/credito/${idSheet}/${index} rel="noreferrer" target="_blank">${sheet.kit} kWp</a> com geração média de ${sheet.geracao} kWh > <a href=https://corpbrasil.app/credito/${idSheet}/${updatedSheets.length - 1} rel="noreferrer" target="_blank">${kit} kWp</a> com geração média de ${generation} kWh.`,
        usuario_id: userRef?.id,
        usuario_nome: userRef?.nome
      } : null;

      const updatedHistorico = [...customer.historico, newHistoricoEntry].filter(entry => entry !== null);

      await updateDoc(doc(dataBase, "Clientes", idSheet), {
        planilha: removeNullProperties(updatedSheets),
        data: moment().format('DD MMM YYYY - HH:mm'),
        historico: updatedHistorico
      });

      const consultoraRef = customer.assistente ?
        customer.consultora_uid === userRef?.id ?
          customer.consultora : `${customer.consultora} (${userRef?.nome})` :
        customer.consultora;

      if (!checkPercentageRecommended() && cloneSheet) {
        await axios.post('https://backend.botconversa.com.br/api/v1/webhooks-automation/catch/45898/IsDRdPcw5BQ9/', {
          nome: customer.nome,
          kit: kit,
          gordura: gordura,
          consultora: consultoraRef,
          id: idSheet,
          index: updatedSheets.length - 1,
          telefone: '5515991573088',
          motivo: motivo,
          ceo: 'Thales',
        })
      }

      setLoading(false);

      await Swal.fire({
        title: 'CORPBRASIL',
        html: `A Planilha de Créditos do(a) <b>${customer.nome}</b> foi salva com sucesso.`,
        icon: "success",
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#111",
      });

      // Redirecionar se cloneSheet for verdadeiro
      if (cloneSheet) {
        window.location.href = `/credito/${idSheet}/${updatedSheets.length - 1}`
        // setCloneSheet(false);
        // setRefresh(true);
        // navigate(`/credito/${idSheet}/${updatedSheets.length - 1}`);
        // setShowExtra(false);
        // setEquipments([]);
        // setGoogleEarth({
        //   file: '',
        //   complete: null
        // });
        // setAudio(null);
        // setAudioExclusive(null);
        // setProposal(null);
      }

      setRefresh(true);

    } catch (error) {
      console.log('Erro ao salvar planilha:', error);
      setLoading(false);
      await errorMsg(error);
    }

    setLoading(false);
  };

  const hasSpecificPropertiesChanged = (original, modified) => {
    const propertiesToCheck = [
      "janeiro", "fevereiro", "marco", "abril", "maio",
      "junho", "julho", "agosto", "setembro", "outubro",
      "novembro", "dezembro", "kit", 'geracao', "extra", "orcamento", "nome"
    ];

    for (let property of propertiesToCheck) {
      if (!original.hasOwnProperty(property) || !modified.hasOwnProperty(property)) {
        return true;
      }
      if (hasObjectChanged(original[property], modified[property])) {
        return true;
      }
    }

    return false;
  }

  const hasObjectChanged = (original, modified) => {
    // Verifica se os dois argumentos são objetos e não são null
    if (typeof original !== 'object' || typeof modified !== 'object' || original === null || modified === null) {
      return original !== modified;
    }

    // Verifica se têm o mesmo conjunto de chaves
    const originalKeys = Object.keys(original);
    const modifiedKeys = Object.keys(modified);

    if (originalKeys.length !== modifiedKeys.length) {
      return true;
    }

    // Verifica recursivamente as propriedades
    for (let key of originalKeys) {
      if (!modified.hasOwnProperty(key) || hasObjectChanged(original[key], modified[key])) {
        return true;
      }
    }

    return false;
  }

  const checkChanges = () => {
    if (!sheet || !customer.planilha || !customer.planilha[index] || !generation || generation === '0' || name.length === 0) {
      return false;
    }

    if (cloneSheet) {
      return true
    }

    return hasSpecificPropertiesChanged(sheet,
      {
        ...state,
        kit: kit,
        nome: name,
        geracao: generation,
        extra: {
          mensagemVoz: sheet?.extra?.mensagemVoz || audio,
          googleEarth: sheet?.extra?.googleEarth || googleEarth?.complete,
          audioExclusivo: sheet?.extra?.audioExclusivo || audioExclusive
        },
        orcamento: proposal || sheet?.orcamento
      });
  }

  const checkSystem = () => {
    if (!sheet || !customer.planilha || !customer.planilha[index]) {
      return false;
    } else {
      if (sheet.sistemaRecomendado && !sheet?.reenviar_orcamento) {
        return true;
      }
    }
    return false;
  }

  const checkVisit = () => {
    if (!sheet || !customer.planilha || !customer.planilha[index]) {
      return false;
    } else {
      if (sheet.visita_tecnica) {
        return true;
      }
    }
    return false;
  }

  const checkExtra = () => {
    if (!sheet || !customer.planilha || !customer.planilha[index]) {
      return false;
    } else {
      if (sheet?.extra?.mensagemVoz?.url && sheet?.extra?.googleEarth?.url && sheet?.orcamento?.inversor) {
        return true;
      }
    }
    return false;
  }

  // const createDateWithTime = (timeString) => { // Cria a data com o horário fornecido pela propriedade 'horario_orcamento', caso não tenha valor na propriedade, retorna o horario atual
  //   const today = moment();

  //   // Combinar a data atual com o horário fornecido
  //   if (!timeString) {
  //     return today;
  //   } else {
  //     const dateWithTime = today.startOf('day').add(timeString, 'hours');
  //     const dateCheck = dateWithTime > moment() ? dateWithTime : today.add(1, 'days');
  //     return dateCheck;
  //   }
  // }

  // const differenceInSeconds = (date1, date2) => { // Mostra a diferença entre os horarios para disparar o orçamento
  //   // Criar dois objetos moment com as datas fornecidas
  //   const momentDate1 = moment(date1);
  //   const momentDate2 = moment(date2);

  //   // Calcular a diferença em segundos
  //   const difference = momentDate2.diff(momentDate1, 'seconds');

  //   return difference;
  // }

  const sendSystem = async (type) => {
    try {
      console.log(type);
      const result = await Swal.fire({
        title: 'Orçamento',
        html: `Você deseja enviar o <b>${type}</b> para o Cliente via WhatsApp?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result.isConfirmed) {
        if (!customer.proposta || customer.proposta.length === 0) return await Swal.fire({
          title: 'Atenção',
          html: `Não é possivel enviar o orçamento, pois <b>não foi encontrado nenhum orçamento solicitado</b>.`,
          icon: "error",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        });

        const proposals = customer.proposta.reduce((acc, data) => {
          const key = data.nome.replace(/\s+/g, '_');
          // Armazena um objeto stringified como valor, mas exibe apenas o nome
          acc[key] = JSON.stringify({ nome: data.nome, createAt: data.createAt });
          return acc;
        }, {});

        const { value: estimate } = await Swal.fire({
          title: "Orçamento",
          html: `Antes de enviar para o cliente, selecione o Orçamento solicitado pelo <b>Vendedor(a).</b>`,
          input: "select",
          inputOptions: Object.keys(proposals).reduce((acc, key) => {
            // Exibe apenas o nome na seleção
            acc[key] = JSON.parse(proposals[key]).nome;
            return acc;
          }, {}),
          showCloseButton: true,
          inputPlaceholder: "Selecione o Orçamento",
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          confirmButtonColor: "#0eb05f",
          cancelButtonText: 'Fechar',
          inputValidator: (value) => {
            if (!value) {
              return "Selecione um Orçamento";
            }
          }
        });
        if (estimate) {
          const selectedProposal = JSON.parse(proposals[estimate]);

          // Encontra o índice do objeto correspondente
          const estimateRef = customer.proposta.find(data =>
            data.createAt.seconds === selectedProposal.createAt.seconds &&
            data.createAt.nanoseconds === selectedProposal.createAt.nanoseconds
          );

          const estimateRefIndex = customer.proposta.findIndex(data =>
            data.createAt.seconds === selectedProposal.createAt.seconds &&
            data.createAt.nanoseconds === selectedProposal.createAt.nanoseconds
          );

          if (!estimateRef.id) {
            estimateRef.id = generateId(estimateRef.nome) // Atribue o id com o nome do orçamento
          }

          const newEstimates = customer.proposta;
          newEstimates[estimateRefIndex] = estimateRef;

          // Se o objeto foi encontrado (index >= 0)
          // if (newindex >= 0) {
          // Cria uma cópia do objeto encontrado
          // const updatedProposal = { ...customer.proposta[index] };

          // // Altera a propriedade desejada
          // updatedProposal.planilha = {
          //   kit: sheet.kit,
          //   index: index,
          //   kwh: sheet.geracao
          // };

          // console.log(updatedProposal);

          // Substitui o objeto na lista original
          // const updatedPropostas = [
          //   ...customer.proposta.slice(0, index),   // Mantém os objetos antes do encontrado
          //   updatedProposal,                        // Insere o objeto atualizado
          //   ...customer.proposta.slice(index + 1)   // Mantém os objetos após o encontrado
          // ];

          // Atualiza o array original com a nova lista
          // console.log(updatedPropostas)
          // customer.proposta = updatedPropostas;
          //}

          // const budgetEstimate = createDateWithTime(customer.horario_orcamento || '') // Calcula diferença de horas
          // const differenceDate = differenceInSeconds(moment(), budgetEstimate)

          setLoading(true);

          await axios.post('https://n8n.corpbrasil.cloud/webhook/51acb5cb-127c-48d1-b128-5d7b00da2767', {
            nome: customer.nome,
            consultora: customer.consultora,
            cidade: customer.cidade,
            telefone: customer.telefone,
            chave: type,
            kit: kit,
            representante: customer.assistente_nome || '',
            orcamento: sheet.orcamento,
            id: `${idSheet}-${index}`,
            index: index,
            geracao: sheet.geracao
          })

          const sheets = [];
          sheets.push(...customer.planilha);
          sheets[index] = {
            ...sheet,
            sistemaRecomendado: true,
            orcamento_atualizado: type === 'Orçamento' ? false : true,
            orcamento_solicitado: estimate,
            orcamento_id: estimateRef.id,
            orcamento_status: 'Enviado',
            historico: [
              ...sheet.historico,
              {
                data: moment().format('DD/MM/YYYY HH:mm'),
                text: `O ${type} foi enviado para o cliente via WhatsApp pelo(a) ${userRef?.nome}.`
              }]
          };

          await updateDoc(doc(dataBase, 'Clientes', idSheet), {
            planilha: sheets,
            proposta: newEstimates,
            historico: [
              ...customer.historico,
              {
                data: moment().format('DD/MM/YYYY - HH:mm'),
                evento: 'Orçamento Enviado ✅',
                text: `Enviou o Orçamento de <a href=https://corpbrasil.app/credito/${idSheet}/${index} rel="noreferrer" target="_blank">${sheet.kit} kWp</a> com geração de <b>${sheet.geracao} kWh</b> para o cliente.`,
                usuario_id: userRef?.id,
                usuario_nome: userRef?.nome
              }
            ]
          });

          await setDoc(doc(dataBase, 'Fila', `${customer.id}-${index}`), {
            id: `${idSheet}-${index}`,
            nome: customer.nome,
            consultora: customer.consultora,
            consultora_uid: customer.consultora_uid,
            cidade: customer.cidade,
            telefone: customer.telefone,
            chave: type,
            kit: kit,
            orcamento: sheet.orcamento,
            index: index,
            representante: customer.assistente_nome || userRef?.nome,
            representante_uid: customer.assistente || userRef?.id,
            tipo: 'Orçamento',
            espera: '0',
            createAt: new Date(),
            horario_materiais: customer.horario_materiais || new Date(),
            geracao: sheet.geracao,
            orcamento_nome: estimate,
            cliente_id: customer.id
          })

          setLoading(false);
          await Swal.fire({
            title: 'Orçamento',
            html: `O <b>Orçamento</b> foi enviado com sucesso para o cliente.`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: "#111",
          })
          setRefresh(true);
        }
      }

    } catch (error) {
      console.error(error);
      setLoading(false);
      await errorMsg(error);
    }
  }

  const errorMsg = (error) => {
    return Swal.fire({
      title: 'Erro 404',
      html: `Ocorreu um erro ao enviar o orçamento: <br /> <b>${error}</b>`,
      icon: "error",
      showCloseButton: true,
      confirmButtonColor: "#000",
      confirmButtonText: "Fechar",
    });
  }

  const sendVisit = async () => {
    try {
      const result = await Swal.fire({
        title: 'Visita Técnica',
        html: `Você deseja enviar a <b>Visita Técnica</b> para o Cliente via WhatsApp?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result.isConfirmed) {
        // if (!customer.proposta || customer.proposta.length === 0) return await Swal.fire({
        //   title: 'Atenção',
        //   html: `Não é possivel enviar o orçamento, pois <b>não foi encontrado nenhum orçamento solicitado</b>.`,
        //   icon: "error",
        //   showConfirmButton: true,
        //   showCloseButton: true,
        //   confirmButtonColor: "#111",
        // });

        setLoading(true);

        const docRef = doc(dataBase, "Membros", sheet?.visita_tecnica?.responsavel_id);
        const docSnap = await getDoc(docRef);

        let analistaRef = {};
        if (docSnap.exists()) {
          // Atualiza os estados com os dados retornados
          analistaRef = { ...docSnap.data(), id: docSnap.id };
        }

        await axios.post('https://n8n.corpbrasil.cloud/webhook/a213344f-021f-4220-ae1d-f710cb332151', {
          nome: customer.nome,
          consultora: customer.consultora,
          cidade: customer.cidade,
          telefone: customer.telefone,
          id_agenda: customer.id,
          chave: checkExtra() ? 'Visita Técnica' : 'Página Visita Técnica', // Checa se a aba 'Orçamento Enviado' possui as etapas preenchidas para enviar outro tipo de visita
          kit: kit,
          analista: analistaRef?.nome_ref || userRef?.nome_ref,
          representante: customer.assistente_nome || '',
          id: `${idSheet}-${index}`,
          link: checkExtra() ? 'http://corpbrasil.app/orcamento/' : 'http://corpbrasil.app/visita-tecnica/',
          index: index,
        })

        const sheets = [];
        sheets.push(...customer.planilha);
        sheets[index] = {
          ...sheet,
          sistemaRecomendado: checkSystem(),
          visita_tecnica: {
            ...sheet.visita_tecnica,
            enviado: true
          },
          historico: [
            ...sheet.historico,
            {
              data: moment().format('DD/MM/YYYY HH:mm'),
              text: `A Visita Técnica foi enviada para o cliente via WhatsApp pelo(a) ${userRef?.nome}.`
            }]
        };

        await updateDoc(doc(dataBase, 'Clientes', idSheet), {
          planilha: sheets,
        });

        setLoading(false);
        await Swal.fire({
          title: 'Visita Técnica',
          html: `A <b>Visita Técnica</b> foi enviado com sucesso para o cliente.`,
          icon: "success",
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#111",
        })
        // window.location.reload();
        setRefresh(true);
      }

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const deleteFile = async (type, file) => {
    const msgType = type === 'Imagem' ? 'a <b>Imagem</b>' : 'o <b>Áudio</b>';
    try {
      const result = await Swal.fire({
        title: 'Atenção',
        html: `Você deseja excluir ${msgType}?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result.isConfirmed) {
        const filesRef = sheet.extra[file];
        console.log(filesRef);
        let updatedSheets = [...customer.planilha];
        let newSheet = sheet;
        newSheet.extra = {
          ...sheet.extra,
          [file]: null
        };
        updatedSheets[index] = newSheet;

        console.log(updatedSheets);
        await deleteFiles(filesRef);
        await updateDoc(doc(dataBase, 'Clientes', customer.id), {
          planilha: updatedSheets
        })

        toast({ icon: 'success', text: 'Arquivo excluido com sucesso!' });
      }

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const deleteFiles = async (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        deleteFiles(obj[key]);
      } else if (key === 'ref') {
        const refObj = obj[key];
        const fileRef = ref(storage, refObj);

        try {
          await deleteObject(fileRef);
          console.log(`File deleted: ${refObj}`);
        } catch (error) {
          console.error(`Error deleting file: ${refObj}`, error);

        }
      }
    }
  };

  const handleClone = (check) => {

    if (check) {
      setCloneSheet(false);
      setAudio(null);
      setAudioExclusive(null);
      setGoogleEarth(null);
      setProposal(null);
    } else {
      setCloneSheet(true);
    }

    setTabsValue(0);
  }

  // const handleCheck = (checked) => {

  //   if (!checked) {
  //     setGoogleEarth({ file: '', complete: null });
  //     setAudio(null);
  //     setAudioExclusive(null);
  //     setProposal(null);
  //   }

  //   setShowExtra(checked);
  // }

  const sendPhoto = (e) => {
    const image = e.target.files[0];
    return { file: URL.createObjectURL(image), complete: image };
  }

  const sendAudio = (e, type) => {

    const audio = e.target.files[0];
    const audioData = { file: URL.createObjectURL(audio), complete: audio };

    const typeAudio = {
      'audio': setAudio,
      'audioExclusive': setAudioExclusive,
    };

    if (typeAudio[type]) {
      typeAudio[type](audioData);
    } else {
      console.error('Tipo de áudio desconhecido:', type);
    }

    // setAudio({ file: URL.createObjectURL(audio), complete: audio });
  }

  const checkPercentage = () => {

    const gorduraConsumoAdicionado = (((SumValue('CCC') / 12) / SumValue('MCC')) * 100).toFixed(2);

    if (gorduraConsumoAdicionado >= 1 && gorduraConsumoAdicionado <= 5) {
      return {
        color: '#000',
        check: true,
        text: 'Ideal'
      }
    } else if (gorduraConsumoAdicionado > 5) {
      return {
        color: '#05a937',
        check: true,
        text: 'Recomendado'
      }
    } else {
      return {
        color: '#ccc',
        check: false,
        text: ''
      }
    }
  };

  const checkPercentageRecommended = () => {
    const gorduraConsumoAdicionado = (((SumValue('CCC') / 12) / SumValue('MCC')) * 100).toFixed(2);

    if (gorduraConsumoAdicionado < 5 || gorduraConsumoAdicionado > 12) {
      return false
    } else {
      return true;
    }
  }

  const monthPermission = (month) => {
    if (month === 'maio' || month === 'junho' || month === 'julho' || month === 'agosto') {
      return true
    }

    return false;
  }
  const cancelSend = async () => {
    try {
      const result = await Swal.fire({
        title: 'Atenção',
        html: `Você deseja reiniciar o envio de orçamento?`,
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#0eb05f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result.isConfirmed) {

        let updatedSheets = [...customer.planilha];
        let newSheet = sheet;
        newSheet.sistemaRecomendado = false;
        updatedSheets[index] = newSheet;


        console.log(updatedSheets);
        await deleteDoc(doc(dataBase, "Fila", `${idSheet}-${index}`));
        await updateDoc(doc(dataBase, 'Clientes', customer.id), {
          planilha: updatedSheets
        })

        toast({ icon: 'success', text: 'Envio de Orçamento Reiniciado com sucesso!' });
      }

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <>
      <Loading view={loading || isEmptyObj(sheet)} />
      <div className={style.sheets_page}>
        <div className={style.sheets_header}>
          <div className={style.sheets_logo}>
            <img src={Logo} alt='' />
          </div>
          <div className={style.title}>
            <h1>Planilha de Créditos</h1>
          </div>
          <ul>
            <li>
              <b>Nome: </b>
              <input type="text" onChange={(e) => setName(e.target.value)} value={name} required />
            </li>
            <li>
              <Box textAlign={'start!important'} width={`${kit?.length * 10 + 90}px!important`} marginRight={'1rem'}>
                <b>Kit: </b>
                <input
                  type="text"
                  style={{ width: `${kit?.length * 10 + 10}px` }}
                  value={kit ?? ''}
                  onChange={(e) => setKit(e.target.value)}
                  autoComplete='off' /> kWp
              </Box>
              <NewTooltip title={generation === '0' || !generation ? 'Atenção: Geração Média está zerado.' : ''} open={generation === '0' || !generation}>
                <Box textAlign={'start!important'} width={`${generation?.length * 10 + 170}px!important`}>
                  <b>Geração Média: </b>
                  <input
                    type="text"
                    style={{ width: `${generation?.length * 10 + 10}px` }}
                    value={generation ?? ''}
                    onChange={(e) => setGeneration(e.target.value)}
                    autoComplete='off' /> kWh
                </Box>
              </NewTooltip>
            </li>
          </ul>
        </div>
        <div className={style.sheets_container}>
          <div className={style.sheets_result}>
            <form onSubmit={onSubmit}>
              <TableContainer className={style.sheets} >
                <Table size="small" aria-label="sticky table">
                  <TableHead>
                    <TableRow className={style.sheets_table_header}>
                      <TableCell align="center"></TableCell>
                      {meses.map((m, index) => (
                        <TableCell key={index} sx={{ textTransform: 'capitalize' }} align="center">{m.substring(0, 3)}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className={style.table_row}>
                      <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}><b>Geração</b></TableCell>
                      {meses.map((m, index) => (
                        <TableCell className={style.table_td} key={index} sx={{ padding: '0' }}>
                          <input
                            // ref={(el) => (inputRefs.current[`${m}.geracao`] = el)}
                            type="number"
                            label="Geração Atual"
                            name={`${m}.geracao`}
                            value={state[m]?.geracao ? state[m]?.geracao.replace('.', ',') : ''}
                            autoComplete="off"
                            onChange={handleChange}
                            required
                          // disabled={!cloneSheet}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow className={style.table_row}>
                      <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}>Consumo Atual</TableCell>
                      {meses.map((m, index) => (
                        <TableCell className={style.table_td} key={index}>
                          <input
                            type="number"
                            label="Geração Atual"
                            name={`${m}.consumo_atual`}
                            value={(state[m]?.consumo_atual && state[m]?.consumo_atual.replace('.', ',')) || ''}
                            autoComplete="off"
                            onChange={handleChange}
                            required
                          // disabled={!cloneSheet}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow className={style.table_row}>
                      <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}>
                        Consumo Adicionado
                      </TableCell>
                      <TableCell className={style.consumption} colSpan={12} sx={{ padding: '0!important', border: 'none!important' }}>
                        <Box className={style.consumption_box}>
                          <ButtonCB onClick={() => setOpen({ ...open, consumption: true })} startIcon={<ElectricBoltIcon />} disableElevation sx={{ padding: '0.3rem 0.7rem', fontWeight: 500 }} color='warning' variant='contained'>Consumo Adicionado</ButtonCB>
                        </Box>
                        {meses.map((m, index) => (
                          <TableCell
                            className={style.table_td}
                            key={index}
                            sx={monthPermission(m) ? { backgroundColor: "#2778c42b" } : { backgroundColor: '#f0e6c7' }}>
                            <input
                              type="text"
                              label="Consumo Adicionado"
                              name={`${m}.consumo_adicionado`}
                              value={(state[m]?.consumo_adicionado && state[m]?.consumo_adicionado.replace('.', ',')) || ''}
                              autoComplete="off"
                              onChange={handleChange}
                              required
                              disabled={!cloneSheet && !monthPermission(m)} />
                          </TableCell>
                        ))}
                      </TableCell>
                    </TableRow>
                    <TableRow className={style.table_row}>
                      <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}>Consumo Futuro Real</TableCell>
                      {meses.map((m, index) => (
                        <TableCell className={style.table_td} key={index}>{(Number(state[m]?.consumo_atual) + Number(state[m]?.consumo_adicionado)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("R$", "") || '0,00'}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow className={style.table_row}>
                      <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}>Créditos Sem Consumo Adicionado</TableCell>
                      {meses.map((m, index) => (
                        <TableCell className={style.table_td} key={index}>{(Number(state[m]?.geracao) - Number(state[m]?.consumo_atual)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("R$", "") || '0,00'}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow className={style.table_row}>
                      <TableCell className={style.table_td} sx={{ backgroundColor: '#34A853', color: "#fff" }}>Créditos Com Consumo Adicionado</TableCell>
                      {meses.map((m, index) => (
                        <TableCell className={style.table_td} key={index}>{(Number(state[m]?.geracao) - (Number(state[m]?.consumo_atual) + Number(state[m]?.consumo_adicionado))).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("R$", "") || '0,00'}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow sx={{ margin: '1rem 0' }}>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f3b100' }} colSpan={2}>Saldo Anual de Créditos Sem Consumo Adicionado kW/h</TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{SumValue('CSC').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("R$", "")}</TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f3b100' }} colSpan={2}>Saldo Anual de Créditos Com Consumo Adicionado kW/h</TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{SumValue('CCC').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("R$", "")}</TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#34A853', color: "#fff" }}>Média de Consumo Mensal SEM Adicionado</TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{SumValue('MSC').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("R$", "")}</TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0e6c7' }}>Média de Consumo Futuro Real COM Adicionado</TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid #ccc' }}>{SumValue('MCC').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("R$", "")}</TableCell>
                    </TableRow>
                    <TableRow sx={{ margin: '1rem 0' }}>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f3b100' }} colSpan={2}>Média Mensal de Créditos Sem Adicionados kW/h</TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{(SumValue('CSC') / 12).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("R$", "")}</TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f3b100' }} colSpan={2}>Média Mensal de Créditos Com Consumo Adicionado kW/h</TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{(SumValue('CCC') / 12).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("R$", "")}</TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#34A853', color: "#fff" }}>Gordura Média SEM Consumo Adicionado</TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {isNaN((SumValue('CSC') / 12) / SumValue('MSC')) || ((SumValue('CSC') / 12) / SumValue('MSC')) === Infinity ? 0 : (((SumValue('CSC') / 12) / SumValue('MSC')) * 100).toFixed(2)
                        } %
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#2778c4', color: "#fff" }}>Gordura Média COM Consumo Adicionado</TableCell>
                      <TableCell className={style.alert} sx={{ border: '1px solid #ccc' }}>
                        {!checkPercentage().check ?
                          <ReportProblemIcon
                            className={style.print_off} color='error' /> :
                          <NewTooltip title={checkPercentage().text} placement='top'>
                            <ThumbUpAltIcon
                              className={style.print_off} sx={{ fill: checkPercentage().color }} />
                          </NewTooltip>}
                        {(((SumValue('CCC') / 12) / SumValue('MCC')) * 100).toFixed(2)} %
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '1rem' }} colSpan={13}></TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: "#cccccc3d" }}>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', padding: '1.2rem', backgroundColor: '#ccc' }}>Observação:</TableCell>
                      <TableCell colSpan={12}>
                        <input
                          className={style.input_obs}
                          type='text'
                          value={observacao}
                          onChange={(e) => setObservacao(e.target.value)}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={`${style.tab_extra} ${style.print_off}`}>
                <ThemeProvider theme={theme}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={TabsValue} onChange={(e, newValue) => setTabsValue(newValue)} aria-label="Colaboradores" centered>
                      <Tab
                        icon={<HistoryIcon />}
                        sx={{ textTransform: 'capitalize' }}
                        label="Histórico"
                        {...a11yProps(1)} />
                      <Tab
                        icon={<Badge badgeContent={checkExtra() && sheet?.extra?.audioExclusivo?.url ? "✔" : 0} color='success'><img src={PanelSolar} width='25px' alt='' /></Badge>}
                        sx={{ textTransform: 'capitalize' }}
                        style={{ pointerEvents: "auto" }}
                        disabled={cloneSheet}
                        label={'Orçamento'}
                        {...a11yProps(2)} />
                      {/* <Tab
                        icon={<img src={PanelSolar} width='25px' alt='' />}
                        sx={{ textTransform: 'capitalize' }}
                        style={{ pointerEvents: "auto" }}
                        label={
                          <NewTooltip title={!checkPercentage().check || !cloneSheet ? 'Para liberar o sistema, a planilha precisa estar no modo clonagem e ter uma gordura acima de 1%' : ''} placement='bottom'>
                            <span>
                              Orçamento
                            </span>
                          </NewTooltip>
                        }
                        disabled={!checkPercentage().check || !cloneSheet}
                        {...a11yProps(3)} /> */}
                      <Tab
                        icon={<Badge badgeContent={checkVisit() ? "✔" : 0} color='success'><MdEngineering size={'1.4rem'} color='#1c256e' /></Badge>}
                        sx={{ textTransform: 'capitalize' }}
                        style={{ pointerEvents: "auto" }}
                        disabled={cloneSheet}
                        label={'Visita Técnica'}
                        {...a11yProps(3)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={TabsValue} index={0}>
                    <ul className={style.history}>
                      {sheet.historico ?
                        sheet.historico.slice().reverse().map((item, index) => (
                          <li key={index}>
                            <span><CalendarMonthIcon sx={{ fill: "#2e2e2e", width: '20px' }} /> {item.data}</span>
                            <p>{parse(item.text)}</p>
                          </li>
                        )) :
                        <h2>Nenhum histórico a ser exibido</h2>
                      }
                    </ul>
                  </CustomTabPanel>
                  <CustomTabPanel value={TabsValue} index={1}>
                    <div className={`${style.extra_container} ${style.print_off} ${cloneSheet ? style.lock_color : null}`}>
                      <img src={PainelSolar} alt='' />
                      <Box sx={{ display: 'flex', gap: '1.3rem', alignItems: 'center' }}>
                        <Badge badgeContent='Enviado' color='success'>
                          <h2>Orçamento</h2>
                        </Badge>
                      </Box>
                      <div className={`${style.extra_container_item} ${audio || sheet?.extra?.mensagemVoz?.url ? style.confirm_color : ''}`}>
                        <h3>Áudio 1: Entenda o seu Orçamento <b>(Obrigatório)</b>{audio || sheet?.extra?.mensagemVoz?.url ? ' ✅' : null}</h3>
                        <p>Envie um audio explicando como é feito o orçamento.</p>
                        {sheet?.extra?.mensagemVoz?.url ?
                          <Box sx={{ display: 'flex', width: '100%', gap: '0.5rem', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }} className={style.print_off}>
                            <CustomAudioPlayerFixed url={sheet?.extra?.mensagemVoz?.url} />
                            <NewTooltip title='Fazer Download' placement='top'>
                              <IconButton onClick={() => handleDownload({ fileUrl: sheet?.extra?.mensagemVoz?.url, fileName: 'Entenda o seu Orçamento' })}>
                                <DownloadRoundedIcon />
                              </IconButton>
                            </NewTooltip>
                            <NewTooltip title='Excluir Áudio' placement='top'>
                              <IconButton onClick={() => deleteFile('Áudio', 'mensagemVoz')} color='error'>
                                <DeleteRounded />
                              </IconButton>
                            </NewTooltip>
                          </Box> :
                          <div className={`${style.sheets_buttons} ${style.print_off}`}>
                            <AudioRecorder setAudio={setAudio} audio={audio} />
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                              <Button
                                disableElevation
                                sx={{ textTransform: 'capitalize' }}
                                component="label"
                                variant="contained"
                                onChange={(e) => sendAudio(e, 'audio')}
                                startIcon={<AudioFileIcon />}>
                                Enviar Áudio
                                <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                              </Button>
                            </Box>
                          </div>
                        }
                      </div>
                      <div className={`${style.extra_container_item} ${googleEarth?.complete || sheet?.extra?.googleEarth?.url ? style.confirm_color : ''}`}>
                        <h3>Imagem do Google Earth <b>(Obrigatório)</b> {googleEarth?.complete || sheet?.extra?.googleEarth?.url ? ' ✅' : null}</h3>
                        <p>Envie uma imagem via satélite do local de instalação que pode ser obtida através do <b>Google Earth</b>.</p>
                        {sheet?.extra?.googleEarth?.url ?
                          <Box sx={{ display: 'flex', width: '100%', gap: '0.5rem', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }} className={style.print_off}>
                            <img className={style.img_upload} src={sheet?.extra?.googleEarth.url || ''} alt='Imagem do Google Earth' />
                            <NewTooltip title='Excluir Imagem' placement='top'>
                              <IconButton onClick={() => deleteFile('Imagem', 'googleEarth')} color='error'>
                                <DeleteRounded />
                              </IconButton>
                            </NewTooltip>
                          </Box> :
                          <>{googleEarth?.file &&
                            <img className={style.img_upload} style={cloneSheet ? { opacity: 0.5 } : null} src={googleEarth?.file} alt='Imagem do Google Earth' />
                          }
                            <div className={`${style.sheets_buttons}`}>
                              <NewTooltip title='Clique aqui para abrir no endereço informado' placement='top'>
                                <IconButton disabled={cloneSheet} onClick={() => window.open(`https://earth.google.com/web/@${customer.endereco?.lat},${customer.endereco?.lng},0a,0d,35y,0h,45t,0r`, '_blank')}>
                                  <MapIcon />
                                </IconButton>
                              </NewTooltip>
                              <Button
                                disableElevation
                                sx={{ textTransform: 'capitalize' }}
                                component="label"
                                variant="contained"
                                disabled={cloneSheet}
                                onChange={(e) => setGoogleEarth(sendPhoto(e))}
                                startIcon={<AddToPhotosIcon />}>
                                Enviar Foto
                                <VisuallyHiddenInput multiple={false} type="file" accept="image/png,image/jpeg" />
                              </Button>
                              {googleEarth?.file.length > 0 ?
                                <NewTooltip title='Excluir Imagem' placement='top'>
                                  <IconButton disabled={cloneSheet} color='error' onClick={() => setGoogleEarth({ file: '', complete: null })}>
                                    <DeleteRounded />
                                  </IconButton>
                                </NewTooltip> : null
                              }
                            </div>
                          </>
                        }

                      </div>
                      <div className={`${style.extra_container_item} ${proposal || sheet.orcamento ? style.confirm_color : ''}`}>
                        <h3>Proposta <b>(Obrigatório)</b>{proposal || sheet.orcamento ? ' ✅' : null}</h3>
                        <p>Clique no botão abaixo para selecionar a proposta do kit recomendado para o cliente.</p>
                        <ThemeProvider theme={theme}>
                          <Button
                            disableElevation
                            sx={{ textTransform: 'capitalize' }}
                            variant='contained'
                            color='success'
                            onClick={() => setOpen({ ...open, estimate: true })}
                            startIcon={<RequestQuoteRoundedIcon />}>
                            Visualizar Proposta
                          </Button>
                        </ThemeProvider>
                      </div>
                      <div className={`${style.extra_container_item} ${audioExclusive || sheet?.extra?.audioExclusivo?.url ? style.confirm_color : ''}`}>
                        <h3>Áudio 2: Exclusivo <b>(Obrigatório)</b>{audioExclusive || sheet?.extra?.audioExclusivo?.url ? ' ✅' : null}</h3>
                        <p>Envie um audio explicando sobre os próximos passos.</p>
                        {sheet?.extra?.audioExclusivo?.url ?
                          <Box sx={{ display: 'flex', width: '100%', gap: '0.5rem', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }} className={style.print_off}>
                            <CustomAudioPlayerFixed url={sheet?.extra?.audioExclusivo?.url} />
                            <NewTooltip title='Fazer Download' placement='top'>
                              <IconButton onClick={() => handleDownload({ fileUrl: sheet?.extra?.audioExclusivo?.url, fileName: 'Exclusivo' })}>
                                <DownloadRoundedIcon />
                              </IconButton>
                            </NewTooltip> 
                            <NewTooltip title='Excluir Áudio' placement='top'>
                              <IconButton onClick={() => deleteFile('Áudio', 'audioExclusivo')} color='error'>
                                <DeleteRounded />
                              </IconButton>
                            </NewTooltip>
                          </Box> :
                          <div className={`${style.sheets_buttons} ${style.print_off}`}>
                            <AudioRecorder setAudio={setAudioExclusive} audio={audioExclusive} />
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                              <Button
                                disableElevation
                                sx={{ textTransform: 'capitalize' }}
                                component="label"
                                variant="contained"
                                onChange={(e) => sendAudio(e, 'audioExclusive')}
                                startIcon={<AudioFileIcon />}>
                                Enviar Áudio
                                <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                              </Button>
                            </Box>
                          </div>
                        }
                      </div>
                    </div>
                  </CustomTabPanel>
                  {/* <CustomTabPanel value={TabsValue} index={2}>
                    <NewTooltip title={!checkPercentage().check || !cloneSheet ? 'Para liberar o sistema, a planilha precisa estar no modo clonagem e ter uma gordura acima de 1%' : ''} placement='top' followCursor>
                      <div className={`${style.extra_container} ${style.print_off} ${!checkPercentage().check || !cloneSheet ? style.lock_color : null}`}>
                        <img src={PainelSolar} alt='' />
                        <Box sx={{ display: 'flex', gap: '1.3rem', alignItems: 'center' }}>
                          <Badge badgeContent='Novo' color='error'>
                            <h2>Orçamento</h2>
                          </Badge>
                          {!checkPercentage().check ?
                            <LockIcon sx={{ fill: '#ccc' }} /> : null}
                        </Box>
                        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                          <Checkbox disabled={!checkPercentage().check || !cloneSheet} checked={showExtra} onChange={() => handleCheck(!showExtra)} />
                          <h3>Ativar Orçamento</h3>
                        </Box>

                        {showExtra ?
                          <>
                            <div className={`${style.extra_container_item} ${audio && cloneSheet ? style.confirm_color : ''}`}>
                            <h3>Áudio 1: Entenda o seu Orçamento <b>(Obrigatório)</b>{audio && cloneSheet ? ' ✅' : null}</h3>
                            <p>Envie um audio explicando como é feito o orçamento.</p>
                              <div className={`${style.sheets_buttons} ${style.print_off}`}>
                                <AudioRecorder setAudio={setAudio} audio={audio} disabled={!checkPercentage().check || !cloneSheet} />
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                  <Button
                                    disableElevation
                                    sx={{ textTransform: 'capitalize' }}
                                    component="label"
                                    variant="contained"
                                    disabled={!checkPercentage().check || !cloneSheet}
                                    onChange={(e) => sendAudio(e, 'audio')}
                                    startIcon={<AudioFileIcon />}>
                                    Enviar Áudio
                                    <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                                  </Button>
                                </Box>
                              </div>
                            </div>
                            <div className={`${style.extra_container_item} ${googleEarth?.complete && cloneSheet ? style.confirm_color : ''}`}>
                              <h3>Imagem do Google Earth <b>(Obrigatório)</b>{googleEarth?.complete && cloneSheet ? ' ✅' : null}</h3>
                              <p>Envie uma imagem via satélite do local de instalação que pode ser obtida através do <b>Google Earth</b>.</p>
                              {googleEarth?.file.length > 0 ?
                                <img className={style.img_upload} style={!checkPercentage().check ? { opacity: 0.5 } : null} src={googleEarth?.file} alt='Imagem do Google Earth' /> : null
                              }
                              <div className={`${style.sheets_buttons}`}>
                                <NewTooltip title='Clique aqui para abrir no endereço informado' placement='top'>
                                  <IconButton disabled={!checkPercentage().check || !cloneSheet} onClick={() => window.open(`https://earth.google.com/web/@${customer.endereco?.lat},${customer.endereco?.lng},0a,0d,35y,0h,45t,0r`, '_blank')}>
                                    <MapIcon />
                                  </IconButton>
                                </NewTooltip>
                                <Button
                                  disableElevation
                                  sx={{ textTransform: 'capitalize' }}
                                  component="label"
                                  variant="contained"
                                  disabled={!checkPercentage().check || !cloneSheet}
                                  onChange={(e) => setGoogleEarth(sendPhoto(e))}
                                  startIcon={<AddToPhotosIcon />}>
                                  Enviar Foto
                                  <VisuallyHiddenInput multiple={false} type="file" accept="image/png,image/jpeg" />
                                </Button>
                                {googleEarth?.file.length > 0 ?
                                  <NewTooltip title='Excluir Imagem' placement='top'>
                                    <IconButton disabled={!checkPercentage().check || !cloneSheet} color='error' onClick={() => setGoogleEarth({ file: '', complete: null })}>
                                      <DeleteRounded />
                                    </IconButton>
                                  </NewTooltip> : null
                                }
                              </div>
                            </div>
                            <div className={`${style.extra_container_item} ${proposal && cloneSheet ? style.confirm_color : ''}`}>
                              <h3>Proposta <b>(Obrigatório)</b>{proposal && cloneSheet ? ' ✅' : null}</h3>
                              <p>Clique no botão abaixo para selecionar a proposta do kit recomendado para o cliente.</p>
                              <ThemeProvider theme={theme}>
                                <Button
                                  disableElevation
                                  sx={{ textTransform: 'capitalize' }}
                                  variant='contained'
                                  color='success'
                                  disabled={!checkPercentage().check || !cloneSheet}
                                  onClick={() => setOpen({ ...open, estimate: true })}
                                  startIcon={<RequestQuoteRoundedIcon />}>
                                  Enviar Proposta
                                </Button>
                              </ThemeProvider>
                            </div>
                            <div className={`${style.extra_container_item} ${audioExclusive && cloneSheet ? style.confirm_color : ''}`}>
                            <h3>Áudio 2: Exclusivo <b>(Obrigatório)</b>{audioExclusive && cloneSheet ? ' ✅' : null}</h3>
                            <p>Envie um audio explicando sobre os próximos passos.</p>
                              <div className={`${style.sheets_buttons} ${style.print_off}`}>
                                <AudioRecorder setAudio={setAudioExclusive} audio={audioExclusive} disabled={!checkPercentage().check || !cloneSheet} />
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                                  <Button
                                    disableElevation
                                    sx={{ textTransform: 'capitalize' }}
                                    component="label"
                                    variant="contained"
                                    disabled={!checkPercentage().check || !cloneSheet}
                                    onChange={(e) => sendAudio(e, 'audioExclusive')}
                                    startIcon={<AudioFileIcon />}>
                                    Enviar Áudio
                                    <VisuallyHiddenInput ref={inputRef} multiple={false} type="file" accept="audio/*" />
                                  </Button>
                                </Box>
                              </div>
                            </div></> : null
                        }
                      </div>
                    </NewTooltip>
                  </CustomTabPanel> */}
                  <CustomTabPanel value={TabsValue} index={2}>
                    <Visit customer={customer} sheet={sheet} index={index} userRef={userRef} setLoading={setLoading} />
                  </CustomTabPanel>
                </ThemeProvider>
              </div>
              {userRef?.cargo === 'Administrador' || userRef?.id === customer?.consultora_uid || userRef?.id === customer?.assistente || userRef?.nome === 'Pós-Venda' ?
                <div className={`${style.button_footer} ${style.print_off}`} style={{ zIndex: '110' }}>
                  <Estimate sheet={customer} cloneSheet={!cloneSheet} setProposalRef={setProposal} view={open.estimate} index={index} close={() => setOpen({ ...open, estimate: false })} />
                  <ThemeProvider theme={theme}>
                    {checkExtra() ?
                      !sheet?.reenviar_orcamento ?
                        <NewTooltip title={checkSystem() ? 'O Orçamento já foi enviado para o Cliente.' : !sheet?.extra?.mensagemVoz || !sheet.extra?.googleEarth ? 'Orçamento incompleto, verifique as etapas.' : 'Clique aqui enviar o Orçamento para o Cliente via WhatsApp'} placement='top'>
                          <span>
                            <Button
                              disableElevation
                              sx={{ textTransform: 'capitalize' }}
                              variant='contained'
                              color='success'
                              disabled={checkSystem() || (!sheet?.extra?.mensagemVoz || !sheet.extra?.googleEarth)}
                              onClick={() => sendSystem('Orçamento')}
                              startIcon={!sheet?.extra?.mensagemVoz || !sheet.extra?.googleEarth ? <WarningRoundedIcon sx={{ fill: '#F9C730' }} /> :
                                <img src={PanelSolar} alt="icon" style={{ width: 24, height: 24 }} />
                              }>
                              Enviar Orçamento
                            </Button>
                          </span>
                        </NewTooltip> :
                        <NewTooltip title={sheet?.orcamento_atualizado ? 'O Orçamento já foi reenviado para o Cliente.' : !sheet?.extra?.mensagemVoz || !sheet.extra?.googleEarth ? 'Orçamento incompleto, verifique as etapas.' : 'Clique aqui reenviar o Orçamento para o Cliente via WhatsApp'} placement='top'>
                          <span>
                            <Badge badgeContent="Atualizado" color='error'>
                              <ButtonCB
                                disableElevation
                                sx={{ padding: '6px 16px', fontSize: '14px' }}
                                variant='contained'
                                size='small'
                                background="#0d762d"
                                disabled={sheet?.orcamento_atualizado || checkSystem() || (!sheet?.extra?.mensagemVoz || !sheet.extra?.googleEarth)}
                                onClick={() => sendSystem('Orçamento Atualizado')}
                                startIcon={!sheet?.extra?.mensagemVoz || !sheet.extra?.googleEarth ? <WarningRoundedIcon sx={{ fill: '#F9C730' }} /> :
                                  <img src={PanelSolar} alt="icon" style={{ width: 24, height: 24 }} />
                                }>
                                Enviar Orçamento
                              </ButtonCB>
                            </Badge>
                          </span>
                        </NewTooltip> : null
                    }
                    {checkVisit() ?
                      <NewTooltip title={sheet?.visita_tecnica?.enviado ? 'A Visita já foi enviada para o Cliente.' : 'Clique aqui enviar a Visita Técnica para o Cliente via WhatsApp'} placement='top'>
                        <span>
                          <ButtonCB
                            disableElevation
                            sx={{ padding: '6px 16px', fontSize: '14px' }}
                            variant='contained'
                            size='small'
                            disabled={sheet?.visita_tecnica?.enviado}
                            onClick={sendVisit}
                            background="#1c256e"
                            startIcon={sheet?.visita_tecnica?.enviado ? <WarningRoundedIcon sx={{ fill: '#F9C730' }} /> :
                              <EngineeringIcon />
                            }>
                            Enviar Visita Técnica
                          </ButtonCB>
                        </span>
                      </NewTooltip> :
                      null
                    }
                  </ThemeProvider>
                  {sheet?.sistemaRecomendado &&
                    <Button color='error' onClick={cancelSend}>Reiniciar Envio</Button>
                  }
                  <NewTooltip title={cloneSheet ? '' : 'Clique aqui para ativar o modo Clonagem de Planilha'} placement='top'>
                    <Button
                      disableElevation
                      sx={{ textTransform: 'capitalize' }}
                      variant='contained'
                      color={cloneSheet ? 'error' : 'primary'}
                      onClick={() => handleClone(cloneSheet)}
                      startIcon={cloneSheet ? <CloseIcon /> : <ContentCopyIcon />}>
                      {cloneSheet ? 'Cancelar Clonagem' : 'Ativar Clonagem de Planilha'}
                    </Button>
                  </NewTooltip>
                  <ThemeProvider theme={theme}>
                    <NewTooltip title={!checkChanges() ? 'Clique em Ativar Clonagem De Planilha ou altere algum campo desbloqueado para liberar o botão' : ''} placement='top'>
                      <span>
                        <Button
                          disableElevation
                          type='submit'
                          color='success'
                          disabled={!checkChanges()}
                          sx={{ textTransform: 'capitalize' }}
                          startIcon={<SaveIcon />}
                          variant='contained'>Salvar {cloneSheet ? 'Clonagem' : null}</Button>
                      </span>
                    </NewTooltip>
                    <NewTooltip title={!generation || generation === '0' ? 'Para liberar o botão, insira um valor no campo Geração Média' : ''} placement='top'>
                      <span>
                        <Button
                          disableElevation
                          sx={{ textTransform: 'capitalize' }}
                          variant='contained'
                          disabled={!generation || generation === '0'}
                          onClick={() => window.print()}
                          startIcon={<PrintIcon />}>
                          Imprimir / PDF
                        </Button>
                      </span>
                    </NewTooltip>
                    <Button
                      disableElevation
                      sx={{ textTransform: 'capitalize' }}
                      startIcon={<ReplyIcon />}
                      onClick={() => navigate(`/clientes/${customer.id}`)}
                      variant='contained'>Voltar ao Cliente</Button>
                  </ThemeProvider>
                </div> : null
              }
            </form>
            <div className={`${style.footer} ${style.print_off}`}>
            </div>
            <Consumption view={open.consumption} close={() => setOpen({ ...open, consumption: false })} equipments={equipments} setEquipments={setEquipments} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Sheet